<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		form(@submit.prevent="submit", v-if="placement")
			.row
				.col-md-6
					.card.card-custom.gutter-b
						.card-header
							.card-title
								.card-label Placement Information <span v-if="placement.id">({{ placement.id }})</span>
							.card-toolbar
						.card-body
							.form-group
								label Name
								input.form-control(type="text", v-model="placement.name")
							.row
								.col-sm-6
									.form-group
										label Publisher
										v-select(:options="filteredPublisherOptions", v-model="placement.publisher_id", :reduce="o => o.v", label="t")
								.col-sm-6
									.form-group
										label Status
										select.form-control(v-model="placement.status")
											option(v-for="o in placementStatusOptions", :value="o.v") {{ o.t }}
							h4 Remote
							.row
								.col-sm-4
									.form-group
										b-form-checkbox(v-model="placement.remote_enabled", switch) Enable
								.col-sm-4
									.form-group
										label Remote ID
										input.form-control(v-model="placement.remote_id")
								.col-sm-4
									.form-group
										label Remote Installs
										input.form-control(v-model="placement.remote_hourly_installs")

					.card.card-custom.gutter-b
						.card-header
							.card-title
								.card-label Custom Tracking Settings
							.card-toolbar
						.card-body
							.row
								.col-sm-6
									.form-group
										label Click-To-Impression mode
										select.form-control(v-model="placement.click2impression_mode")
											option(value="none") None
											option(value="s2s") S2S
											option(value="pixel") Pixel
								.col-sm-6
									.form-group
										label Redirect Type
										select.form-control(v-model="placement.redirect_type")
											option(:value="0") Default
											option(:value="200") 200
											option(:value="302") 302
							.form-group
								label Click-To-Impression Ratio
								input.form-control(type="text", v-model="placement.impression_ratio")

							.row
								.col-sm-6
									.form-group
										label Click2Imp mode
										select.form-control(v-model="placement.click2imp_mode")
											option(value="none") None
											option(value="s2s") S2S
								.col-sm-6
									.form-group
										label Click2Imp %
										input.form-control(type="text", v-model="placement.click2imp_percent")

							.row
								.col-sm-6
									.form-group
										label Imp2Click mode
										select.form-control(v-model="placement.imp2click_mode")
											option(value="none") None
											option(value="s2s") S2S
								.col-sm-6
									.form-group
										label Imp2Click %
										input.form-control(type="text", v-model="placement.imp2click_percent")

							.form-group
								label Custom Click URL
								textarea.form-control(v-model="placement.custom_click_url", rows="3")
							.form-group
								label Offer Click URL
								textarea.form-control(:value="offer.click_url", rows="3", readonly="readonly",
									style="background: #f6f6f6;", :class="{'text-muted':placement.custom_click_url}")
							.form-group
								label Fallback Click URL
								textarea.form-control(v-model="placement.fallback_click_url", rows="3")
							hr
							.form-group
								label Custom Impression URL
								textarea.form-control(v-model="placement.custom_impression_url", rows="3")
							.form-group
								label Offer Impression URL
								textarea.form-control(:value="offer.impression_url", rows="3", readonly="readonly",
									style="background: #f6f6f6;", :class="{'text-muted':placement.custom_impression_url}")
							.form-group
								label Fallback Impression URL
								textarea.form-control(v-model="placement.fallback_impression_url", rows="3")

				.col-md-6

					.card.card-custom.gutter-b
						.card-header
							.card-title
								.card-label Payouts
						.card-body
							p.text-muted Offer payout: {{ offer.payout }} {{ offer.currency }} {{ offer.payout_model }}
							.row
								.col-xl-4.col-lg-6
									.form-group
										label.d-flex
											span(style="flex: 1") Payout
											span.text-muted Currency
										.input-group
											input.form-control(type="text", v-model="placement.payout")
											select.form-control.text-muted(v-model="placement.currency")
												option(v-for="o in currencyOptions", :value="o.v") {{ o.t }}

								.col-xl-4.col-lg-6
									.form-group
										label Model
										select.form-control(v-model="placement.payout_model")
											option(v-for="o in payoutModelOptions", :value="o.v") {{ o.v }}
					.card.card-custom.gutter-b
						.card-header
							.card-title
								.card-label Caps
						.card-body
							.row
								.col-xl-4.col-lg-6
									.form-group
										label Daily Conversion Cap
										.input-group
											input.form-control(type="text", v-model="placement.daily_conversion_cap")
										.text-muted Offer: {{ offer.daily_conversion_cap }}
								.col-xl-4.col-lg-6
									.form-group
										label Daily Click Cap
										.input-group
											input.form-control(type="text", v-model="placement.daily_click_cap")
										.text-muted Offer: {{ offer.daily_click_cap }}
								.col-xl-4.col-lg-6
									.form-group
										label Daily Impression Cap
										.input-group
											input.form-control(type="text", v-model="placement.daily_impression_cap")
										.text-muted Offer: {{ offer.daily_impression_cap }}


					.card.card-custom.gutter-b
						.card-header
							.card-title
								.card-label Misc
							.card-toolbar
						.card-body
							.form-group
								b-form-checkbox(v-model="placement.skip_geo_check", switch) Skip geo check
							.form-group
								label Comments
								textarea.form-control(v-model="placement.comments", rows="5")


</template>
<script>
import Vue from 'vue';
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import store from "@/core/services/store";
import {OVERRIDE_PAGE_LAYOUT_CONFIG, SET_LAYOUT_CONFIG} from "@/core/services/store/config.module";
import HtmlClass from "@/core/services/htmlclass.service";

async function loadRoute(vm, to, from, next){
	let placement = null;
	let publisher = null;
	let publisherOptions = await Vue.$dwData.publisher.getOptions();
	let advertiser = null;
	let offer = null;
	if (to.name === 'placement-edit') {
		let entityId = +to.params.id;
		try {
			placement = await Vue.$dwData.placement.get(entityId, true);
		} catch (e) {
			Vue.$notify.error('Failed to load placement');
			return next(false);
		}
	} else {

	}
	if (placement && placement.offer_id) {
		offer = await Vue.$dwData.offer.get(placement.offer_id);
		if (!offer){
			return next(false);
		}
		placement.advertiser_id = offer.advertiser_id;
	} else if (+to.query.offer_id) {
		offer = await Vue.$dwData.offer.get(+to.query.offer_id, true);
		if (!offer){
			return next(false);
		}
		placement = Vue.$dwData.placement.newInstance(offer);
	}

	advertiser = await Vue.$dwData.advertiser.get(offer.advertiser_id);

	if (placement.publisher_id) {
		publisher = await Vue.$dwData.publisher.get(placement.publisher_id);
	} else if (+to.query.publisher_id) {
		publisher = await Vue.$dwData.publisher.get(+to.query.publisher_id);
		placement.publisher_id = publisher.id;
	}
	return vm => {
		vm.publisherOptions = publisherOptions;
		vm.placement = placement;
		vm.advertiser = advertiser;
		vm.publisher = publisher;
		vm.offer = offer;
		vm.busy = false;
		return vm;
	};
}

export default {
	async beforeRouteEnter(to, from, next){
		let bind = await loadRoute({}, to, from, next);
		next(bind);
	},
	async beforeRouteUpdate(to, from, next){
		await loadRoute(this, to, from, next);
		next();
	},
	name: 'PlacementForm',
	data(){
		return {
			busy: true,
			publisher: null,
			advertiser: null,
			placement: null,
			offer: null,
			publisherOptions: [],
			selectedPublisherId: [],
			placementStatusOptions: Vue.$dwData.general.placementStatusOptions,
			payoutModelOptions: Vue.$dwData.general.payoutModelOptions,
			currencyOptions: Vue.$dwData.general.currencyOptions,
		}
	},
	computed: {
		filteredPublisherOptions() {
			return this.publisherOptions.filter(p => p.network_id === this.offer.network_id);
			// return this.publisherOptions.filter(p => p.t.startsWith(`[${this.offer.network_id},`));
		}
	},
	methods: {
		async submit(closeAfterSave = false){
			if (this.busy){
				return;
			}
			this.busy = true;
			let data = Vue.util.extend({}, this.placement);
			try {
				let resp = await this.$api.post('placements/save', data);
				this.busy = false;
				this.$notify.success('Placement has been saved');
				if (closeAfterSave) {
					this.$router.push({name: 'placement-list'});
				} else {
					this.placement = resp.entity;
				}
			} catch (e) {
				console.error(e);
				this.busy = false;
			}
		}
	},
	mounted(){
		let vm = this;
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Media'},
			{title: 'Placements', name: 'placement-list'},
			{title: this.placement && this.placement.id ? 'Edit Placement' : 'Add Placement'}
		]);
		this.$store.dispatch('setBackButton', {
			route: {name: 'placement-list'}
		});
		this.$store.dispatch('setPageButtons', [
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save',
				action(){
					vm.submit();
				}
			},
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save & Close',
				action(){
					vm.submit(true);
				}
			}
		]);
	},
}

</script>
