<template lang="pug">
	.form-group.form-group-sm.fg-offer
		label Offers
		//multiselect(:options="offerOptions",
		//	:value="value", @input="onChange", track-by="v", label="t")
		//pre {{ value }}
		v-select(:options="offerOptions", :value="value", :reduce="op => op.v", :getOptionLabel="op => op.t")
			template(v-slot:option="option")
				span {{ option.t }}
</template>
<script>
import Vue from 'vue';

export default {
	name: 'SelectOfferAlt',
	props: {
		value: [Array, Object],
		multiple: {
			type: Boolean,
			default: true,
			required: false
		}
	},
	data(){
		return {
			offerOptions: [],
		};
	},
	methods: {
		onChange(value) {
			this.$emit('input', value);
		}
	},
	async mounted(){
		// this.offerOptions = await Vue.$dwData.offer.getOptions(true);

	}
}
</script>
