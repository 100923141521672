<template lang="pug">
	div.default-modal(style="max-width: 650px")

		.modal-header
			.modal-title Link Checker
			a.close(href="javascript:void(0);", @click="$emit('close')")
				i.la.la-times
		.modal-body
			loading(:active.sync="busy", :is-full-page="false")
			.modal-body-inner(style="max-height: 600px; overflow: auto;")
				form(@submit.prevent="submit")
					.row
						.col-sm-6
							.form-group
								label Country
								select.form-control(v-model="formData.country")
									option(v-for="o in countryOptions", :value="o.v") {{ o.v }}
						.col-sm-6
							.form-group
								label Platform
								select.form-control(v-model="formData.platform")
									option(v-for="o in platformOptions", :value="o.v") {{ o.t }}
					.form-group
						label URL
						textarea.form-control(rows="6", v-model="formData.url")
					.form-group
						button.btn.btn-primary(type="submit") Submit
				table.table(v-if="resp && resp.redirects")
					thead
						tr
							th URL
							th Status
					tbody
						tr(v-for="r in resp.redirects")
							td.url {{ r.url }}
							td.text-center {{ r.status }}

</template>
<style lang="scss">
.url {
	word-break: break-all;
}
</style>
<script>
import Vue from 'vue';

export default {
	name: 'QuickEditOfferModal',
	data(){
		return {
			busy: false,
			resp: null,
			formData: {
				platform: 'ios',
				country: 'US_PA',
				url: ''
			},
			platformOptions: Vue.$dwData.general.platformOptions,
			countryOptions: [
				{v: 'AE'},
				{v: 'AR'},
				{v: 'AT'},
				{v: 'AU'},
				{v: 'BE'},
				{v: 'BR'},
				{v: 'CA'},
				{v: 'DE'},
				{v: 'ES'},
				{v: 'FR'},
				{v: 'GB'},
				{v: 'HK'},
				{v: 'ID'},
				{v: 'IL'},
				{v: 'IN'},
				{v: 'IT'},
				{v: 'JP'},
				{v: 'KR'},
				{v: 'MM'},
				{v: 'MX'},
				{v: 'PH'},
				{v: 'QA'},
				{v: 'RO'},
				{v: 'RU'},
				{v: 'SA'},
				{v: 'SE'},
				{v: 'TR'},
				{v: 'US_CO'},
				{v: 'US_MI'},
				{v: 'US_NJ'},
				{v: 'US_NY'},
				{v: 'US_PA'},
				{v: 'VN'},
			]
		};
	},
	methods: {
		async submit(){
			if (this.busy) {
				return;
			}
			this.busy = true;
			try {
				let data = Vue.util.extend({}, this.formData);
				let resp = await this.$api.post('general/linkChecker', data);
				this.resp = resp.resp;
			} catch (e) {
				console.error(e);
			}
			this.busy = false;
		}
	}
}
</script>
