<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		.card.card-custom.gutter-b
			.card-header
				.card-title
					.card-label Managers
				.card-toolbar
			.card-body
				button.btn.btn-primary(type="button", @click="getManagers") getManagers
				table.table.table-bordered
					thead
						tr
							th ID (Port)
							th SocketID
							th Connected
							th Jobs
					tbody
						tr(v-for="m in managers")
							td {{ m.id }}
							td {{ m.socketId }}
							td {{ m.connected }}
							td {{ m.jobs.join(", ") }}

</template>
<script>
import Vue from 'vue';
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';

export default {
	name: 'MonitorACL',
	async beforeRouteEnter(to, from, next){
		next(vm => {
			vm.getManagers();
			return vm;
		});
	},
	data(){
		return {
			busy: false,
			managers: []
		};
	},
	methods: {
		async getManagers() {
			if (this.busy) {
				return;
			}
			this.busy = true;
			try {
				let resp = await this.$api.get('/monitor/acl/getManagers');
				this.managers = resp.managers;
			} catch (e) {
				console.error(e);
			}
			this.busy = false;
		}
	},
	mounted(){
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Media'},
			{title: 'Monitor'},
			{title: 'ACL'}
		]);
	},
}
</script>
