<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		form(@submit.prevent="submit", v-if="network")

			b-tabs(content-class="mt-3", pills, card)
				b-tab(title="Main", style="padding: 0")
					.row
						.col-md-6
							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Network Information <span v-if="network.id">({{ network.id }})</span>
									.card-toolbar
								.card-body
									.row
										.col-sm-6
											.form-group
												label Name
												input.form-control(type="text", v-model="network.name")
										.col-sm-6
											.form-group
												label Slug
												input.form-control(type="text", v-model="network.slug")
									.form-group
										label Tracking Base URL
										input.form-control(type="text", v-model="network.tracking_base_url")
									.form-group
										label Assigned Domains
										textarea.form-control(v-model="network.assigned_domains", rows="8")

</template>
<script>
import Vue from 'vue';
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import store from "@/core/services/store";
import {OVERRIDE_PAGE_LAYOUT_CONFIG, SET_LAYOUT_CONFIG} from "@/core/services/store/config.module";
import HtmlClass from "@/core/services/htmlclass.service";

async function loadRoute(vm, to, from, next){

	let network = {};
	if (to.name === 'network-edit') {
		let entityId = +to.params.id;
		try {
			network = await Vue.$dwData.network.get(entityId, true);
		} catch (e) {
			Vue.$notify.error('Failed to load network');
			return next(false);
		}
	} else {
		network = Vue.$dwData.network.newInstance();
	}
	network.assigned_domains = network.assigned_domains.join("\n");

	return vm => {
		vm.network = network;
		vm.busy = false;
		return vm;
	};
}

export default {
	async beforeRouteEnter(to, from, next){
		let bind = await loadRoute({}, to, from, next);
		next(bind);
	},
	async beforeRouteUpdate(to, from, next){
		await loadRoute(this, to, from, next);
		next();
	},
	name: 'NetworkForm',
	data(){
		return {
			busy: true,
			network: null,
		};
	},
	methods: {

		async submit(closeAfterSave = false){
			if (this.busy){
				return;
			}
			this.busy = true;
			let data = Vue.util.extend({}, this.network);
			try {
				let resp = await this.$api.post('networks/save', data);
				this.busy = false;
				this.$notify.success('Network has been saved');
				if (closeAfterSave) {
					this.$router.push({name: 'network-list'});
				} else {
					this.network = resp.entity;
				}
			} catch (e) {
				console.error(e);
				this.busy = false;
			}
		}
	},
	mounted(){
		let vm = this;
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Media'},
			{title: 'Networks', name: 'network-list'},
			{title: this.advertiser && this.advertiser.id ? 'Edit Network' : 'Add Network'}
		]);
		this.$store.dispatch('setBackButton', {
			route: {name: 'network-list'}
		});
		this.$store.dispatch('setPageButtons', [
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save',
				action(){
					vm.submit();
				}
			},
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save & Close',
				action(){
					vm.submit(true);
				}
			}
		]);
	},
}

</script>
