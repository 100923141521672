<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		form(@submit.prevent="submit", v-if="platform")
			.row
				.col-md-6
					.card.card-custom.gutter-b
						.card-header
							.card-title
								.card-label MB Platform Information <span v-if="platform.id">({{ platform.id }})</span>
							.card-toolbar
						.card-body
							.form-group
								label Name
								input.form-control(type="text", v-model="platform.name")
					//.card.card-custom.gutter-b
						.card-header
							.card-title
								.card-label Panel Information
							.card-toolbar
						.card-body
							.row
								.col-sm-12
									.form-group
										label Panel URL
										input.form-control(type="text", v-model="platform.panel_url")
							.row
								.col-sm-6
									.form-group
										label Username
										input.form-control(type="text", v-model="platform.panel_username")
								.col-sm-6
									.form-group
										label Password
										input.form-control(type="text", v-model="platform.panel_password")
				//.col-md-6

					.card.card-custom.gutter-b
						.card-header
							.card-title
								.card-label Misc
							.card-toolbar
						.card-body
							.form-group
								label Comments
								textarea.form-control(v-model="platform.comments", rows="5")


</template>
<script>
import Vue from 'vue';
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import store from "@/core/services/store";
import {OVERRIDE_PAGE_LAYOUT_CONFIG, SET_LAYOUT_CONFIG} from "@/core/services/store/config.module";
import HtmlClass from "@/core/services/htmlclass.service";

async function loadRoute(vm, to, from, next){

	let platform = {};
	if (to.name === 'mb-platform-edit') {
		let entityId = +to.params.id;
		try {
			platform = await Vue.$dwData.mb.platform.get(entityId, true);
		} catch (e) {
			Vue.$notify.error('Failed to load platform');
			return next(false);
		}
	} else {
		platform = Vue.$dwData.mb.platform.newInstance();
	}

	return vm => {
		vm.platform = platform;
		vm.busy = false;
		return vm;
	};
}

export default {
	async beforeRouteEnter(to, from, next){
		let bind = await loadRoute({}, to, from, next);
		next(bind);
	},
	async beforeRouteUpdate(to, from, next){
		await loadRoute(this, to, from, next);
		next();
	},
	name: 'MBPlatformForm',
	data(){
		return {
			busy: true,
			platform: null
		}
	},
	methods: {
		async save(closeAfterSave = false){
			if (this.busy){
				return;
			}
			this.busy = true;
			let data = Vue.util.extend({}, this.platform);
			try {
				let resp = await this.$api.post('mb/platforms/save', data);
				this.busy = false;
				this.$notify.success('MB Platform has been saved');
				if (closeAfterSave) {
					this.$router.push({name: 'mb-platform-list'});
				} else {
					this.platform = resp.entity;
				}
			} catch (e) {
				console.error(e);
				this.busy = false;
			}
		}
	},
	mounted(){
		let vm = this;
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Media'},
			{title: 'MB Platforms', name: 'mb-platform-list'},
			{title: this.platform && this.platform.id ? 'Edit Platform' : 'Add Platform'}
		]);
		this.$store.dispatch('setBackButton', {
			route: {name: 'mb-platform-list'}
		});
		this.$store.dispatch('setPageButtons', [
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save',
				action(){
					vm.save();
				}
			},
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save & Close',
				action(){
					vm.save(true);
				}
			}
		]);
	},
}

</script>
