<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		form(@submit.prevent="submit", v-if="user")
			b-tabs(content-class="mt-3", pills, card)
				b-tab(title="Main", style="padding: 0")
					.row
						.col-md-6
							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label User Information <span v-if="user.id">({{ user.id }})</span>
									.card-toolbar
								.card-body
									.row
										.col-sm-4
											.form-group
												label Network
												select.form-control(v-model="user.network_id")
													option(v-for="o in networkOptions", :value="o.v") {{ o.t }}
										.col-sm-4
											.form-group
												label First Name
												input.form-control(type="text", v-model="user.first_name")
										.col-sm-4
											.form-group
												label Last Name
												input.form-control(type="text", v-model="user.last_name")
									.row
										.col-sm-4
											.form-group
												label Level
												select.form-control(v-model="user.level")
													option(v-for="o in levelOptions", :value="o.v") {{ o.t }}
										.col-sm-4(v-if="user.level === 1")
											.form-group
												label Publisher
												select.form-control(v-model="user.publisher_id")
													option(v-for="o in publisherOptions", :value="o.v") {{ o.t }}

							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Login
									.card-toolbar
								.card-body
									.form-group
										label Email
										input.form-control(type="text", v-model="user.email")
									.form-group
										label Password
										input.form-control(type="password", v-model="newPass")



</template>
<script>
import Vue from 'vue';
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import store from "@/core/services/store";
import {OVERRIDE_PAGE_LAYOUT_CONFIG, SET_LAYOUT_CONFIG} from "@/core/services/store/config.module";
import HtmlClass from "@/core/services/htmlclass.service";

async function loadRoute(vm, to, from, next){

	let user = {};
	if (to.name === 'user-edit') {
		let entityId = +to.params.id;
		try {
			user = await Vue.$dwData.user.get(entityId, true);
		} catch (e) {
			Vue.$notify.error('Failed to load user');
			return next(false);
		}
	} else {
		user = Vue.$dwData.user.newInstance();
	}
	let networkOptions = await Vue.$dwData.network.getOptions();
	let publisherOptions = await Vue.$dwData.publisher.getOptions();
	return vm => {
		vm.user = user;
		vm.networkOptions = networkOptions;
		vm.publisherOptions = publisherOptions;
		vm.busy = false;
		return vm;
	};
}

export default {
	async beforeRouteEnter(to, from, next){
		let bind = await loadRoute({}, to, from, next);
		next(bind);
	},
	async beforeRouteUpdate(to, from, next){
		await loadRoute(this, to, from, next);
		next();
	},
	name: 'UserForm',
	data(){
		return {
			busy: true,
			user: null,
			networkOptions: [],
			publisherOptions: [],
			newPass: '',
			levelOptions: [
				{v: 1, t: 'Publisher'},
				{v: 4, t: 'Network'},
				{v: 5, t: 'Admin'},
			]
		};
	},
	methods: {

		async submit(closeAfterSave = false){
			if (this.busy){
				return;
			}
			this.busy = true;
			let data = Vue.util.extend({}, this.user);
			data.new_pass = this.newPass;
			try {
				let resp = await this.$api.post('users/save', data);
				this.busy = false;
				this.$notify.success('User has been saved');
				if (closeAfterSave) {
					this.$router.push({name: 'user-list'});
				} else {
					this.publisher = resp.entity;
				}
			} catch (e) {
				console.error(e);
				this.busy = false;
			}
		}
	},
	mounted(){
		let vm = this;
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Media'},
			{title: 'Users', name: 'user-list'},
			{title: this.user && this.user.id ? 'Edit User' : 'Add User'}
		]);
		this.$store.dispatch('setBackButton', {
			route: {name: 'user-list'}
		});
		this.$store.dispatch('setPageButtons', [
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save',
				action(){
					vm.submit();
				}
			},
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save & Close',
				action(){
					vm.submit(true);
				}
			}
		]);
	},
}

</script>
