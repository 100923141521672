<template lang="pug">
	ul.menu-nav

		template(v-for="(group, groupIndex) in menuItems")
			li.menu-section(v-if="group.label" :key="groupIndex")
				h4.menu-text {{group.label}}
				i.menu-icon.flaticon-more-v2
			template(v-for="(item, itemIndex) in group.items")
				router-link(v-if="!item.children", :key="groupIndex+'_'+itemIndex", :to="'/app'+group.path+item.path", v-slot="{ href, navigate, isActive, isExactActive }")
					li(aria-haspopup="true", data-menu-toggle="hover" class="menu-item",
						:class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]")
						a.menu-link(:href="href", @click="navigate")
							i.menu-icon(:class="item.icon")
							span.menu-text {{ item.label }}

				li.menu-item.menu-item-submenu(v-if="item.children && item.children.length", :key="groupIndex+'_'+itemIndex",
					aria-haspopup="true", data-menu-toggle="hover", :class="{'menu-item-open': hasActiveChildren('/app'+group.path+item.path)}")
					a.menu-link.menu-toggle(href="#")
						i.menu-icon(:class="item.icon")
						span.menu-text {{item.label }}
						i.menu-arrow
					div.menu-submenu.menu-submenu-classic.menu-submenu-right
						ul.menu-subnav
							router-link(v-for="(subItem, subItemIndex) in item.children", :key="subItemIndex",
								:to="'/app'+group.path+item.path+subItem.path", v-slot="{ href, navigate, isActive, isExactActive }")
								li.menu-item(aria-haspopup="true", data-menu-toggle="hover",
									:class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active']")
									a.menu-link(:href="href", @click="navigate")
										//i.menu-bullet.menu-bullet-dot
											span
										span.menu-text {{ subItem.label }}
</template>

<script>
export default {
	name: "KTMenu",
	data(){
		return {
			menuItems: [
				{
					label: null,
					items: [
						{
							label: 'Dashboard',
							icon: 'flaticon2-architecture-and-city',
							path: '/'
						}
					]
				},
				{
					label: 'Crypto',
					path: '/crypto',
					items: [
						{
							label: 'Exchanges',
							icon: 'flaticon2-expand',
							path: '/exchanges',
							children: [
								{
									label: 'Balances',
									path: '/balances'
								},
								{
									label: 'Transactions',
									path: '/transactions'
								},
								{
									label: 'Accounts',
									path: '/accounts'
								},
							]
						},
					]
				},

				{
					label: 'Media',
					path: '/media',
					items: [
						{
							label: 'Demand',
							icon: 'flaticon2-expand',
							path: '/demand',
							children: [
								{
									label: 'Networks',
									path: '/networks'
								},
								{
									label: 'Advertisers',
									path: '/advertisers'
								},
								// {
								// 	label: 'Feeds',
								// 	path: '/advertiser-feeds'
								// },
								{
									label: 'Offers',
									path: '/offers'
								},
								{
									label: 'Ext. Offers',
									path: '/external-offers'
								},
								{
									label: 'Assets',
									path: '/assets'
								},
								{
									label: 'Data Control',
									path: '/data-control'
								},
								{
									label: 'Source Blacklist',
									path: '/data-control/source-blacklist'
								},
								{
									label: 'MMP Accounts',
									path: '/mmp-accounts'
								},
							]
						},
						{
							label: 'Supply',
							icon: 'flaticon2-expand',
							path: '/supply',
							children: [
								{
									label: 'Publishers',
									path: '/publishers'
								},
								{
									label: 'Feeds',
									path: '/publisher-feeds'
								},
								{
									label: 'Placements',
									path: '/placements'
								},
								{
									label: 'Creatives',
									path: '/creatives'
								},
								{
									label: 'Tags',
									path: '/tags'
								},
								{
									label: 'Profiles',
									path: '/profiles'
								},
								{
									label: 'Remote Publishers',
									path: '/remote-publishers'
								},
								{
									label: 'Remote Groups',
									path: '/remote-publisher-groups'
								},
								{
									label: 'Remote Offers',
									path: '/remote-offers'
								},
								{
									label: 'UDP Report',
									path: '/udp-report'
								},
								{
									label: 'MB Platforms',
									path: '/mb/platforms'
								},
								{
									label: 'MB Campaigns',
									path: '/mb/campaigns'
								},
							]
						},
						{
							label: 'RTB',
							icon: 'flaticon2-expand',
							path: '/rtb',
							children: [
								{
									label: 'Publisher Feeds',
									path: '/publisher-feeds'
								},
								{
									label: 'Advertiser Feeds',
									path: '/advertiser-feeds'
								},
								{
									label: 'Campaigns (RTB)',
									path: '/campaigns'
								},
							]
						},
						{
							label: 'Reports',
							icon: 'flaticon2-expand',
							path: '/reports',
							children: [
								{
									label: 'Reports',
									path: ''
								},
								{
									label: 'Bucksense',
									path: '/bucksense'
								},
								{
									label: 'Events',
									path: '/events'
								},
								{
									label: 'Rejection Events',
									path: '/rejection-events'
								},
								{
									label: 'Clicks',
									path: '/clicks'
								},
								{
									label: 'Postback Log',
									path: '/postback-log'
								},
							]
						},
						{
							label: 'Data',
							icon: 'flaticon2-expand',
							path: '/data',
							children: [
								{
									label: 'Categories',
									path: '/categories'
								},
							]
						},

						{
							label: 'Automation',
							icon: 'flaticon2-expand',
							path: '/jobs',
							children: [
								{
									label: 'Import Clicks',
									path: '/import'
								},
								{
									label: 'Generate Clicks',
									path: '/generate'
								},
								{
									label: 'Auto-Campaigns',
									path: '/auto-campaigns'
								},
								{
									label: 'Auto-Imports',
									path: '/auto-imports'
								},

								{
									label: 'Auto-Campaigns (new)',
									path: '/ac'
								},
							]
						},
						{
							label: 'Monitor',
							icon: 'flaticon2-expand',
							path: '/monitor',
							children: [
								{
									label: 'ACL',
									path: '/acl'
								},
								{
									label: 'SSL',
									path: '/ssl'
								},
								{
									label: 'Domain',
									path: '/domain'
								},
							]
						},
						{
							label: 'Settings',
							icon: 'flaticon2-expand',
							path: '/settings',
							children: [
								{
									label: 'Users',
									path: '/users'
								},
							]
						},
					]
				}
			]
		};
	},
	methods: {
		hasActiveChildren(match){
			return this.$route["path"].indexOf(match) !== -1;
		}
	}
};
</script>
