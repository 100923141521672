<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		.card.card-custom.gutter-b
			.card-header
				.card-title
					.card-label Publisher Feeds
				.card-toolbar
			.card-body
				form.form.form-inline.form-filters(@submit.prevent="loadData()", :class="{expanded:filtersExpanded}")
					a.btn.btn-primary.toggle-filters.d-md-none(v-b-toggle.form-filters)
						span Filters
						i.fa.fw.fa-chevron-up.transition-all(:class="{'rotate-180':filtersExpanded}")
					b-collapse#form-filters.dont-collapse-sm(style="width: 100%;", v-model="filtersExpanded")
						.row.filters-form-row
							.form-row-main.col-auto.mr-auto(style="flex:1;")
								.form-group
									label Search
									input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.keyword", v-debounce:300ms="loadData")
								.form-group
									label Network
									select.form-control(v-model="filters.network_id", style="width: 120px;")
										option(:value="null") - All -
										option(v-for="n in networkOptions", :value="n.v") {{ n.t }}
								.form-group
									label Status
									select.form-control(v-model="filters.status", @change="loadData()")
										option(v-for="o in statusOptions", :value="o.v") {{ o.t }}
								.form-group.no-label
									button.btn.btn-primary(type="submit") Go
							.form-row-end.col-auto
								.form-group.no-label
									router-link.btn.btn-success(:to="{name:'publisher-feed-add'}")
										i.flaticon2-plus
										| Add

				p.clearfix &nbsp;

				table.table.table-bordered
					thead
						tr
							th ID
							th Status
							th Type
							th Network
							th Name
							th Token
							th Payout
							th Countries
							th Actions
					tbody
						tr(v-if="records.length===0")
							td(colspan="8") No matching records were found
						tr(v-for="r in records", :key="r.id")
							td {{ r.id }}
							td {{ r.status }}
							td
								span(v-if="r.type === 0") In-App
								span(v-if="r.type === 1") Pop
								span(v-if="r.type === 2") Push
								span(v-if="r.type === 3") Extension
								span(v-if="r.type === 4") Domain
								span(v-if="r.type === 5") Video
							td [{{ r.network_id }}] {{ r.network.name }}
							td {{ r.name }}
							td {{ r.token }}
							td
								span(v-if="r.payout_percent") {{ r.payout_percent }}%&nbsp;
								span {{ r.payout_model }}
							td {{ r.countries }}
							td.actions
								a.btn.btn-sm.btn-action.btn-secondary(href="javascript:void(0)", :id="`endpoint-url-${r.id}`", v-b-tooltip.hover, title="Show Endoint")
									i.la.la-link
								b-popover(:target="`endpoint-url-${r.id}`", placement="left", title="Endpoint", custom-class="endpoint-link-popover")
									div
										.form-group
											label Endpoint
											textarea.quickview-url.form-control(readonly="readonly") {{ r.endpoint_url }}
								router-link.btn.btn-action.btn-secondary(:to="{name:'publisher-feed-edit', params: {id:r.id}}")
									i.la.la-pen
								.btn.btn-action.btn-outline-danger()
									i.la.la-trash

				paginate(:paginator="paginate", @update-page-size="loadData")

</template>
<style lang="scss">
.endpoint-link-popover {
	width: 300px;
	.popover-body {
		padding: 5px;
		.form-group {
			margin-bottom: 5px;
			&:last-child {
				margin-bottom: 0;
			}
			label {
				margin-bottom: 0;
			}
		}
	}
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
export default {
	name: 'PublisherFeedList',
	async beforeRouteEnter(to, from, next){
		let networkOptions = await Vue.$dwData.network.getOptions(true);
		next(vm => {
			vm.networkOptions = networkOptions;
			vm.loadData();
			return vm;
		});
	},
	data(){
		return {
			busy: false,
			// PERMS: null,
			networkOptions: [],
			paginate: {
				numPages: 0,
				total: 0,
				page: 1,
				limit: 50,
				onPageChange: () => {
					this.loadData();
				}
			},
			filters: {
				keyword: '',
				status: 1,
				network_id: 1,
				publisher_id: null,
			},
			statusOptions: [
				{v: undefined, t: 'All'},
				{v: 0, t: 'Inactive'},
				{v: 1, t: 'Active Only'},
			],
			records: [],
			filtersExpanded: false,
		};
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ title: 'Media' },
			{ title: 'RTB' },
			{ title: 'Publisher Feeds' }
		]);
	},
	methods: {

		async loadData(){
			this.busy = true;
			let params = {
				page: this.paginate.page,
				limit: this.paginate.limit,
				keyword: this.filters.keyword,
				network_id: this.filters.network_id || 0,
				publisher_id: this.filters.publisher_id || 0
			};
			try {
				let resp = await this.$api.get('/publisher-feeds/getList', {params});
				this.records = resp.records;
				this.paginate.total = resp.total;
				this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
			} catch (e){
				console.error(e);
			}
			this.busy = false;
		}
	}

}
</script>
