<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		form(@submit.prevent="submit", v-if="tag")

			b-tabs(content-class="mt-3", pills, card)
				b-tab(title="Main", style="padding: 0")
					.row
						.col-md-6
							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Tag Information <span v-if="tag.id">({{ tag.id }})</span>
									.card-toolbar
								.card-body
									.form-group
										b-form-checkbox(v-model="tag.active", switch) Active
									.row
										.col-sm-6
											.form-group
												label Name
												input.form-control(type="text", v-model="tag.name")
										.col-sm-6
											.form-group
												label Publisher
												select.form-control(v-model="tag.publisher_id")
													option(:value="null") - Select -
													option(v-for="p in publisherOptions", :value="p.v") {{ p.t }}

									.row
										.col-sm-6
											.form-group
												label RemoteID
												input.form-control(type="text", v-model="tag.remote_id")
									.row
										.col-sm-4
											.form-group
												label Width
												input.form-control(type="text", v-model="tag.width")
										.col-sm-4
											.form-group
												label Height
												input.form-control(type="text", v-model="tag.height")
										.col-sm-4
											.form-group
												b-form-checkbox(v-model="tag.is_rtb", switch) RTB?

						.col-md-6
							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Targeting
									.card-toolbar
								.card-body
									.form-group
										label Countries
										textarea.form-control(rows="3", placeholder="GB, US, ...", v-model="tag.countries")
									.form-group
										label Platform
										select.form-control(v-model="tag.platform")
											option(v-for="o in platformOptions", :value="o.v") {{ o.t }}


					.card.card-custom.gutter-b
						.card-header
							.card-title
								.card-label Banners
							.card-toolbar
						.card-body

							table.table.table-compact.filters-table
								thead
									tr
										th &nbsp;
										th Placement
										th Creative
										th Weight
										th
											button.btn.btn-secondary(type="button", @click="addBanner()")
												i.la.la-plus
								tbody
									tr(v-for="(b, i) in tag.banners")
										td
											b-form-checkbox(v-model="b.enabled", switch)
										td
											v-select(:options="placementOptions", :getOptionLabel="op => op.t", v-model="b.placement_id", :reduce="op => op.v")
												template(#option="{ v, t }") {{ t }}
										td
											v-select(:options="creativeOptions", :getOptionLabel="op => op.t", v-model="b.creative_id", :reduce="op => op.v")
												template(#option="{ v, t }") {{ t }}
										td
											input.form-control(type="text", v-model="b.weight")
										td
											button.btn.btn-sm.btn-circle(type="button", @click="tag.banners.splice(i,1)")
												i.la.la-times

					.card.card-custom.gutter-b
						.card-header
							.card-title
								.card-label Background Placements
							.card-toolbar
						.card-body

							table.table.table-compact.filters-table
								thead
									tr
										th &nbsp;
										th Placement
										th Weight
										th
											button.btn.btn-secondary(type="button", @click="addBGPlacement()")
												i.la.la-plus
								tbody
									tr(v-for="(b, i) in tag.background_placements")
										td
											b-form-checkbox(v-model="b.enabled", switch)
										td
											v-select(:options="placementOptions", :getOptionLabel="op => op.t", v-model="b.placement_id", :reduce="op => op.v")
												template(#option="{ v, t }") {{ t }}
										td
											input.form-control(type="text", v-model="b.weight")
										td
											button.btn.btn-sm.btn-circle(type="button", @click="tag.banners.splice(i,1)")
												i.la.la-times

				b-tab(title="Code", style="padding: 0")
					.card.card-custom.gutter-b
						.card-header
							.card-title
								.card-label Code
							.card-toolbar
						.card-body
							.form-group
								label Exchange: {{ tag.exchange }}
								select.form-control.form-control-solid(v-model="tag.exchange")
									option(v-for="o in exchangeOptions", :value="o.v", @change="clearPreview()") {{ o.t }}
							.form-group
								textarea.form-control(:value="exchangeHTMLCode", rows="5")

							button.btn.btn-primary(type="button", @click="showPreview()") Preview

							p.clearfix &nbsp;

							div(id="tag-preview")


</template>
<style lang="scss">
.filter-block {
	background: #f6f6f6;
	padding: 30px;
	border-radius: 10px;
	margin-bottom: 10px;
	position: relative;
	.btn-remove {
		position: absolute;
		right: 10px;
		top: 10px;
		font-size: 20px;
	}
	.form-group {
		margin-bottom: 5px;
	}
}
</style>
<script>
import Vue from 'vue';
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import $ from 'jquery';

async function loadRoute(vm, to, from, next){

	let tag = {};
	let publisherOptions = await Vue.$dwData.publisher.getOptions();
	if (to.name === 'tag-edit') {
		let entityId = +to.params.id;
		try {
			tag = await Vue.$dwData.tag.get(entityId, true);
		} catch (e) {
			Vue.$notify.error('Failed to load tag');
			return next(false);
		}
	} else {
		let copyEntityId = +to.query.copy_id;
		if (copyEntityId){
			try {
				tag = await Vue.$dwData.tag.get(copyEntityId, true);
				tag.id = 0;
			} catch (e) {
				Vue.$notify.error('Failed to load tag');
				return next(false);
			}
		} else {
			tag = Vue.$dwData.tag.newInstance();
		}
	}

	let placementOptions = await Vue.$dwData.placement.getOptions(true);
	let creativeOptions = await Vue.$dwData.creative.getOptions(true);

	// let trackBaseUrl = ;
	return vm => {
		vm.tag = tag;
		vm.publisherOptions = publisherOptions;
		vm.placementOptions = placementOptions;
		vm.creativeOptions = creativeOptions;
		// vm.trackBaseUrl = trackBaseUrl;
		vm.busy = false;
		return vm;
	};
}


export default {
	name: 'TagForm',
	async beforeRouteEnter(to, from, next){
		let bind = await loadRoute({}, to, from, next);
		next(bind);
	},
	async beforeRouteUpdate(to, from, next){
		await loadRoute(this, to, from, next);
		next();
	},
	data(){
		return {
			busy: true,
			placement: null,
			tag: null,
			platformOptions: Vue.$dwData.general.platformOptions,
			placementOptions: [],
			publisherOptions: [],
			creativeOptions: [],
			exchange: 'bucksense',
			exchangeOptions: Vue.$dwData.general.exchangeOptions,
			trackBaseUrl: 'https://trkio.co',
		};
	},
	computed: {
		exchangeHTMLCode(){

			let url = this.trackBaseUrl + `/tg/${this.tag.short_uuid}/t.js?tag_id=`+ this.tag.id;
			switch (this.tag.exchange){
				case 'bucksense':
					url += `&sub1=%%PUB_ID%%&sub2=%%PLACE_ID%%&ssp_id=%%EXCHANGE_ID%%&campaign_id=%%CAMPAIGN_ID%%&ip=%%DEVICE_IP%%&bundle=%%APP_BUNDLE_ENC%%&device_id=%%DEVICE_IOS_IFA%%&store_url=%%APP_STORE_URL_ENC%%&carrier_id=%%CARRIER_ID%%&appname=%%APP_NAME_ENC%%`;
					break;
				case 'kayzen':
					url += `&aff_clickid={CONVERSION_ID}&sub1={PUBLISHER_ID}&sub2={APP_ID}&ssp={EXCHANGE_NAME}&campaign_id={CAMPAIGN_ID}&ip={USER_IP}&bundle={APP_BUNDLE}&device_id={DEVICE_PLATFORM_ID}&carrier_id={CARRIER_NAME}&appname={APP_NAME_ALPHANUM}&att={DNT}`;
					break;
				case 'mediasmart':
					url += `&sub1=%pub%&sub2=%placement_id%&ssp_id=%exchange%&campaign_id=%campaign_id%&ip=%ip%&bundle=%bundle%&device_id=%udid%&store_url=%app_storeurl%&carrier_id=%carrier%`;
					break;
			}
			return `<div id="dw-${this.tag.short_uuid}"></div><script `+` src="${url}"><`+`/script>`;
		}
	},
	methods: {
		showPreview(){
			let code = this.exchangeHTMLCode;
			$('#tag-preview').html(code);
		},
		clearPreview(){
			$('#tag-preview').html('');
		},
		addBanner(){
			this.tag.banners.push({
				enabled: true,
				placement_id: null,
				creative_id: null,
				weight: 0,
			});
		},
		removeBanner(index){
			this.tag.banners.splice(index, 1);
		},
		addBGPlacement(){
			this.tag.background_placements.push({
				enabled: true,
				placement_id: null,
				weight: 100
			});
		},
		removeBGPlacement(index){
			this.tag.background_placements.splice(index, 1);
		},
		async submit(closeAfterSave = false){
			if (this.busy){
				return;
			}
			this.busy = true;
			let data = Vue.util.extend({}, this.tag);
			try {
				let resp = await this.$api.post('tags/save', data);
				this.busy = false;
				this.$notify.success('Tag has been saved');
				if (closeAfterSave) {
					this.$router.push({name: 'tag-list'});
				} else {
					this.tag = resp.entity;
				}
			} catch (e) {
				console.error(e);
				this.busy = false;
			}
		}
	},
	mounted(){
		let vm = this;
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Media'},
			{title: 'Supply'},
			{title: 'Tags'},
			{title: this.tag && this.tag.id ? 'Edit Tag' : 'Add Tag'}
		]);
		this.$store.dispatch('setBackButton', {
			route: {name: 'tag-list'}
		});
		this.$store.dispatch('setPageButtons', [
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save',
				action(){
					vm.submit();
				}
			},
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save & Close',
				action(){
					vm.submit(true);
				}
			}
		]);
	},

}

</script>
