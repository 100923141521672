<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		.card.card-custom.gutter-b
			.card-header
				.card-title
					.card-label Monitor SSL
				.card-toolbar
			.card-body
				form.form.form-inline.form-filters(@submit.prevent="loadData()", :class="{expanded:filtersExpanded}")
					a.btn.btn-primary.toggle-filters.d-md-none(v-b-toggle.form-filters)
						span Filters
						i.fa.fw.fa-chevron-up.transition-all(:class="{'rotate-180':filtersExpanded}")
					b-collapse#form-filters.dont-collapse-sm(style="width: 100%;", v-model="filtersExpanded")
						.row.filters-form-row
							.form-row-main.col-auto.mr-auto(style="flex:1;")
								.form-group
									label Search
									input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.keyword")
								//.form-group
									label Status
									select.form-control(v-model="filters.status")
										option(v-for="o in offerStatusOptions", :value="o.v") {{ o.t }}
								// select-advertiser(v-model="filters.advertiser_id")
								.form-group.no-label
									button.btn.btn-primary(type="submit") Go
							.form-row-end.col-auto
								//select-advertiser(v-model="addAdvertiser", :multiple="false", @input="addOffer")
								.form-group.no-label
									button.btn.btn-success(type="button", @click="addRecord()")
										i.flaticon2-plus
										| Add

				p.clearfix &nbsp;

		.card.card-custom.gutter-b
			.card-body
				.table-wrapper
					table.table.table-bordered.table-compact
						thead
							tr
								th ID
								th Enabled
								th Domain
								th Status
								th Expiration
								th Days Left
								th Last Check
								th(style="padding-top: 0; padding-bottom: 0; vertical-align: middle; ") &nbsp;
									//b-dropdown(text="Bulk Actions", size="sm", variant="secondary")
										b-dropdown-item(href="#", @click="bulkConnect()") Connect
										b-dropdown-item(href="#", @click="bulkPause()") Pause
										b-dropdown-item(href="#", @click="bulkActivate()") Activate
										b-dropdown-item(href="#", @click="bulkExport()") Export
										b-dropdown-item(href="#", @click="bulkStatistics()") View statistics
										b-dropdown-item(href="#", @click="bulkModal('daily_click_cap', 'Set Click Cap')") Set Click Cap
						tbody
							tr(v-if="records.length === 0")
								td(colspan="18") No matching records were found
							tr(v-for="r in records", v-bind:key="r.id")
								td {{ r.id }}
								td {{ r.enabled }}
								td {{ r.domain }}
								td {{ r.status }}
								td {{ r.expiration_date }}
								td {{ r.days_left }}
								td {{ r.last_check }}
								td.actions
									button.btn.btn-action.btn-secondary(type="button", @click="editRecord(r)", v-b-tooltip.hover.bottom, title="Edit")
										i.la.la-pencil
									| &nbsp;
									button.btn.btn-action.btn-success(type="button", @click="checkRecord(r)", v-b-tooltip.hover.bottom, title="Check")
										i.la.la-refresh
									| &nbsp;
									button.btn.btn-action.btn-danger(type="button", @click="deleteRecord(r)", v-b-tooltip.hover.bottom, title="Delete")
										i.la.la-trash

					paginate(:paginator="paginate", @update-page-size="loadData")

		b-modal#monitor-ssl-form-modal(title="SSL", @ok="saveRecord")
			loading(:active.sync="busySave", :is-full-page="false")
			form.form.form-horizontal(v-if="currentRecord")
				.row
					.col-sm-12
						.form-group
							label Domain
							input.form-control(type="text", v-model="currentRecord.domain", placeholder="example.com")


</template>
<style lang="scss">
.mmp {
	text-transform: uppercase;
	font-weight: bold;
}

.category {
	position: absolute;
	bottom: 2px;
	left: 2px;
	font-size: 10px;
	color: #aaa;
}

.created_at {
	position: absolute;
	bottom: 2px;
	right: 2px;
	font-size: 10px;
	color: #aaa;
}
</style>
<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import Vue from 'vue';

export default {
	name: 'MonitorSSLList',
	async beforeRouteEnter(to, from, next){
		next(vm => {
			vm.loadData();
			return vm;
		});
	},
	data(){
		return {
			busy: false,
			paginate: {
				numPages: 0,
				total: 0,
				page: 1,
				limit: 50,
				onPageChange: () => {
					this.loadData();
				}
			},
			filters: {
				keyword: '',
				status: '',
			},
			records: [],
			filtersExpanded: false,
			statusOptions: [{
				v: null,
				t: '- All -',
			}],
			busySave: false,
			currentRecord: null,

		};
	},
	mounted(){
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Monitor'},
			{title: 'SSL'},
		]);
	},
	methods: {

		addRecord(){
			this.currentRecord = {
				id: 0,
				domain: ''
			};
			this.$bvModal.show('monitor-ssl-form-modal');
		},
		editRecord(r){
			this.currentRecord = Vue.util.extend({}, r);
			this.$bvModal.show('monitor-ssl-form-modal');
		},
		async checkRecord(r){
			try {
				let data = {
					id: r.id
				};
				let resp = await this.$api.post('monitor/ssl/check', data);
				this.busySave = false;
				this.loadData();
			} catch (e) {
				console.error(e);
				this.busySave = false;
			}
		},
		async deleteRecord(r){
			if (!confirm('Are you sure?')){
				return;
			}
			try {
				let data = {
					id: r.id
				};
				let resp = await this.$api.post('monitor/ssl/delete', data);
				this.busySave = false;
				this.$notify.success('Record has been removed');
				this.loadData();
			} catch (e) {
				console.error(e);
				this.busySave = false;
			}
		},
		async saveRecord(bvModalEvt){
			bvModalEvt.preventDefault();
			if (this.busySave) {
				return;
			}
			this.busySave = true;
			let data = Vue.util.extend({}, this.currentRecord);
			try {
				let resp = await this.$api.post('monitor/ssl/save', data);
				this.busySave = false;
				this.$notify.success('Record has been saved');
				this.$bvModal.hide('monitor-ssl-form-modal');
				this.loadData();
			} catch (e) {
				console.error(e);
				this.busySave = false;
			}
		},
		async loadData(){
			this.busy = true;
			let params = {
				page: this.paginate.page,
				limit: this.paginate.limit,
				keyword: this.filters.keyword,
				status: this.filters.status,
				platform: this.filters.platform,
				countries: this.filters.countries,
			};
			try {
				let resp = await this.$api.get('/monitor/ssl/getList', {params});
				this.records = resp.records;
				this.paginate.total = resp.total;
				this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
			} catch (e) {
				console.error(e);
			}
			this.busy = false;
		}
	}
}
</script>
