<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		.card.card-custom.gutter-b
			.card-header
				.card-title
					.card-label External Offers
				.card-toolbar
			.card-body
				form.form.form-inline.form-filters(@submit.prevent="loadData()", :class="{expanded:filtersExpanded}")
					a.btn.btn-primary.toggle-filters.d-md-none(v-b-toggle.form-filters)
						span Filters
						i.fa.fw.fa-chevron-up.transition-all(:class="{'rotate-180':filtersExpanded}")
					b-collapse#form-filters.dont-collapse-sm(style="width: 100%;", v-model="filtersExpanded")
						.row.filters-form-row
							.form-row-main.col-auto.mr-auto(style="flex:1;")
								.form-group
									label Search
									input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.keyword")
								select-advertiser(v-model="filters.advertiser_id")
								//.form-group
									label Advertiser
									select.form-control(v-model="filters.advertiser_id")
										option(value="all") - All -
										option(v-for="o in advertiserOptions", :value="o.v") {{ o.t }}
								.form-group
									label OS
									select.form-control(v-model="filters.platform")
										option(value="") - All -
										option(value="ios") iOS
										option(value="android") Android
								.form-group
									label Countries
									input.form-control.input-search(type="text", placeholder="", v-model="filters.countries")
								.form-group
									label Status
									select.form-control(v-model="filters.status")
										option(value="all") - All -
										option(value="active") Active
										option(value="inactive") InActive
								.form-group.no-label
									button.btn.btn-primary(type="submit") Go

		.card.card-custom.gutter-b
			.card-body
				.table-wrapper
					table.table.table-bordered
						thead
							tr
								th ID
								th Active
								th Advertiser
								th Name
								th Asset
								th AdvID
								th Offers
								th Country
								th OS
								th Payout
								th Model
								th Cap
								th Preview
								th Last Update
								th &nbsp;
						tbody
							tr(v-if="records.length === 0")
								td(colspan="14") No matching records were found
							tr(v-for="r in records", :key="r.id")
								td {{ r.id }}
								td
									span.btn.btn-circle.btn-success(v-if="r.active")
										i.la.la-check
									span.btn.btn-circle.btn-secondary(v-if="!r.active")
										i.la.la-times
								td
									entity(v-if="r.advertiser", :id="r.advertiser.id", :name="r.advertiser.name", type="advertiser")
								td {{ r.name }}
								td {{ r.asset ? r.asset.package_id : '' }}
								td {{ r.external_id }}
								td
									div(v-if="r.offers && r.offers.length")
										span(v-for="o in r.offers")
											router-link(:to="{name:'offer-edit', params: {id: o.id}}") {{ o.id }}
									router-link(:to="{name:'offer-add', query:{eo_id:r.id}}")
										i.la.la-plus
								td
									div(style="max-width: 200px; word-break: break-all;") {{ r.countries }}
								td {{ r.platform }}
								td {{ r.payout }}
								td {{ r.payout_model }}
								td {{ r.daily_conversion_cap }}
								td
									a.btn.btn-sm.btn-secondary(:href="r.preview_url", v-if="r.preview_url", target="_blank", v-b-tooltip.hover.bottom, title="Preview")
										i.la.la-search
								td {{ r.last_sync }}
								td
									button.btn.btn-sm.btn-secondary(type="button", @click="autoCreate(r, false)", v-b-tooltip.hover.bottom, title="Auto-Create")
										i.la.la-copy
									| &nbsp;
									button.btn.btn-sm.btn-secondary(type="button", @click="autoCreate(r, true)", v-b-tooltip.hover.bottom, title="Auto-Create")
										i.la.la-play
									| &nbsp;
									button.btn.btn-sm.btn-secondary(type="button", @click="showOriginal(r)")
										i.la.la-search

					paginate(:paginator="paginate", @update-page-size="loadData")

					b-modal#original-modal(title="Original")
						pre(v-if="selectedOffer") {{ selectedOffer.original }}

</template>
<script>
import Vue from 'vue';
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
	name: 'ExternalOfferList',
	async beforeRouteEnter(to, from, next){
		let advertiserOptions = await Vue.$dwData.advertiser.getOptions();
		next(vm => {
			vm.advertiserOptions = advertiserOptions;
			vm.loadData();
			return vm;
		});
	},
	data(){
		return {
			busy: false,
			filtersExpanded: false,
			paginate: {
				numPages: 0,
				total: 0,
				page: 1,
				limit: 50,
				onPageChange: () => {
					this.loadData();
				}
			},
			filters: {
				keyword: '',
				status: 'active',
				platform: '',
				countries: '',
				advertiser_id: []
			},
			records: [],
			payoutModelOptions: Vue.$dwData.general.payoutModelOptions,
			selectedOffer: null
		};
	},
	methods: {

		showOriginal(r){
			this.selectedOffer = r;
			this.$bvModal.show('original-modal');
		},

		async autoCreate(r, autoRun = false){
			if (this.busy){
				return;
			}
			this.busy = true;
			try {
				let resp = await this.$api.post('/external-offers/autoCreate', {id:r.id, auto_run: autoRun});
				this.busy = false;
				this.loadData();
			} catch (e){
				console.error(e);
				this.busy = false;
			}
		},


		async loadData(){
			this.busy = true;
			let params = {
				page: this.paginate.page,
				limit: this.paginate.limit,
				keyword: this.filters.keyword,
				status: this.filters.status,
				platform: this.filters.platform,
				countries: this.filters.countries,
				advertiser_id: this.filters.advertiser_id.map(r => r.v).join(','),
			};
			try {
				let resp = await this.$api.get('/external-offers/getList', {params});
				this.records = resp.records;
				this.paginate.total = resp.total;
				this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
			} catch (e) {
				console.error(e);
			}
			this.busy = false;
		}
	},
	mounted(){
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Media'},
			{title: 'External Offers'}
		]);
	},
}
</script>
