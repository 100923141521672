<template lang="pug">
	div.page-reports
		loading(:active.sync="busy", :is-full-page="true")
		.card.card-custom.gutter-b(v-if="filters")
			.card-header
				.card-title
					.card-label UDP Report
				.card-toolbar
			.card-body
				form.form.dw-filters(@submit.prevent="loadData()", :class="{expanded:filtersExpanded}")
					.form-blocks
						.block.block-dimensions
							.block-header
								h3 Dimensions
							.block-body
								.selection-list
									div(v-for="d in dimensions", :class="{selected:d._selected}")
										b-checkbox(v-model="d._selected") {{ d.label }}
								.selected-items
									div(v-for="d in selectedDimensions")
										a(href="javascript:void(0);", @click="removeDimension(d)")
											i.la.la-times
										span {{ d.label }}
						.block.block-filters
							.block-header
								h3 Filters
								.block-actions
									a(href="javascript:void(0);", @click="clearFilters()") Clear
							.block-body.form-inline
								div(style="width: 100%")
									div(style="width: 100%")
										//, @input="updateDates"
										dw-date-time-picker(v-model="filters.datePicker")
										.form-group
											label Group Timeframe
											select.form-control(v-model="filters.group_timeframe", style="width: 120px;")
												option(value="none") None
												option(value="hour") Hour
												option(value="day") Day
							div(style="width: 100%")
								select-publisher(v-model="filters.publisher_id")
								.form-group
									label Country
									input.form-control(type="text", v-model="filters.country")
								.form-group
									label Regions
									input.form-control(type="text", v-model="filters.region")
								.form-group
									label Cities
									input.form-control(type="text", v-model="filters.city")
								.form-group
									label ISPs
									input.form-control(type="text", v-model="filters.isp")
								.form-group
									label Carriers
									input.form-control(type="text", v-model="filters.carrier")
								//.form-group
									label OS
									multiselect(:multiple="true", :options="osOptions", v-model="filters.os_id",
										track-by="v", label="t", deselect-label="", select-label="")
								.form-group
									label Platform
									select.form-control(v-model="filters.platform", style="width: 120px;")
										option(:value="0") All
										option(:value="1") ios
										option(:value="2") android
								.form-group
									label Device
									multiselect(:multiple="true", :options="deviceOptions", v-model="filters.device_id",
										track-by="v", label="t", deselect-label="", select-label="")
								.form-group
									label Browser
									multiselect(:multiple="true", :options="browserOptions", v-model="filters.browser_id",
										track-by="v", label="t", deselect-label="", select-label="")


					.row-buttons
						.submit-buttons
							b-button-group
								b-dropdown(split-button-type="submit", variant="primary", right, split, text="Go")
									b-dropdown-item(@click="loadData(1)")
										i.la.la-download
										| Export
									b-dropdown-item(@click="loadData(2)")
										i.la.la-download
										| Background Report
									b-dropdown-item(@click="showBackgroundReportsModal(true)")
										i.la.la-external-link
										| View Report
		.card.card-custom
			.card-header.border-0.pt-5
				h3.card-title Results
			.card-body.pt-3.pb-0
				paginate(:paginator="paginate", @update-page-size="loadData")
				.stats-table-wrapper
					table.table.table-bordered.tbl-statistics(v-if="resultMeta")
						thead
							tr
								th(v-if="filters.group_timeframe === 'day'") Date
								th(v-if="filters.group_timeframe === 'hour'") Hour
								th.col-dim(v-for="d in resultMeta.dimensions", :class="'col-'+d.name") {{ d.label }}
								th.col-metric Total
						tbody.summary
							tr(v-if="summary")
								td(v-for="d in resultMeta.dimensions") &nbsp;
								td.c
									span {{ summary.total.toLocaleString() }}
						tbody
							//tr(v-if="records.length===0")
								//td(:colspan="numColumns") No matching records were found
							tr(v-for="r in records", v-bind:key="r.key")
								td.nowrap(v-if="filters.group_timeframe === 'day'") {{ r.group_date }}
								td.nowrap(v-if="filters.group_timeframe === 'hour'") {{ r.group_hour }}
								template(v-for="d in resultMeta.dimensions")
									td.col-entity(v-bind:key="r.key+'_'+(d.name)")
										entity.no-bg(v-if="r[d.name] && r[d.name].id", :id="r[d.name].id", :name.sync="r[d.name].name", :type="d.name",
											:mode="d.mode", :data="r[d.name]",
											:max-width="d.width", @click="appendEntity(r,d)", @dblclick="selectEntity(r,d)")
										span(v-if="!r[d.name] || !r[d.name].id") {{ r[d.field] }}

								td.c
									span {{ r.total.toLocaleString() }}

						tfoot.summary
							tr(v-if="summary")
								th(v-for="d in resultMeta.dimensions") &nbsp;
								th.c
									span {{ summary.total.toLocaleString() }}

				paginate(:paginator="paginate", @update-page-size="loadData")
</template>
<script>
import moment from "moment";
import Vue from "vue";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
	name: 'UDPReport',
	async beforeRouteEnter(to, from, next){

		let formInfo = await Vue.$api.get('udp/getFormInfo');
		let publisherOptions = await Vue.$dwData.publisher.getOptions(true);
		let remotePublisherOptions = [];
		next(async(vm)=>{

			vm.dimensions = formInfo.dimensions;
			vm.dimensions.forEach(d => {
				Vue.set(d, '_selected', false);
				vm.dimHash[d.name] = d;
			});

			vm.publisherOptions = publisherOptions;
			vm.remotePublisherOptions = remotePublisherOptions;

			vm.presets = formInfo.presets;
			vm.browserOptions = formInfo.options.browser;
			vm.osOptions = formInfo.options.os;
			vm.deviceOptions = formInfo.options.device;
			vm.setPreset(vm.presets[0]);

			return vm;
		});
	},
	computed: {
		selectedDimensionHash(){
			let h = {};
			this.dimensions.forEach(d => {
				if (d._selected) {
					h[d.name] = d;
				}
			});
			return h;
		},
		selectedDimensions(){
			return this.dimensions.filter(d => d._selected);
		},
	},
	data(){
		return {
			busy: false,
			paginate: {
				numPages: 0,
				total: 0,
				page: 1,
				limit: 50,
				onPageChange: () => {
					this.loadData();
				}
			},
			filtersExpanded: false,
			// sortBy: {
			// 	field: 'revenue',
			// 	direction: 'desc',
			// 	sort: null,
			// },
			dimensions: [],
			dimHash: {},
			records: [],
			summary: null,
			resultMeta: null,
			publisherOptions: [],
			remotePublisherOptions: [],
			osOptions: [],
			deviceOptions: [],
			browserOptions: [],
			filters: {
				publisher_id: [],
				remote_publisher_id: [],
				platform: 1,
				os_id: [],
				device_id: [],
				browser_id: [],
				country: 'US',
				region: '',
				city: '',
				isp: '',
				carrier: '',
				status: 'all',
				group_timeframe: 'none',

				datePicker: {
					timezone: 0,
					dateRange: {
						startDate: null,
						endDate: null
					},
				}
			},
			reportsConfig: {
				loadOnStart: true
			}
		};
	},
	methods: {
		removeDimension: function (d){
			d._selected = false;
		},
		updateDates(d){
			this.filters.datePicker = d;
			// console.log('updateDates', d);
		},

		setPreset(p){
			if (p.type === 'full' || p.type === 'dimensions') {
				this.dimensions.forEach(d => {
					let selected = p.dimensions.indexOf(d.name) > -1;
					if (selected || p.action === 'set') {
						Vue.set(d, '_selected', selected);
					}
					if (d.children) {
						d.children.forEach(cd => {
							let selected = p.dimensions.indexOf(cd.name) > -1;
							if (selected || p.action === 'set') {
								Vue.set(cd, '_selected', selected);
							}
						});
					}
				});
			}
		},

		async loadData(doExport){

			let dimensions = this.dimensions.filter(d => d._selected).map(d => d.name);

			let params = {
				export: doExport ? doExport : 0,
				extended_params: this.filters.extended_params ? 1 : 0,
				page: this.paginate.page,
				page_size: this.paginate.limit,
				group_timeframe: this.filters.group_timeframe,
				timezone: this.filters.datePicker.timezone,
				date_start: moment(this.filters.datePicker.dateRange.startDate).format('YYYY-MM-DD HH:mm'),
				date_end: moment(this.filters.datePicker.dateRange.endDate).format('YYYY-MM-DD HH:mm'),
				// sort_col: this.sortBy.field,
				// sort_dir: this.sortBy.direction,
				// groups_mode: this.filters.groups_mode,
				filters: {
					platform: this.filters.platform,
					publisher_id: this.filters.publisher_id.map(r => r.v),
					remote_publisher_id: this.filters.remote_publisher_id.map(r => r.v),
					os_id: this.filters.os_id.map(r => r.v),
					device_id: this.filters.device_id.map(r => r.v),
					browser_id: this.filters.browser_id.map(r => r.v),
					country: this.filters.country,
					region: this.filters.region,
					city: this.filters.city,
					isp: this.filters.isp,
					carrier: this.filters.carrier,
				},
				dimensions: dimensions
			};
			if (this.busy) {
				return;
			}
			this.busy = true;
			console.log(params);
			try {
				let resp = await this.$api.post('udp/report', params);

				this.records = resp.records.slice(0, this.paginate.limit);
				this.summary = resp.summary;
				this.paginate.total = resp.total;
				this.resultMeta = {
					dimensions: resp.dimensions,
				};
				this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);

			} catch (e) {
				console.log(e);
			}
			this.busy = false;
		}
	},
	mounted(){
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Media'},
			{title: 'Supply'},
			{title: 'UDP Report'}
		]);
	},
}
</script>
