<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		form(@submit.prevent="submit", v-if="dataControl")
			.row
				.col-md-6
					.card.card-custom.gutter-b
						.card-header
							.card-title
								.card-label Details
						.card-body
							.row
								.col-sm-6
									.form-group
										label ID
										input.form-control.readonly(type="text", :value="dataControl.id", readonly="readonly")
								.col-sm-6
									.form-group
										label Network
										select.form-control(v-model="dataControl.network_id")
											option(v-for="o in networkOptions", :value="o.v") {{ o.t }}
							.form-group
								label Name
								input.form-control(type="text", v-model="dataControl.name")
							.form-group
								label Countries
								textarea.form-control(rows="3", placeholder="GB, US, ...", v-model="dataControl.countries")
							.form-group
								label Platform
								select.form-control(v-model="dataControl.platform")
									option(v-for="o in platformOptions", :value="o.v") {{ o.t }}

					.card.card-custom.gutter-b
						.card-header
							.card-title
								.card-label Override
						.card-body
							.row
								.col-sm-6
									.form-group
										label Keyword Select Mode
										select.form-control(v-model="dataControl.keyword_select_mode")
											option(value="random") random
											option(value="priority") priority
									.form-group
										label Keyword
										textarea.form-control(v-model="dataControl.keyword_override", rows="15")
										p.help-block Lines: {{ keyword_lines }}
									.form-group
										label Sub2
										input.form-control(type="text", v-model="dataControl.total_sub2")
									.form-group
										textarea.form-control(v-model="dataControl.sub2_override", rows="20")
										p.help-block Lines: {{ sub2_lines }}
								.col-sm-6
									.form-group
										b-form-checkbox(v-model="dataControl.sub1_rotate", switch) Auto-rotate
									.row
										.col-sm-6
											.form-group
												label # Subsources
												input.form-control(type="text", v-model="dataControl.num_sources")
										.col-sm-6
											.form-group
												label Sub1 Generate Mode
												select.form-control(v-model="dataControl.sub1_generate_mode")
													option(value="random") random
													option(value="pid_appname") pid_appname

									.form-group
										label Subsource
										textarea.form-control(v-model="dataControl.sub1_override", rows="20")
										p.help-block Lines: {{ sub1_lines }}

									h4 Clean the following sources
									.form-group
										button.btn.btn-default(type="button", @click="cleanData()") Clean
									.form-group
										textarea.form-control(v-model="autoCleanData", rows="20")


				.col-md-6
					.card.card-custom.gutter-b
						.card-header
							.card-title
								.card-label Asset Source
						.card-body
							table.table.table-compact.asset-source-list
								thead
									tr
										th Bundle
										th AppName
										th.actions
											button.btn.btn-add.btn-circle.btn-primary(type="button", @click="addAssetOverrideRow()")
												i.la.la-plus
								tbody
									tr(v-for="(a, i) in dataControl.asset_override")
										td
											input.form-control.form-control-sm(type="text", v-model="a.bundle")
										td
											input.form-control.form-control-sm(type="text", v-model="a.appname")
										td.actions
											button.btn-remote(type="button", @click="dataControl.asset_override.splice(i,1)")
												i.la.la-times


							textarea.form-control(v-model="assetOverrideImport", rows="6")
</template>
<style lang="scss">
table.table.table-compact.asset-source-list {
	thead {
		tr {
			th {
				button.btn-add {
					width: 16px;
					height: 16px;
					line-height: 16px;
					text-align: center;
					border: none;
					padding: 0;
				}

				&.actions {
					width: 30px;
					text-align: center;
				}
			}
		}
	}
	tbody {
		tr {
			td {
				padding: 0;
				border: none;
				line-height: 20px;
				.form-control-sm {
					height: 20px;
					line-height: 18px;
					padding-top: 0;
					padding-bottom: 0;
					border: none;
					border-radius: 0;
					border-bottom: 1px solid #ddd;
					background: transparent;
				}

				.btn-remote {
					width: 16px;
					height: 16px;
					line-height: 16px;
					text-align: center;
					border: none;
					color: #aaa;
					background: #f6f6f6;
					border-radius: 100%;
					padding: 0;
					&:hover {
						color: #f63333;
					}
				}

				&.actions {
					text-align: center;
				}
			}
			&:hover {

				td {
					background: #f6f6f6;
				}
			}
		}
	}
}
</style>
<script>
import Vue from 'vue';
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';

async function loadRoute(vm, to, from, next){

	let dataControl = {};
	if (to.name === 'data-control-edit') {
		let entityId = +to.params.id;
		try {
			dataControl = await Vue.$dwData.dataControl.get(entityId, true);
		} catch (e) {
			Vue.$notify.error('Failed to load data control');
			return next(false);
		}
	} else {
		let copyId = +to.query.copy_id;
		if (copyId){
			try {
				dataControl = await Vue.$dwData.dataControl.get(copyId, true);
				dataControl.id = 0;
			} catch (e) {
				Vue.$notify.error('Failed to load data control');
				return next(false);
			}
		} else {
			dataControl = Vue.$dwData.dataControl.newInstance();
		}
	}
	let networkOptions = await Vue.$dwData.network.getOptions();

	return vm => {
		vm.dataControl = dataControl;
		vm.networkOptions = networkOptions;
		vm.busy = false;
		return vm;
	};
}

export default {
	async beforeRouteEnter(to, from, next){
		let bind = await loadRoute({}, to, from, next);
		next(bind);
	},
	async beforeRouteUpdate(to, from, next){
		await loadRoute(this, to, from, next);
		next();
	},
	computed: {
		sub1_lines(){
			let sub1Overrides = this.dataControl.sub1_override || '';
			let parsed = sub1Overrides.split("\n");
			return parsed.length;
		},
		sub2_lines(){
			let sub1Overrides = this.dataControl.sub2_override || '';
			let parsed = sub1Overrides.split("\n");
			return parsed.length;
		},
		keyword_lines(){
			let sub1Overrides = this.dataControl.keyword || '';
			let parsed = sub1Overrides.split("\n");
			return parsed.length;
		}
	},
	name: 'DataControlForm',
	data(){
		return {
			busy: true,
			dataControl: null,
			platformOptions: Vue.$dwData.general.platformOptions,
			assetOverrideList: [],
			networkOptions: [],
			assetOverrideImport: '',
			autoCleanData: ''
		};
	},
	methods: {

		addAssetOverrideRow(){
			this.dataControl.asset_override.push({
				bundle: '',
				appname: '',
			});
		},

		cleanData(){
			let rawData = this.autoCleanData;
			let parsedToRemove = rawData.split("\n");
			let sources = this.dataControl.sub1_override.split("\n");
			sources = sources.filter(s => {
				return parsedToRemove.indexOf(s) === -1;
			});
			this.dataControl.sub1_override = sources.join("\n");
		},

		async submit(closeAfterSave = false){
			if (this.busy){
				return;
			}
			this.busy = true;
			let data = Vue.util.extend({}, this.dataControl);
			try {
				let resp = await this.$api.post('data-control/save', data);
				this.busy = false;
				this.$notify.success('Data Control has been saved');
				if (closeAfterSave) {
					this.$router.push({name: 'data-control-list'});
				} else {
					this.dataControl = resp.entity;
				}
			} catch (e) {
				console.error(e);
				this.busy = false;
			}
		}
	},
	mounted(){
		let vm = this;
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Media'},
			{title: 'Data Control', name: 'data-control-list'},
			{title: this.offer && this.offer.id ? 'Edit Data Control' : 'Add Data Control'}
		]);
		this.$store.dispatch('setBackButton', {
			route: {name: 'data-control-list'}
		});
		this.$store.dispatch('setPageButtons', [
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save',
				action(){
					vm.submit();
				}
			},
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save & Close',
				action(){
					vm.submit(true);
				}
			}
		]);
	},
}

</script>
