<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		.card.card-custom.gutter-b
			.card-header
				.card-title
					.card-label Automatic Imports
				.card-toolbar
			.card-body
				form.form.form-inline.form-filters(@submit.prevent="loadData()", :class="{expanded:filtersExpanded}")
					a.btn.btn-primary.toggle-filters.d-md-none(v-b-toggle.form-filters)
						span Filters
						i.fa.fw.fa-chevron-up.transition-all(:class="{'rotate-180':filtersExpanded}")
					b-collapse#form-filters.dont-collapse-sm(style="width: 100%;", v-model="filtersExpanded")
						.row.filters-form-row
							.form-row-main.col-auto.mr-auto(style="flex:1;")
								//.form-group
									label Search
									input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.keyword")
								.form-group
									label Countries
									input.form-control.input-search(type="text", placeholder="", v-model="filters.countries")
								.form-group
									label R.PubID
									input.form-control.input-search(type="text", placeholder="", v-model="filters.remote_publisher_id")
								.form-group
									label R.OfferID
									input.form-control.input-search(type="text", placeholder="", v-model="filters.remote_offer_id")
								.form-group
									label Status
									select.form-control(v-model="filters.status")
										option(v-for="o in statusOptions", :value="o.v") {{ o.t }}
								// select-advertiser(v-model="filters.advertiser_id")
								.form-group.no-label
									button.btn.btn-primary(type="submit") Go
							.form-row-end.col-auto
								//select-advertiser(v-model="addAdvertiser", :multiple="false", @input="addOffer")
								.form-group.no-label
									button.btn.btn-success(type="button", @click="addJob()")
										i.flaticon2-plus
										| Add

				p.clearfix &nbsp;

		.card.card-custom.gutter-b
			.card-body
				.table-wrapper
					table.table.table-bordered
						thead
							tr
								th &nbsp;
								th ID
								th Status
								th Publisher
								th R.PubID
								th R.OfferID
								th OS
								th Geo
								th Priority
								th Created At
								th(style="padding-top: 0; padding-bottom: 0; vertical-align: middle; ") &nbsp;
						tbody
							tr(v-if="records.length === 0")
								td(colspan="11") No matching records were found
							tr(v-for="r in (selectedTab==='bucket' ? bucket : records)", v-bind:key="r.id", :class="{'row-selected': bucketHash[r.id]}")
								td.col-select(:class="{'bucket-row':bucketHash[r.id]}")
									a(href="javascript:void(0)", @click="toggleBucket(r)")
										i.la(:class="{'la-check-square':bucketHash[r.id], 'la-stop':!bucketHash[r.id]}")
								td {{ r.id }}
								td {{ r.status }}
								td
									entity(:id="r.publisher.id", :name="r.publisher.name", type="publisher")
								td [{{ r.remote_publisher_id }}] {{ r.remotePublisher ? r.remotePublisher.name : '' }}
								td {{ r.remote_offer_id }}
								td {{ r.platform }}
								td {{ r.country }}
								td {{ r.priority }}
								td {{ r.created_at }}
								td.actions
									button.btn.btn-action.btn-secondary(type="button", @click="editJob(r)")
										i.la.la-pen
									button.btn.btn-action.btn-secondary(type="button", @click="createImportJob(r)")
										i.la.la-file
									button.btn.btn-action.btn-danger(type="button", @click="deleteRecord(r)")
										i.la.la-trash

					paginate(:paginator="paginate", @update-page-size="loadData")


					b-modal#manual-import-job-form-modal(title="Import dates", @ok="createManualImport")
						loading(:active.sync="busySave", :is-full-page="false")
						form.form.form-horizontal(v-if="newImportJob")
							.row
								.col-sm-6
									label Publisher
									input.form-control(type="text", v-model="newImportJob.publisher_id")
							.row
								.col-sm-6
									.form-group
										label From date
										input.form-control(type="text", v-model="newImportJob.click_date_from", placeholder="2021-01-14")
								.col-sm-6
									.form-group
										label From date
										input.form-control(type="text", v-model="newImportJob.click_date_to", placeholder="2021-01-14")
							.row
								.col-sm-6
									.form-group
										label Country
										input.form-control(type="text", v-model="newImportJob.country", placeholder="US")
								.col-sm-6
									.form-group
										label Platform
										select.form-control(v-model="newImportJob.platform")
											option(value="") - All -
											option(value="ios") iOS
											option(value="android") Android

							.row
								.col-sm-6
									.form-group
										label Remote Publisher ID
										input.form-control(type="text", v-model="newImportJob.remote_publisher_id")
								.col-sm-6
									.form-group
										label Remote Offer ID
										input.form-control(type="text", v-model="newImportJob.remote_offer_id")

							.row
								.col-sm-6
									.form-group
										label Limit
										input.form-control(type="text", v-model="newImportJob.limit")
								.col-sm-6
									.form-group
										label Status
										select.form-control(v-model="newImportJob.status")
											option(value="new") New
											option(value="skip") Skip

					b-modal#job-form-modal(title="Job Info", @ok="saveJob")
						loading(:active.sync="busySave", :is-full-page="false")
						form.form.form-horizontal(v-if="currentJob")
							.row
								.col-sm-6
									.form-group
										label Platform
										select.form-control(v-model="currentJob.status")
											option(value="active") Active
											option(value="paused") Paused
							.row
								.col-sm-6
									.form-group
										label Country
										input.form-control(type="text", v-model="currentJob.country", placeholder="US")
								.col-sm-6
									.form-group
										label Platform
										select.form-control(v-model="currentJob.platform")
											option(value="") All
											option(value="ios") iOS
											option(value="android") Android
							.row
								.col-sm-6
									.form-group
										label Publisher ID
										input.form-control(type="text", v-model="currentJob.publisher_id")
										//select-publisher(v-model="currentJob.publisher_id")
							.row
								.col-sm-4
									.form-group
										label Remote Publisher ID
										input.form-control(type="text", v-model="currentJob.remote_publisher_id")
								.col-sm-4
									.form-group
										label Remote Offer ID
										input.form-control(type="text", v-model="currentJob.remote_offer_id")
								.col-sm-4
									.form-group
										label Priority
										input.form-control(type="text", v-model="currentJob.priority")


</template>
<style lang="scss">
.category {
	position: absolute;
	bottom: 2px;
	left: 2px;
	font-size: 10px;
	color: #aaa;
}

.created_at {
	position: absolute;
	bottom: 2px;
	right: 2px;
	font-size: 10px;
	color: #aaa;
}
</style>
<script>
import Vue from 'vue';
import moment from 'moment';
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import QuickConnectOffersModal from "@/views/components/QuickConnectOffersModal";

export default {
	name: 'AutoImportList',
	async beforeRouteEnter(to, from, next){
		let publisherOptions = [];//await Vue.$dwData.publisher.getOptions();
		let remotePublisherOptions = [];//await Vue.$dwData.remotePublisher.getOptions();
		next(vm => {
			vm.remotePublisherOptions = remotePublisherOptions;
			vm.loadData();
			return vm;
		});
	},
	data(){
		return {
			busy: false,
			busySave: false,
			selectedTab: 'search',
			paginate: {
				numPages: 0,
				total: 0,
				page: 1,
				limit: 50,
				onPageChange: () => {
					this.loadData();
				}
			},
			filters: {
				keyword: '',
				status: null
			},
			bucket: [],
			bucketHash: {},
			records: [],
			currentJob: null,
			newImportJob: null,
			filtersExpanded: false,
			statusOptions: [
				{ v: null, t: '- All -', },
				...Vue.$dwData.autoImportJob.statusOptions
			],
		};
	},
	mounted(){
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Media'},
			{title: 'Automation'},
			{title: 'Auto-Imports'}
		]);
	},
	methods: {

		addJob(){
			this.currentJob = {
				id: 0,
				status: 'active',
				publisher_id: 2,
				countries: '',
				platform: '',
				remote_publisher_id: null,
				remote_offer_id: null,
				limit: 0,
				priority: 0,
			};
			this.$bvModal.show('job-form-modal');
		},
		editJob(job){
			this.currentJob = Vue.util.extend({}, job);
			this.$bvModal.show('job-form-modal');
		},
		createImportJob(job){
			this.currentJob = Vue.util.extend({}, job);
			this.newImportJob = {
				publisher_id: job.publisher_id,
				country: job.country,
				platform: job.platform,
				remote_publisher_id: job.remote_publisher_id,
				remote_offer_id: job.remote_offer_id,
				limit: 0,
				priority: job.priority,
				status: 'new',
				click_date_from: moment().subtract(1, 'days').format('YYYY-MM-DD'),
				click_date_to: moment().subtract(1, 'day').format('YYYY-MM-DD'),
			};
			this.$bvModal.show('manual-import-job-form-modal');
		},
		async deleteRecord(job){
			if (!confirm('Are you sure?')){
				return;
			}
			try {
				let data = {
					id: job.id
				};
				let resp = await this.$api.post('auto-imports/delete', data);
				this.busySave = false;
				this.$notify.success('Job has been removed');
				this.loadData();
			} catch (e) {
				console.error(e);
				this.busySave = false;
			}
		},
		async createManualImport(bvModalEvt){
			bvModalEvt.preventDefault();
			if (this.busySave) {
				return;
			}
			this.busySave = true;
			let data = Vue.util.extend({}, this.newImportJob);
			try {
				let resp = await this.$api.post('jobs/saveClickImportJob', data);
				this.busySave = false;
				this.$notify.success('Job has been saved');
				this.$bvModal.hide('manual-import-job-form-modal');
				// this.loadData();
			} catch (e) {
				console.error(e);
				this.busySave = false;
			}
		},
		async saveJob(bvModalEvt){
			bvModalEvt.preventDefault();
			if (this.busySave) {
				return;
			}
			this.busySave = true;
			let data = Vue.util.extend({}, this.currentJob);
			try {
				let resp = await this.$api.post('auto-imports/save', data);
				this.busySave = false;
				this.$notify.success('Job has been saved');
				this.$bvModal.hide('job-form-modal');
				this.loadData();
			} catch (e) {
				console.error(e);
				this.busySave = false;
			}
		},

		async loadData(){
			this.busy = true;
			let params = {
				page: this.paginate.page,
				limit: this.paginate.limit,
				status: this.filters.status,
				keyword: this.filters.keyword,
				countries: this.filters.countries,
				platform: this.filters.platform,
				remote_publisher_id: this.filters.remote_publisher_id,
				remote_offer_id: this.filters.remote_offer_id,
			};
			try {
				let resp = await this.$api.get('auto-imports/getList', {params});
				this.records = resp.records;
				this.paginate.total = resp.total;
				this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
			} catch (e) {
				console.error(e);
			}
			this.busy = false;
		}
	}
}
</script>
