<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		form(@submit.prevent="submit", v-if="autoCampaignJob")
			b-tabs(content-class="mt-3", pills, card)
				b-tab(title="Main", style="padding: 0")
					.row
						.col-md-4
							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label AutoCampaign Information <span v-if="autoCampaignJob.id">({{ autoCampaignJob.id }})</span>
									.card-toolbar
								.card-body
									.row
										.col-sm-8
											select-placement(v-model="placement", :multiple="false")
										.col-sm-4
											.form-group(style="padding-top: 24px", v-if="placement")
												router-link.btn.btn-sm.btn-secondary.btn-icon(:to="{name:'placement-edit', params:{id:placement.v}}", target="_blank")
													.la.la-external-link
												| &nbsp;
												router-link.btn.btn-sm.btn-secondary.btn-icon(:to="{name:'offer-edit', params:{id:placement.offer_id}}", target="_blank")
													.la.la-external-link
											//.form-group(style="padding-top: 24px")
												button.btn.btn-secondary(type="button", @click="loadOfferSchedule()") Load Schedule
									.row
										.col-sm-6
											.form-group
												label Status
												select.form-control(v-model="autoCampaignJob.status")
													option(v-for="o in statusOptions", :value="o.v") {{ o.t }}
										.col-sm-6
											.form-group
												label Priority
												input.form-control(type="text", v-model="autoCampaignJob.priority")
						.col-md-8
							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Targeting
									.card-toolbar
								.card-body
									.row
										.col-sm-3
											.form-group
												label Countries
												textarea.form-control(rows="3", placeholder="GB, US, ...", v-model="autoCampaignJob.countries")
										.col-sm-3
											.form-group
												label Regions
												textarea.form-control(rows="3", placeholder="123,456", v-model="autoCampaignJob.regions")
										.col-sm-3
											.form-group
												label Cities
												textarea.form-control(rows="3", placeholder="123,456", v-model="autoCampaignJob.cities")
										.col-sm-3
											.form-group
												label ISPs
												textarea.form-control(rows="3", placeholder="123,456", v-model="autoCampaignJob.isps")
									.row
										.col-sm-3
											.form-group
												label Exclude Regions
												textarea.form-control(rows="3", placeholder="123,456", v-model="autoCampaignJob.exclude_regions")
										.col-sm-3
											.form-group
												label Exclude Cities
												textarea.form-control(rows="3", placeholder="123,456", v-model="autoCampaignJob.exclude_cities")
										.col-sm-3
											.form-group
												label Exclude Publishers
												textarea.form-control(rows="3", placeholder="123,456", v-model="autoCampaignJob.exclude_publishers")
									.row
										.col-sm-2
											.form-group
												label Platform
												select.form-control(v-model="autoCampaignJob.platform")
													option(v-for="o in platformOptions", :value="o.v") {{ o.t }}
										.col-sm-2
											.form-group
												label Min OS Version
												input.form-control(type="number", v-model="autoCampaignJob.min_os_version")
										.col-sm-2
											.form-group
												label Max OS Version
												input.form-control(type="number", v-model="autoCampaignJob.max_os_version")
										.col-sm-2
											.form-group
												label Days Back (8)
												input.form-control(type="number", v-model="autoCampaignJob.days_back")
										.col-sm-4
											.form-group
												b-form-checkbox(v-model="autoCampaignJob.send_impressions", switch) Send Impressions
											.form-group
												b-form-checkbox(v-model="autoCampaignJob.require_device_id", switch) Require Device ID


									h3.h3 Filters

									.form-group
										b-form-checkbox(v-model="autoCampaignJob.use_all_supply", switch) Use all supply

									div(v-if="!autoCampaignJob.use_all_supply")
										.form-group.form-group-sm
											label Remote P.Groups
											select-remote-publisher-group(:multiple="false", v-model="autoCampaignJob.remote_publisher_group_id")

										table.table.table-compact.filters-table
											thead
												tr
													th Publisher
													th R.PubID
													th Country
													th Region
													th R.OffID
													th DeviceID
													th DaysBack
													th Weight
													th
														button.btn.btn-secondary(type="button", @click="addFilter()")
															i.la.la-plus
											tbody
												tr(v-for="(f, i) in autoCampaignJob.filters")
													td
														select.form-control(v-model="f.publisher_id")
															option(v-for="o in publisherOptions", :value="o.v") {{ o.t }}
													td
														select.form-control(v-model="f.remote_publisher_id")
															option(:value="0") All
															option(v-for="o in filterRemotePublisher(f.publisher_id)", :value="o.remote_publisher_id") {{ o.t }}
													td
														input.form-control(type="text", v-model="f.country")
													td
														input.form-control(type="text", v-model="f.region_id")
														//input.form-control(type="text", v-model="f.remote_publisher_id")
													td
														input.form-control(type="text", v-model="f.remote_offer_id")
													td
														b-checkbox(v-model="f.require_device_id") Require Device ID
													td
														input.form-control(type="text", v-model="f.days_back")
													td
														input.form-control(type="text", v-model="f.weight")
													td
														button.btn.btn-sm.btn-circle(type="button", @click="autoCampaignJob.filters.splice(i,1)")
															i.la.la-times

					.card.card-custom.gutter-b
						.card-header
							.card-title
								.card-label Schedule
							.card-toolbar
						.card-body
							table.schedule-table
								thead
									tr
										th &nbsp;
										th(v-for="(h,i) in autoCampaignJob.schedule[0]")
											a(href="javascript:void(0)") {{ (i < 10 ? '0':'') + i }}
										th Total
										th &nbsp;
								tbody
									tr(v-for="(d, j) in autoCampaignJob.schedule")
										td.d
											a(@click="selectRow(j)", href="javascript:void(0)") {{ days[j] }}
										td.c(v-for="(i,h) in d")
											input(type="text", v-model="d[h].clicks")
										td.col-sum {{ getDailySum(j).toLocaleString() }}
										td.actions
											a(href="javascript:void(0);", @click="copyAll(j)")
												i.la.la-copy

				b-tab(title="Schedule", style="padding: 0")
					.card.card-custom.gutter-b
						.card-header
							.card-title
								.card-label
							.card-toolbar
						.card-body
							.form-inline
								.form-group
									label(style="display: block; width: 100%;") Date
									input.form-control(type="text", v-model="scheduleData.date")
								.form-group.no-label
									button.btn.btn-secondary(type="button", @click="fetchScheduleData()") Go
							table.table
								thead
									tr
										th #
										th Status
										th Platform
										th Country
										th TimeBase
										th Publisher
										th R.PubID
										th R.OfferID
										th # Records
										th Used
										th Actual
										th &nbsp;
								tbody
									tr(v-for="r in udpRecords")
										td {{ r.delivery_order }}
										td {{ r.status }}
										td {{ r.platform }}
										td {{ r.country }}
										td {{ r.time_base }}
										td {{ r.publisher_id }}
										td {{ r.remote_publisher_id }}
										td {{ r.remote_offer_id }}
										td {{ r.num_records }}
										td {{ r.used_records }}
										td {{ r.actual_num_records }}
										td
											button.btn.btn-sm.btn-secondary(type="button", @click="checkUDPRecord(r)")
												i.la.la-refresh
								tbody
									tr
										td(colspan="8") &nbsp;
										td {{ totalUDPRecords.toLocaleString() }}
										td {{ totalUsedUDPRecords }}
										td &nbsp;
										td &nbsp;


</template>
<style lang="scss">
.schedule-table {
	//width: 1200px;
	th {
		text-align: center;
	}
	td {
		text-align: center;
	}
	td.d {
		width: 40px;
		text-align: center;
	}
	td.c {
		width: 60px;
	}
	input[type="text"] {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
		text-align: center;
		border: none;
		border-radius: 2px;
		background: #f6f6f6;
	}
}
.filter-block {
	background: #f6f6f6;
	padding: 30px;
	border-radius: 10px;
	margin-bottom: 10px;
	position: relative;
	.btn-remove {
		position: absolute;
		right: 10px;
		top: 10px;
		font-size: 20px;
	}
	.form-group {
		margin-bottom: 5px;
	}
}
</style>
<script>
import Vue from 'vue';
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import moment from 'moment';
import SelectRemotePublisherGroup from "@/views/components/SelectRemotePublisherGroup";

async function loadRoute(vm, to, from, next){

	let autoCampaign = {};
	if (to.name === 'auto-campaign-edit') {
		let entityId = +to.params.id;
		try {
			autoCampaign = await Vue.$dwData.autoCampaignJob.get(entityId, true);
		} catch (e) {
			Vue.$notify.error('Failed to load auto-campaign job');
			return next(false);
		}
	} else {
		let copyEntityId = +to.query.copy_id;
		if (copyEntityId){
			try {
				autoCampaign = await Vue.$dwData.autoCampaignJob.get(copyEntityId, true);
				autoCampaign.id = 0;
			} catch (e) {
				Vue.$notify.error('Failed to load auto-campaign job');
				return next(false);
			}
		} else {
			autoCampaign = Vue.$dwData.autoCampaignJob.newInstance();
		}
	}

	let publisherOptions = await Vue.$dwData.publisher.getOptions();
	let remotePublisherOptions = await Vue.$dwData.remotePublisher.getOptions(true);

	return vm => {
		vm.autoCampaignJob = autoCampaign;
		vm.publisherOptions = publisherOptions;
		vm.remotePublisherOptions = remotePublisherOptions;
		vm.busy = false;
		if (autoCampaign.placement_id) {
			vm.placement = {
				v: autoCampaign.placement_id,
				t: `[${autoCampaign.placement_id}] ${autoCampaign.placement.offer.name}`,
				offer_id: autoCampaign.placement.offer.id,
			}
		}
		return vm;
	};
}


export default {
	name: 'AutoCampaignForm',
	components: {SelectRemotePublisherGroup},
	async beforeRouteEnter(to, from, next){
		let bind = await loadRoute({}, to, from, next);
		next(bind);
	},
	async beforeRouteUpdate(to, from, next){
		await loadRoute(this, to, from, next);
		next();
	},
	computed: {
		totalUDPRecords(){
			let sum = 0;
			this.udpRecords.forEach(r => {
				sum += r.num_records;
			});
			return sum;
		},
		totalUsedUDPRecords(){
			return 0;
		}
	},
	data(){
		return {
			busy: true,
			placement: null,
			autoCampaignJob: null,
			days: ['S','M','T','W','T','F','S'],
			platformOptions: Vue.$dwData.general.platformOptions,
			publisherOptions: [],
			remotePublisherOptions: [],
			statusOptions: [
				{v: 'draft', t: 'Draft'},
				{v: 'pending', t: 'Pending'},
				{v: 'live', t: 'Live'},
				{v: 'paused', t: 'Paused'},
				{v: 'stopped', t: 'Stopped'},
			],
			scheduleBusy: false,
			scheduleData: {
				date: moment().format('YYYY-MM-DD')
			},
			udpRecords: []
		};
	},
	methods: {
		filterRemotePublisher(publisherId){
			if (!publisherId) return this.remotePublisherOptions;
			return this.remotePublisherOptions.filter(p => p.publisher_id === publisherId);
		},
		async checkUDPRecord(r){
			if (this.scheduleBusy){
				return;
			}
			this.scheduleBusy = true;
			try {
				let data = {
					id: r.id,
				};
				let resp = await this.$api.get('auto-campaigns/checkUDPRecord', {params: data});
				//r.actual_num_records = resp.record.actual_num_records;
				Vue.set(r, 'actual_num_records', resp.record.actual_num_records);
			} catch (e) {
				console.error(e);
			}
			this.scheduleBusy = false;
		},
		async fetchScheduleData(){
			if (this.scheduleBusy){
				return;
			}
			this.scheduleBusy = true;
			try {
				let data = {
					id: this.autoCampaignJob.id,
					date: this.scheduleData.date
				};
				let resp = await this.$api.get('auto-campaigns/getSchedule', {params: data});
				this.udpRecords = resp.records;
			} catch (e) {
				console.error(e);
			}
			this.scheduleBusy = false;
		},
		addFilter(){
			this.autoCampaignJob.filters.push({
				country: '',
				remote_publisher_id: null,
				remote_offer_id: null,
				require_device_id: false,
				days_back: 0,
				weight: 0,
			});
		},
		removeFilter(index){
			this.autoCampaignJob.filters.splice(index, 1);
		},
		copyAll(index){
			for (let i = 0; i < 7; i++) {
				if (i !== index) {
					for (let j = 0; j < 24; j++) {
						this.autoCampaignJob.schedule[i][j].clicks = this.autoCampaignJob.schedule[index][j].clicks;
					}
				}
			}
		},
		getDailySum(d){
			let sum = 0;
			if (!this.autoCampaignJob || !this.autoCampaignJob.schedule){
				return 0;
			}
			let sched = this.autoCampaignJob.schedule;
			if (sched[d]) {
				for (let i = 0; i < 24; i++) {
					sum += +sched[d][i].clicks;
				}
			}
			return sum;
		},
		async loadOfferSchedule(){
			try {
				let placementId = this.placement.v;
				let resp = await this.$api.get('/auto-campaigns/getOfferSchedule/'+placementId+'/'+this.autoCampaignJob.id);
				console.log(resp);
			} catch (e){
				console.error(e);
			}
		},
		async save(closeAfterSave = false){
			if (this.busy){
				return;
			}
			this.busy = true;
			let data = Vue.util.extend({}, this.autoCampaignJob);
			data.placement_id = this.placement.v;
			try {
				let resp = await this.$api.post('auto-campaigns/save', data);
				this.busy = false;
				this.$notify.success('AutoCampaign Job has been saved');
				if (closeAfterSave) {
					this.$router.push({name: 'auto-campaign-list'});
				} else {
					this.autoCampaignJob = resp.entity;
				}
			} catch (e) {
				console.error(e);
				this.busy = false;
			}
		}
	},
	mounted(){
		let vm = this;
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Media'},
			{title: 'Automation'},
			{title: 'Auto-Campaigns'},
			{title: this.autoCampaignJob && this.autoCampaignJob.id ? 'Edit AutoCampaign' : 'Add AutoCampaign'}
		]);
		this.$store.dispatch('setBackButton', {
			route: {name: 'auto-campaign-list'}
		});
		this.$store.dispatch('setPageButtons', [
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save',
				action(){
					vm.save();
				}
			},
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save & Close',
				action(){
					vm.save(true);
				}
			}
		]);
	},

}

</script>
