<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		form(@submit.prevent="submit", v-if="mmpAccount")
			.row
				.col-md-6
					.card.card-custom.gutter-b
						.card-header
							.card-title
								.card-label MMP Account Information <span v-if="mmpAccount.id">({{ mmpAccount.id }})</span>
							.card-toolbar
						.card-body
							.row
								.col-sm-6
									select-network(v-model="mmpAccount.network_id")
								.col-sm-6
									.form-group
										label MMP
										select.form-control(v-model="mmpAccount.mmp")
											option(value="appsflyer") Appsflyer
											option(value="kochava") Kochava
											option(value="singular") Singular
							.row
								.col-sm-6
									.form-group
										label Type
										select.form-control(v-model="mmpAccount.type")
											option(value="adnetwork") Ad Network
											option(value="agency") Agency
								.col-sm-6
									.form-group
										label Identifier
										input.form-control(type="text", v-model="mmpAccount.identifier")
							.form-group
								label Comments
								textarea.form-control(placeholder="Comments", v-model="mmpAccount.comments", rows="5")

</template>
<script>
import Vue from 'vue';
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

async function loadRoute(vm, to, from, next){
	let mmpAccount = null;
	if (to.name === 'mmp-account-edit') {
		let entityId = +to.params.id;
		try {
			mmpAccount = await Vue.$dwData.mmpAccount.get(entityId, true);
		} catch (e) {
			Vue.$notify.error('Failed to load MMP Account');
			return next(false);
		}
	} else {
		mmpAccount = Vue.$dwData.mmpAccount.newInstance();
	}

	// let networkOptions = await Vue.$dwData.network.getOptions(true);

	return vm => {
		// vm.networkOptions = networkOptions;
		vm.mmpAccount = mmpAccount;
		vm.busy = false;
		return vm;
	};
}

export default {
	async beforeRouteEnter(to, from, next){
		let bind = await loadRoute({}, to, from, next);
		next(bind);
	},
	async beforeRouteUpdate(to, from, next){
		await loadRoute(this, to, from, next);
		next();
	},
	name: 'MMPAccountForm',
	data(){
		return {
			busy: true,
			mmpAccount: null,
			// networkOptions: []
		}
	},
	methods: {
		async submit(closeAfterSave = false){
			if (this.busy){
				return;
			}
			this.busy = true;
			let data = Vue.util.extend({}, this.mmpAccount);
			try {
				let resp = await this.$api.post('mmp-accounts/save', data);
				this.busy = false;
				this.$notify.success('MMP Account has been saved');
				if (closeAfterSave) {
					this.$router.push({name: 'mmp-account-list'});
				} else {
					this.mmpAccount = resp.entity;
				}
			} catch (e) {
				console.error(e);
				this.busy = false;
			}
		}
	},
	mounted(){
		let vm = this;
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Demand'},
			{title: 'MMP Accounts', name: 'mmp-account-list'},
			{title: this.mmpAccount && this.mmpAccount.id ? 'Edit MMP Account' : 'Add MMP Account'}
		]);
		this.$store.dispatch('setBackButton', {
			route: {name: 'mmp-account-list'}
		});
		this.$store.dispatch('setPageButtons', [
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save',
				action(){
					vm.submit();
				}
			},
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save & Close',
				action(){
					vm.submit(true);
				}
			}
		]);
	},
}

</script>
