<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		form(@submit.prevent="submit", v-if="publisherFeed")

			b-tabs(content-class="mt-3", pills, card)
				b-tab(title="Main", style="padding: 0")
					.row
						.col-md-6
							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Publisher Feed Information <span v-if="publisherFeed.id">({{ publisherFeed.id }})</span>
									.card-toolbar
								.card-body
									.row
										.col-sm-12
											.form-group
												label UUID
												span.form-control {{ publisherFeed.uuid }}
									.row
										.col-sm-12
											.form-group
												label Name
												input.form-control(type="text", v-model="publisherFeed.name")
									.row
										.col-sm-4
											.form-group
												label Network
												select.form-control(v-model="publisherFeed.network_id")
													option(:value="null") - All -
													option(v-for="n in networkOptions", :value="n.v") {{ n.t }}
										.col-sm-4
											.form-group
												label Publisher
												select.form-control(v-model="publisherFeed.publisher_id")
													option(:value="null") - Select -
													option(v-for="p in filteredPublisherOptions", :value="p.v") {{ p.t }}
									.row
										.col-sm-4
											.form-group
												label Status
												select.form-control(v-model="publisherFeed.status")
													option(v-for="o in publisherFeedStatusOptions", :value="o.v") {{ o.t }}
										.col-sm-4
											.form-group
												label Type
												select.form-control(v-model="publisherFeed.type")
													option(v-for="o in publisherFeedTypeOptions", :value="o.v") {{ o.t }}
									.row
										.col-sm-4
											.form-group
												label Payout Model
												select.form-control(v-model="publisherFeed.payout_model")
													option(value="CPC") CPC
													option(value="CPM") CPM
										.col-sm-4
											.form-group
												label Payout %
												input.form-control(type="text", v-model="publisherFeed.payout_percent")
										.col-sm-4
											.form-group
												label Timeout (ms)
												input.form-control(type="text", v-model="publisherFeed.timeout")

							p.clearfix &nbsp;

							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Comments
									.card-toolbar
								.card-body
									.row
										.col-sm-12
											.form-group
												label Description
												textarea.form-control(rows="3", v-model="publisherFeed.description")
									.row
										.col-sm-12
											.form-group
												label Comments
												textarea.form-control(rows="3", v-model="publisherFeed.comments")
						.col-md-6

							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Misc
									.card-toolbar
								.card-body
									.form-group
										label Countries
										textarea.form-control(v-model="publisherFeed.countries", rows="5")


</template>
<script>
import Vue from 'vue';
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

async function loadRoute(vm, to, from, next){

	let publisherFeed = {};
	if (to.name === 'publisher-feed-edit') {
		let entityId = +to.params.id;
		try {
			publisherFeed = await Vue.$dwData.publisherFeed.get(entityId, true);
		} catch (e) {
			Vue.$notify.error('Failed to load publisher feed');
			return next(false);
		}
	} else {
		publisherFeed = Vue.$dwData.publisherFeed.newInstance();
	}
	let networkOptions = await Vue.$dwData.network.getOptions(true);
	// Vue.ovData.advertiser._booleans.forEach(f => {
	// 	advertiser[f] = !!advertiser[f];
	// });
	let publisherOptions = await Vue.$dwData.publisher.getOptions(true);
	return vm => {
		// vm.publisherOptions = publisherOptions;
		vm.networkOptions = networkOptions;
		vm.publisherOptions = publisherOptions;
		vm.publisherFeed = publisherFeed;
		vm.busy = false;
		return vm;
	};
}

export default {
	async beforeRouteEnter(to, from, next){
		let bind = await loadRoute({}, to, from, next);
		next(bind);
	},
	async beforeRouteUpdate(to, from, next){
		await loadRoute(this, to, from, next);
		next();
	},
	name: 'PublisherFeedForm',
	data(){
		return {
			busy: true,
			publisherFeed: null,
			integrationTypeOptions: [],
			networkOptions: [],
			publisherOptions: [],
			publisherFeedStatusOptions: Vue.$dwData.general.publisherFeedStatusOptions,
			publisherFeedTypeOptions: Vue.$dwData.general.publisherFeedTypeOptions
		};
	},
	computed: {
		filteredPublisherOptions(){
			return this.publisherOptions.filter(p => {
				return p.network_id === this.publisherFeed.network_id
			});
		}
	},
	methods: {
		// addTrackingLinkParam(){
		// 	this.publisherFeed.params.push({
		// 		key: '',
		// 		val: ''
		// 	});
		// },

		async submit(closeAfterSave = false){
			if (this.busy){
				return;
			}
			this.busy = true;
			let data = Vue.util.extend({}, this.publisherFeed);
			try {
				let resp = await this.$api.post('publisher-feeds/save', data);
				this.busy = false;
				this.$notify.success('Publisher feed has been saved');
				if (closeAfterSave) {
					this.$router.push({name: 'publisher-feed-list'});
				} else {
					this.publisherFeed = resp.entity;
				}
			} catch (e) {
				console.error(e);
				this.busy = false;
			}
		}
	},
	mounted(){
		let vm = this;
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Media'},
			{title: 'RTB'},
			{title: 'Publisher Feeds', name: 'publisher-feed-list'},
			{title: this.publisherFeed && this.publisherFeed.id ? 'Edit Publisher Feed' : 'Add Publisher Feed'}
		]);
		this.$store.dispatch('setBackButton', {
			route: {name: 'publisher-feed-list'}
		});
		this.$store.dispatch('setPageButtons', [
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save',
				action(){
					vm.submit();
				}
			},
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save & Close',
				action(){
					vm.submit(true);
				}
			}
		]);
	},
}

</script>
