<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		form(@submit.prevent="submit", v-if="creative")
			b-tabs(content-class="mt-3", pills, card)
				b-tab(title="Main", style="padding: 0")
					.row
						.col-md-6
							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Creative Information <span v-if="creative.id">({{ creative.id }})</span>
									.card-toolbar
								.card-body
									.row
										.col-sm-6
											.form-group
												label Name
												input.form-control(type="text", v-model="creative.name")
										.col-sm-6
											select-network(v-model="creative.network_id")
							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Creative Information
									.card-toolbar
								.card-body
									.form-group
										label Type
										select.form-control(v-model="creative.type")
											option(v-for="o in creativeTypeOptions", :value="o.v") {{ o.t }}
									.row
										.col-sm-12
											.form-group
												label URL
												input.form-control(type="text", v-model="creative.url")
									.row
										.col-sm-6
											.form-group
												label Width
												input.form-control(type="number", v-model="creative.width")
										.col-sm-6
											.form-group
												label Height
												input.form-control(type="number", v-model="creative.height")
									.form-group(v-if="creative.type==='html'")
										label HTML Code
										textarea.form-control(rows="5", v-model="creative.html")


</template>
<script>
import Vue from 'vue';
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

async function loadRoute(vm, to, from, next){

	let creative = {};
	if (to.name === 'creative-edit') {
		let entityId = +to.params.id;
		try {
			creative = await Vue.$dwData.creative.get(entityId, true);
		} catch (e) {
			Vue.$notify.error('Failed to load creative');
			return next(false);
		}
	} else {
		creative = Vue.$dwData.creative.newInstance();
	}

	return vm => {
		vm.creative = creative;
		vm.busy = false;
		return vm;
	};
}

export default {
	async beforeRouteEnter(to, from, next){
		let bind = await loadRoute({}, to, from, next);
		next(bind);
	},
	async beforeRouteUpdate(to, from, next){
		await loadRoute(this, to, from, next);
		next();
	},
	name: 'CreativeForm',
	data(){
		return {
			busy: true,
			creative: null,
			creativeTypeOptions: [
				{v: 'icon', t: 'icon'},
				{v: 'image', t: 'image'},
				{v: 'video', t: 'video'},
				{v: 'html', t: 'html'},
			]
		};
	},
	methods: {

		async submit(closeAfterSave = false){
			if (this.busy){
				return;
			}
			this.busy = true;
			let data = Vue.util.extend({}, this.creative);
			try {
				let resp = await this.$api.post('creatives/save', data);
				this.busy = false;
				this.$notify.success('Creative has been saved');
				if (closeAfterSave) {
					this.$router.push({name: 'creative-list'});
				} else {
					this.creative = resp.entity;
				}
			} catch (e) {
				console.error(e);
				this.busy = false;
			}
		}
	},
	mounted(){
		let vm = this;
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Media'},
			{title: 'Creatives', name: 'creative-list'},
			{title: this.creative && this.creative.id ? 'Edit Creative' : 'Add Creative'}
		]);
		this.$store.dispatch('setBackButton', {
			route: {name: 'creative-list'}
		});
		this.$store.dispatch('setPageButtons', [
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save',
				action(){
					vm.submit();
				}
			},
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save & Close',
				action(){
					vm.submit(true);
				}
			}
		]);
	},
}

</script>
