<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		.card.card-custom.gutter-b
			.card-header
				.card-title
					.card-label Campaigns
				.card-toolbar
			.card-body
				form.form.form-inline.form-filters(@submit.prevent="loadData()", :class="{expanded:filtersExpanded}")
					a.btn.btn-primary.toggle-filters.d-md-none(v-b-toggle.form-filters)
						span Filters
						i.fa.fw.fa-chevron-up.transition-all(:class="{'rotate-180':filtersExpanded}")
					b-collapse#form-filters.dont-collapse-sm(style="width: 100%;", v-model="filtersExpanded")
						.row.filters-form-row
							.form-row-main.col-auto.mr-auto(style="flex:1;")
								.form-group
									label Search
									input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.keyword")
								.form-group
									label Network
									select.form-control(v-model="filters.network_id", style="width: 120px;")
										option(:value="null") - All -
										option(v-for="n in networkOptions", :value="n.v") {{ n.t }}
								.form-group
									label OS
									select.form-control(v-model="filters.platform")
										option(value="") - All -
										option(value="ios") iOS
										option(value="android") Android
								.form-group
									label Countries
									input.form-control.input-search(type="text", placeholder="", v-model="filters.countries")
								.form-group
									label Status
									select.form-control(v-model="filters.status")
										option(v-for="o in campaignStatusOptions", :value="o.v") {{ o.t }}
								.form-group
									label E.Status
									select.form-control(v-model="filters.effective_status")
										option(v-for="o in campaignEffectiveStatusOptions", :value="o.v") {{ o.t }}
								.form-group.no-label
									button.btn.btn-primary(type="submit") Go
							.form-row-end.col-auto
								.form-group.no-label
									router-link.btn.btn-success(:to="{name:'campaign-add'}")
										i.flaticon2-plus
										| Add

				p.clearfix &nbsp;

		.card.card-custom.gutter-b
			.card-body
				.table-wrapper
					.tabs.table-tabs
						ul.nav.nav-tabs
							li.nav-item
								a.nav-link(:class="{active:selectedTab==='search'}", @click="selectedTab='search'") Search ({{ paginate.total }})
							li.nav-item
								a.nav-link(:class="{active:selectedTab==='bucket'}", @click="selectedTab='bucket'") Selected ({{bucket.length}})
					table.table.table-bordered
						thead
							tr
								th
									span(v-if="selectedTab==='search'")
										a(href="javascript:void(0);", @click="selectAll()")
											i.la.la-check-square-o
										=' | '
									span
										a(href="javascript:void(0);", @click="clearBucket()")
											i.la.la-square-o
								th ID
								th Feed
								th Name
								th Status
								th Tag
								th Bid
								th ImpCap
								th Budget
								th Created At
								th(style="padding-top: 0; padding-bottom: 0; vertical-align: middle; ")
									//b-dropdown(text="Bulk Actions", size="sm", variant="secondary")
										b-dropdown-item(href="#", @click="bulkConnect()") Connect
										b-dropdown-item(href="#", @click="bulkPause()") Pause
										b-dropdown-item(href="#", @click="bulkActivate()") Activate
										b-dropdown-item(href="#", @click="bulkExport()") Export
										b-dropdown-item(href="#", @click="bulkStatistics()") View statistics
										b-dropdown-item(href="#", @click="bulkModal('daily_click_cap', 'Set Click Cap')") Set Click Cap
						tbody
							tr(v-if="records.length === 0")
								td(colspan="18") No matching records were found
							tr(v-for="r in (selectedTab==='bucket' ? bucket:records)", v-bind:key="r.id", :class="getRowClass(r)")
								td.col-select(:class="{'bucket-row':bucketHash[r.id]}")
									a(href="javascript:void(0)", @click="toggleBucket(r)")
										i.la(:class="{'la-check-square':bucketHash[r.id], 'la-stop':!bucketHash[r.id]}")
								td {{ r.id }}
								td
									entity(v-if="r.publisher_feed", type="publisher_feed", :id="r.publisher_feed_id", :name="r.publisher_feed.name")
								td {{ r.name }}
								td.col-status
									b-dd(:text="r.status", :class="'status-'+r.status")
										b-dropdown-item(v-for="o in campaignStatusOptions", v-bind:key="o.v", :class="'option-'+o.v", @click="updateStatus(r, o.v)") {{ o.t }}
									div {{ r.effective_status }}
								td
									entity(type="tag", :id="r.tag_id", :name="r.tag.name")
								td {{ r.bid.toFixed(2) }}
								td {{ r.current_impression_count }} / {{ r.daily_impression_cap }}
								td {{ r.current_spend }} / {{ r.daily_budget }}
								td {{ r.created_at }}
								td.actions
									router-link.btn.btn-action.btn-secondary(:to="{name:'campaign-edit', params: {id:r.id}}")
										i.la.la-pen

</template>
<style lang="scss">
.col-status {
	text-align: center;
	button.btn {
		border: none;
		text-transform: uppercase;
		padding: 0 15px;
		height: 24px;
		border-radius: 4px;
	}
	.status-live {
		button.btn {
			background: #72df94;
		}
	}
}
</style>
<script>
import Vue from 'vue';
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
	name: 'OfferList',
	async beforeRouteEnter(to, from, next) {
		let networkOptions = await Vue.$dwData.network.getOptions(true);

		next(vm => {
			vm.networkOptions = networkOptions;
			vm.loadData();
			return vm;
		});
	},
	data() {
		return {
			busy: false,
			selectedTab: 'search',
			paginate: {
				numPages: 0,
				total: 0,
				page: 1,
				limit: 50,
				onPageChange: () => {
					this.loadData();
				}
			},
			networkOptions: [],
			filters: {
				keyword: '',
				countries: '',
				platform: '',
				status: null,
				effective_status: null,
				network_id: 1,
			},
			bucket: [],
			bucketHash: {},
			records: [],
			filtersExpanded: false,
			campaignEffectiveStatusOptions: [
				{v: null, t: '- All -'},
				{v: 'live', t: 'Live'},
				{v: 'paused', t: 'Paused'},
			],
			campaignStatusOptions: [{
				v: null,
				t: '- All -',
			}, ...Vue.$dwData.general.campaignStatusOptions.filter(o => o.v !== 'draft')],
		};
	},
	mounted(){
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Media'},
			{title: 'Campaigns'}
		]);
	},
	methods: {
		getRowClass(r){
			let o = {
				['type-'+r.type]: true,
				'row-selected': this.bucketHash[r.id]
			};
			return o;
		},
		toggleBucket(r){
			if (this.bucketHash[r.id]) {
				Vue.set(this.bucketHash, r.id, false);
				for (let i = 0; i < this.bucket.length; i++) {
					if (this.bucket[i].id === r.id) {
						this.bucket.splice(i, 1);
						return;
					}
				}
			} else {
				Vue.set(this.bucketHash, r.id, true);
				this.bucket.push(r);
			}
		},
		clearBucket(){
			this.bucket = [];
			this.bucketHash = {};
		},
		selectAll(){
			this.records.forEach(r => {
				if (!this.bucketHash[r.id]) {
					Vue.set(this.bucketHash, r.id, true);
					this.bucket.push(r);
				}
			});
		},
		updateStatus(r, v){
			r._status = v;
			this.updateField(r, 'status');
		},
		async deleteRecord(job){
			if (!confirm('Are you sure?')){
				return;
			}
			try {
				let data = {
					id: job.id
				};
				await this.$api.post('campaigns/delete', data);
				this.busySave = false;
				this.$notify.success('Campaign has been removed');
				this.loadData();
			} catch (e) {
				console.error(e);
				this.busySave = false;
			}
		},
		async loadData(){
			if (this.busy){
				return;
			}
			this.busy = true;
			let params = {
				page: this.paginate.page,
				limit: this.paginate.limit,
				keyword: this.filters.keyword,
				status: this.filters.status,
				effective_status: this.filters.effective_status,
				platform: this.filters.platform,
				countries: this.filters.countries,
				network_id: this.filters.network_id || 0
			};
			try {
				let resp = await this.$api.get('/campaigns/getList', {params});
				this.records = resp.records;
				this.paginate.total = resp.total;
				this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
			} catch (e) {
				console.error(e);
			}
			this.busy = false;
		}
	}
}

</script>
