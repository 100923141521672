<template lang="pug">
	.card.card-custom.gutter-b
		.card-header
			.card-title
				.card-label Publishers
			.card-toolbar
		.card-body
			form.form.form-inline.form-filters(@submit.prevent="loadData()", :class="{expanded:filtersExpanded}")
				a.btn.btn-primary.toggle-filters.d-md-none(v-b-toggle.form-filters)
					span Filters
					i.fa.fw.fa-chevron-up.transition-all(:class="{'rotate-180':filtersExpanded}")
				b-collapse#form-filters.dont-collapse-sm(style="width: 100%;", v-model="filtersExpanded")
					.row.filters-form-row
						.form-row-main.col-auto.mr-auto(style="flex:1;")
							.form-group
								label Search
								input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.keyword", v-debounce:300ms="loadData")
							.form-group
								label Network
								select.form-control(v-model="filters.network_id", style="width: 120px;")
									option(:value="null") - All -
									option(v-for="n in networkOptions", :value="n.v") {{ n.t }}
							.form-group
								label Status
								select.form-control(v-model="filters.status", @change="loadData()")
									option(v-for="o in statusOptions", :value="o.v") {{ o.t }}
							.form-group.no-label
								button.btn.btn-primary(type="button", @click="loadData()") Go
						.form-row-end.col-auto
							.form-group.no-label
								router-link.btn.btn-success(:to="{name:'publisher-add'}")
									i.flaticon2-plus
									| Add

			p.clearfix &nbsp;

			table.table.table-bordered
				thead
					tr
						th ID
						th Network
						th Active
						th Name
						th(style="width: 200px") API Key
						th(style="width: 220px") Panel
						th Actions
				tbody
					tr(v-if="records.length===0")
						td(colspan="7") No matching records were found
					tr(v-for="r in records", :key="r.id")
						td {{ r.id }}
						td
							entity(:id="r.network.id", :name="r.network.name", type="network")
						td {{ r.active }}
						td {{ r.name }}
						td
							.input-group
								input.form-control.form-control-sm(type="text", :value="r.api_key", readonly="readonly")
								button.btn.btn-sm.btn-secondary(type="button", @click="refreshAPIKey(r)")
									i.la.la-refresh
						td
							.input-group(v-if="r.panel_url")
								input.form-control.form-control-sm(type="text", :value="r.panel_username", readonly="readonly")
								input.form-control.form-control-sm(type="text", :value="r.panel_password", readonly="readonly")
								a.btn.btn-sm.btn-secondary(:href="r.panel_url", target="_blank")
									i.la.la-external-link
							//span(v-if="r.panel_url") {{ r.panel_username }} / {{ r.panel_password }}
							//	| &nbsp;
							//	a(:href="r.panel_url")
							//		i.la.la-external-link
						td.actions
							router-link.btn.btn-action.btn-secondary(:to="{name:'publisher-edit', params: {id:r.id}}")
								i.la.la-pen
							button.btn.btn-action.btn-danger(type="button", @click="deleteRecord(r)")
								i.la.la-trash

			paginate(:paginator="paginate", @update-page-size="loadData")

</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
export default {
	name: 'PublisherList',
	async beforeRouteEnter(to, from, next){
		let networkOptions = await Vue.$dwData.network.getOptions(true);
		next(vm => {
			vm.networkOptions = networkOptions;
			vm.loadData();
			return vm;
		});
	},
	data(){
		return {
			busy: false,
			// PERMS: null,
			networkOptions: [],
			paginate: {
				numPages: 0,
				total: 0,
				page: 1,
				limit: 50,
				onPageChange: () => {
					this.loadData();
				}
			},
			filters: {
				keyword: '',
				status: 1,
				network_id: 1
			},
			statusOptions: [
				{v: undefined, t: 'All'},
				{v: 0, t: 'Inactive'},
				{v: 1, t: 'Active Only'},
			],
			records: [],
			filtersExpanded: false,
		};
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ title: 'Media' },
			{ title: 'Publishers' }
		]);
	},
	methods: {
		async refreshAPIKey(r){
			if (confirm('Are you sure?')) {
				try {
					let resp = await this.$api.post('/publishers/refreshAPIKey', {id: r.id})
					r.api_key = resp.api_key;
				} catch (e) {
					console.error(e);
				}
			}
		},
		async deleteRecord(job){
			if (!confirm('Are you sure?')){
				return;
			}
			try {
				let data = {
					id: job.id
				};
				await this.$api.post('publishers/delete', data);
				this.busySave = false;
				this.$notify.success('Publisher has been removed');
				this.loadData();
			} catch (e) {
				console.error(e);
				this.busySave = false;
			}
		},
		async loadData(){
			this.busy = true;
			let params = {
				page: this.paginate.page,
				limit: this.paginate.limit,
				keyword: this.filters.keyword,
				network_id: this.filters.network_id || 0
			};
			try {
				let resp = await this.$api.get('/publishers/getList', {params});
				this.records = resp.records;
				this.paginate.total = resp.total;
				this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
			} catch (e){
				console.error(e);
			}
			this.busy = false;
		}
	},
	created(){
		this.loadData();
	}

}
</script>
