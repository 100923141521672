<template lang="pug">
	.form-group.form-group-sm.fg-publisher
		label Network
		v-select(:options="networkOptions", :value="value", @input="onChange", :reduce="o => o.v", label="t")

</template>
<script>
import Vue from 'vue';

export default {
	name: 'SelectNetwork',
	props: {
		value: Number,
	},
	data(){
		return {
			networkOptions: [],
		};
	},
	methods: {
		onChange(value) {
			this.$emit('input', value);
		}
	},
	async mounted(){
		this.networkOptions = await Vue.$dwData.network.getOptions(true);
	}
}
</script>
