<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		.card.card-custom.gutter-b
			.card-header
				.card-title
					.card-label Import Clicks
				.card-toolbar
			.card-body
				form.form.form-inline.form-filters(@submit.prevent="loadData()")
					div(style="width: 100%; display: block")
						.row.filters-form-row
							.form-row-main.col-auto.mr-auto(style="flex:1;")
								.form-group
									label Countries
									input.form-control.input-search(type="text", placeholder="", v-model="filters.countries")
								.form-group
									label OS
									select.form-control(v-model="filters.platform")
										option(value="") - All -
										option(value="ios") iOS
										option(value="android") Android
								.form-group
									label R.PubID
									input.form-control.input-search(type="text", placeholder="", v-model="filters.remote_publisher_id")
								.form-group
									label R.OfferID
									input.form-control.input-search(type="text", placeholder="", v-model="filters.remote_offer_id")
								.form-group
									label Date
									input.form-control.input-search(type="text", placeholder="", v-model="filters.import_date")
								.form-group.no-label
									button.btn.btn-primary(type="submit", @click="loadData()") Go
							.form-row-end.col-auto
								.form-group.no-label
									button.btn.btn-success(type="button", @click="addJob()")
										i.flaticon2-plus
										| Add

				p.clearfix &nbsp;

				table.table.table-bordered.table-compact
					thead
						tr
							th ID
							th Status
							th Publisher
							th OS
							th Geo
							th R.Pub
							th R.Off
							th Clicks Date
							th Priority
							th Count
							th Created At
							th Actions
					tbody
						tr(v-if="records.length===0")
							td(colspan="12") No matching records were found
						tr(v-for="r in records", :key="r.id", :class="'row-'+r.status")
							td {{ r.id }}
							td {{ r.status }}
							td
								entity(:id="r.publisher.id", :name="r.publisher.name", type="publisher")
							td {{ r.platform }}
							td {{ r.country }}
							td
								span(v-if="r.remote_publisher_id") [{{ r.remote_publisher_id }}]
								=' '
								span(v-if="r.remote_publisher") {{ r.remote_publisher.name }}
							td {{ r.remote_offer_id }}
							td {{ r.click_date }}
							td {{ r.priority }}
							td {{ r.current_count }}
							td {{ r.created_at }}
							td.actions
								button.btn.btn-action.btn-secondary(type="button", @click="editJob(r)")
									i.la.la-pen
								button.btn.btn-action.btn-secondary(type="button", @click="copyJob(r)")
									i.la.la-copy
								button.btn.btn-action.btn-outline-danger(type="button", @click="deleteRecord(r)")
									i.la.la-trash

				paginate(:paginator="paginate", @update-page-size="loadData")

				b-modal#job-form-modal(title="Job Info", @ok="saveJob")
					loading(:active.sync="busySave", :is-full-page="false")
					form.form.form-horizontal(v-if="currentJob")
						.row
							.col-sm-6
								label Publisher
								select.form-control(v-model="currentJob.publisher_id")
									option(v-for="o in publisherOptions", :key="o.v", :value="o.v") {{ o.t }}
						.row(v-if="!currentJob.id")
							.col-sm-6
								.form-group
									label From date
									input.form-control(type="text", v-model="currentJob.click_date_from", placeholder="2021-01-14")
							.col-sm-6
								.form-group
									label From date
									input.form-control(type="text", v-model="currentJob.click_date_to", placeholder="2021-01-14")
						.row(v-if="currentJob.id")
							.col-sm-6
								.form-group
									label Clicks Date
									input.form-control(type="text", v-model="currentJob.click_date", placeholder="2021-01-14")
						.row
							.col-sm-6
								.form-group
									label Country
									input.form-control(type="text", v-model="currentJob.country", placeholder="US")
							.col-sm-6
								.form-group
									label Platform
									select.form-control(v-model="currentJob.platform")
										option(value="") - All -
										option(value="ios") iOS
										option(value="android") Android

						.row
							.col-sm-6
								.form-group
									label Remote Publisher ID
									input.form-control(type="text", v-model="currentJob.remote_publisher_id")
							.col-sm-6
								.form-group
									label Remote Offer ID
									input.form-control(type="text", v-model="currentJob.remote_offer_id")

						.row
							.col-sm-6
								.form-group
									label Limit
									input.form-control(type="text", v-model="currentJob.limit")
							.col-sm-6
								.form-group
									label Status
									select.form-control(v-model="currentJob.status")
										option(value="new") New
										option(value="skip") Skip
						//.form-group
							button.btn.btn-primary(type="submit") Save


</template>
<style lang="scss">
.row-new {
	background: #bbeaff;
}
.row-process {
	background: #ddffdd;
}
.row-canceled {
	background: #ffdddd;
}
</style>
<script>
import Vue from 'vue';
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import moment from "moment";

export default {
	name: 'ClickImportJobList',
	async beforeRouteEnter(to, from, next){
		let publisherOptions = await Vue.$dwData.publisher.getOptions();
		next(vm => {
			vm.publisherOptions = publisherOptions;
			vm.loadData();
			return vm;
		});
	},
	data(){
		return {
			busy: false,
			busySave: false,
			paginate: {
				numPages: 0,
				total: 0,
				page: 1,
				limit: 50,
				onPageChange: () => {
					this.loadData();
				},
			},
			publisherOptions: [],
			records: [],
			currentJob: null,
			filters: {
				countries: '',
				platform: '',
				remote_publisher_id: '',
				remote_offer_id: '',
				import_date: '',
			}
		};
	},
	mounted(){
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Media'},
			{title: 'Jobs'},
			{title: 'Import Clicks'}
		]);
	},
	methods: {
		addJob(){
			this.currentJob = {
				id: 0,
				publisher_id: null,
				country: '',
				platform: '',
				click_date_from: moment().subtract(4, 'days').format('YYYY-MM-DD'),
				click_date_to: moment().subtract(1, 'day').format('YYYY-MM-DD'),
				remote_publisher_id: 0,
				remote_offer_id: 0,
				status: 'new',
				import_offset: 0,
				limit: 0,
			};
			this.$bvModal.show('job-form-modal');
		},
		editJob(job){
			this.currentJob = Vue.util.extend({}, job);
			this.$bvModal.show('job-form-modal');
		},
		copyJob(job){
			this.currentJob = Vue.util.extend({}, job);
			this.currentJob.id = 0;
			this.currentJob.click_date_from = this.currentJob.click_date;
			this.currentJob.click_date_to = this.currentJob.click_date;
			this.currentJob.status = 'new';
			this.$bvModal.show('job-form-modal');
		},
		async deleteRecord(job){
			if (!confirm('Are you sure?')){
				return;
			}
			try {
				let data = {
					id: job.id
				};
				let resp = await this.$api.post('jobs/deleteClickImportJob', data);
				this.busySave = false;
				this.$notify.success('Job has been removed');
				this.loadData();
			} catch (e) {
				console.error(e);
				this.busySave = false;
			}
		},
		async saveJob(bvModalEvt){
			bvModalEvt.preventDefault();
			if (this.busySave) {
				return;
			}
			this.busySave = true;
			let data = Vue.util.extend({}, this.currentJob);
			try {
				let resp = await this.$api.post('jobs/saveClickImportJob', data);
				this.busySave = false;
				this.$notify.success('Job has been saved');
				this.$bvModal.hide('job-form-modal');
				this.loadData();
			} catch (e) {
				console.error(e);
				this.busySave = false;
			}
		},
		async loadData(){
			this.busy = true;
			let params = {
				page: this.paginate.page,
				limit: this.paginate.limit,
				// publisher_id: this.filters.publisher_id.map(r => r.v).join(','),
				countries: this.filters.countries,
				platform: this.filters.platform,
				import_date: this.filters.import_date,
				remote_publisher_id: this.filters.remote_publisher_id,
				remote_offer_id: this.filters.remote_offer_id,
			};
			try {
				let resp = await this.$api.get('/jobs/getClickImportJobs', {params});
				this.records = resp.records;
				this.paginate.total = resp.total;
				this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
			} catch (e) {
				console.error(e);
			}
			this.busy = false;
		}
	}
}

</script>
