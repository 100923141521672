import Vue from 'vue';
import Router from 'vue-router';


Vue.use(Router);

const withPrefix = (prefix, routes) =>
	routes.map((route) => {
		route.path = prefix + route.path;
		return route;
	});

export default new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			redirect: '/app'
		},
		{
			path: '/app',
			component: () => import('@/views/layout/Layout'),
			children: [
				{
					path: '',
					name: 'dashboard',
					component: () => import('@/views/pages/Dashboard.vue')
				},
				{
					path: 'media',
					name: 'media',
					component: () => import('@/views/pages/media/Layout.vue'),
					children: [
						{
							path: 'reports/bucksense',
							name: 'bucksense-reports',
							component: () => import('@/views/pages/media/mb/bucksense/BucksenseReports.vue')
						},
						{
							path: 'reports',
							name: 'reports',
							component: () => import('@/views/pages/media/reports/Reports.vue')
						},
						{
							path: 'reports/postback-log',
							name: 'postback-log',
							component: () => import('@/views/pages/media/reports/PostbackLog.vue')
						},
						{
							path: 'reports/clicks',
							name: 'clicks',
							component: () => import('@/views/pages/media/reports/ClickList.vue')
						},
						{
							path: 'reports/clicks/:id',
							name: 'click-view',
							component: () => import('@/views/pages/media/reports/ClickView.vue')
						},
						{
							path: 'reports/events',
							name: 'events',
							component: () => import('@/views/pages/media/reports/Events.vue')
						},
						{
							path: 'reports/rejection-events',
							name: 'rejection-events',
							component: () => import('@/views/pages/media/reports/RejectionEvents.vue')
						},
						{
							name: 'profiles', path: 'supply/profiles',
							component: () => import('@/views/pages/media/profiles/List.vue')
						},
						{
							name: 'remote-publishers', path: 'supply/remote-publishers',
							component: () => import('@/views/pages/media/remote/RemotePublisherList.vue')
						},
						{
							name: 'remote-publisher-groups', path: 'supply/remote-publisher-groups',
							component: () => import('@/views/pages/media/remote/RemotePublisherGroupList.vue')
						},

						...withPrefix('supply/creatives', [
							{
								name: 'creative-list', path: '',
								component: () => import('@/views/pages/media/creatives/List.vue')
							},
							{
								name: 'creative-add', path: '/add',
								component: () => import('@/views/pages/media/creatives/Form.vue')
							},
							{
								name: 'creative-edit', path: '/edit/:id',
								component: () => import('@/views/pages/media/creatives/Form.vue')
							}
						]),
						...withPrefix('demand/advertisers', [
							{
								name: 'advertiser-list', path: '',
								component: () => import('@/views/pages/media/advertisers/List.vue')
							},
							{
								name: 'advertiser-add', path: '/add',
								component: () => import('@/views/pages/media/advertisers/Form.vue')
							},
							{
								name: 'advertiser-edit', path: '/edit/:id',
								component: () => import('@/views/pages/media/advertisers/Form.vue')
							}
						]),
						...withPrefix('demand/networks', [
							{
								name: 'network-list', path: '',
								component: () => import('@/views/pages/media/networks/List.vue')
							},
							{
								name: 'network-add', path: '/add',
								component: () => import('@/views/pages/media/networks/Form.vue')
							},
							{
								name: 'network-edit', path: '/edit/:id',
								component: () => import('@/views/pages/media/networks/Form.vue')
							}
						]),
						...withPrefix('demand/mmp-accounts', [
							{
								name: 'mmp-account-list', path: '',
								component: () => import('@/views/pages/media/mmp-accounts/List.vue')
							},
							{
								name: 'mmp-account-add', path: '/add',
								component: () => import('@/views/pages/media/mmp-accounts/Form.vue')
							},
							{
								name: 'mmp-account-edit', path: '/edit/:id',
								component: () => import('@/views/pages/media/mmp-accounts/Form.vue')
							}
						]),


						// MB
						...withPrefix('supply/mb/platforms', [
							{
								name: 'mb-platform-list', path: '',
								component: () => import('@/views/pages/media/mb/platforms/List.vue')
							},
							{
								name: 'mb-platform-add', path: '/add',
								component: () => import('@/views/pages/media/mb/platforms/Form.vue')
							},
							{
								name: 'mb-platform-edit', path: '/edit/:id',
								component: () => import('@/views/pages/media/mb/platforms/Form.vue')
							}
						]),

						...withPrefix('jobs/ac', [
							{
								name: 'ac-list', path: '',
								component: () => import('@/views/pages/media/ac/List.vue')
							},
							{
								name: 'ac-add', path: '/add',
								component: () => import('@/views/pages/media/ac/Form.vue')
							},
							{
								name: 'ac-edit', path: '/edit/:id',
								component: () => import('@/views/pages/media/ac/Form.vue')
							}
						]),


						...withPrefix('jobs/auto-campaigns', [
							{
								name: 'auto-campaign-list', path: '',
								component: () => import('@/views/pages/media/auto-campaigns/List.vue')
							},
							{
								name: 'auto-campaign-add', path: '/add',
								component: () => import('@/views/pages/media/auto-campaigns/Form.vue')
							},
							{
								name: 'auto-campaign-edit', path: '/edit/:id',
								component: () => import('@/views/pages/media/auto-campaigns/Form.vue')
							}
						]),
						...withPrefix('jobs/auto-imports', [
							{
								name: 'auto-import-list', path: '',
								component: () => import('@/views/pages/media/auto-imports/List.vue')
							},
							// {
							// 	name: 'auto-import-add', path: '/add',
							// 	component: () => import('@/views/pages/media/auto-imports/Form.vue')
							// },
							// {
							// 	name: 'auto-import-edit', path: '/edit/:id',
							// 	component: () => import('@/views/pages/media/auto-imports/Form.vue')
							// }
						]),


						{
							path: 'monitor/ssl',
							name: 'monitor-ssl',
							component: () => import('@/views/pages/monitor/SSL.vue')
						},
						{
							path: 'monitor/acl',
							name: 'monitor-acl',
							component: () => import('@/views/pages/monitor/ACL.vue')
						},

						{
							name: 'external-offer-list', path: 'demand/external-offers',
							component: () => import('@/views/pages/media/offers/ExternalOfferList.vue')
						},
						...withPrefix('jobs', [
							{
								name: 'click-import-job-list', path: '/import',
								component: () => import('@/views/pages/media/jobs/ClickImportJobList.vue')
							},
							{
								name: 'click-generator-job-list', path: '/generate',
								component: () => import('@/views/pages/media/jobs/ClickGeneratorJobList.vue')
							},
						]),
						...withPrefix('demand/offers', [
							{
								name: 'offer-list', path: '',
								component: () => import('@/views/pages/media/offers/List.vue')
							},
							{
								name: 'offer-add', path: '/add',
								component: () => import('@/views/pages/media/offers/Form.vue')
							},
							{
								name: 'offer-edit', path: '/edit/:id',
								component: () => import('@/views/pages/media/offers/Form.vue')
							}
						]),
						...withPrefix('demand/campaigns', [
							{
								name: 'campaign-list', path: '',
								component: () => import('@/views/pages/media/campaigns/List.vue')
							},
							{
								name: 'campaign-add', path: '/add',
								component: () => import('@/views/pages/media/campaigns/Form.vue')
							},
							{
								name: 'campaign-edit', path: '/edit/:id',
								component: () => import('@/views/pages/media/campaigns/Form.vue')
							}
						]),
						...withPrefix('demand/data-control', [
							{
								name: 'data-control-list', path: '',
								component: () => import('@/views/pages/media/data-control/List.vue')
							},
							{
								name: 'data-control-add', path: '/add',
								component: () => import('@/views/pages/media/data-control/Form.vue')
							},
							{
								name: 'data-control-edit', path: '/edit/:id',
								component: () => import('@/views/pages/media/data-control/Form.vue')
							},
							{
								name: 'source-blacklist', path: '/source-blacklist',
								component: () => import('@/views/pages/media/data-control/SourceBlacklist.vue')
							}
						]),

						...withPrefix('supply/publishers', [
							{
								name: 'publisher-list', path: '',
								component: () => import('@/views/pages/media/publishers/List.vue')
							},
							{
								name: 'publisher-add', path: '/add',
								component: () => import('@/views/pages/media/publishers/Form.vue')
							},
							{
								name: 'publisher-edit', path: '/edit/:id',
								component: () => import('@/views/pages/media/publishers/Form.vue')
							}
						]),
						...withPrefix('supply/placements', [
							{
								name: 'placement-list', path: '',
								component: () => import('@/views/pages/media/placements/List.vue')
							},
							{
								name: 'placement-add', path: '/add',
								component: () => import('@/views/pages/media/placements/Form.vue')
							},
							{
								name: 'placement-edit', path: '/edit/:id',
								component: () => import('@/views/pages/media/placements/Form.vue')
							}
						]),
						...withPrefix('supply/tags', [
							{
								name: 'tag-list', path: '',
								component: () => import('@/views/pages/media/tags/List.vue')
							},
							{
								name: 'tag-add', path: '/add',
								component: () => import('@/views/pages/media/tags/Form.vue')
							},
							{
								name: 'tag-edit', path: '/edit/:id',
								component: () => import('@/views/pages/media/tags/Form.vue')
							}
						]),

						...withPrefix('mb/campaigns', [
							{
								name: 'mb-campaign-list', path: '',
								component: () => import('@/views/pages/media/mb/campaigns/List.vue')
							},
							{
								name: 'mb-campaign-add', path: '/add',
								component: () => import('@/views/pages/media/mb/campaigns/Form.vue')
							},
							{
								name: 'mb-campaign-edit', path: '/edit/:id',
								component: () => import('@/views/pages/media/mb/campaigns/Form.vue')
							}
						]),
						...withPrefix('rtb/campaigns', [
							{
								name: 'campaign-list', path: '',
								component: () => import('@/views/pages/media/campaigns/List.vue')
							},
							{
								name: 'campaign-add', path: '/add',
								component: () => import('@/views/pages/media/campaigns/Form.vue')
							},
							{
								name: 'campaign-edit', path: '/edit/:id',
								component: () => import('@/views/pages/media/campaigns/Form.vue')
							}
						]),
						...withPrefix('rtb/publisher-feeds', [
							{
								name: 'publisher-feed-list', path: '',
								component: () => import('@/views/pages/media/publisher-feeds/List.vue')
							},
							{
								name: 'publisher-feed-add', path: '/add',
								component: () => import('@/views/pages/media/publisher-feeds/Form.vue')
							},
							{
								name: 'publisher-feed-edit', path: '/edit/:id',
								component: () => import('@/views/pages/media/publisher-feeds/Form.vue')
							}
						]),
						...withPrefix('rtb/advertiser-feeds', [
							{
								name: 'advertiser-feed-list', path: '',
								component: () => import('@/views/pages/media/advertiser-feeds/List.vue')
							},
							{
								name: 'advertiser-feed-add', path: '/add',
								component: () => import('@/views/pages/media/advertiser-feeds/Form.vue')
							},
							{
								name: 'advertiser-feed-edit', path: '/edit/:id',
								component: () => import('@/views/pages/media/advertiser-feeds/Form.vue')
							}
						]),

						{
							path: 'supply/udp-report',
							name: 'udp-report',
							component: () => import('@/views/pages/media/reports/UDPReport.vue')
						},
						...withPrefix('settings/users', [
							{
								name: 'user-list', path: '',
								component: () => import('@/views/pages/media/users/List.vue')
							},
							{
								name: 'user-add', path: '/add',
								component: () => import('@/views/pages/media/users/Form.vue')
							},
							{
								name: 'user-edit', path: '/edit/:id',
								component: () => import('@/views/pages/media/users/Form.vue')
							}
						]),
					]
				}
			]
		},
		{
			name: 'login',
			path: '/login',
			component: () => import("@/views/pages/Login")
		},
		// {
		// 	path: '*',
		// 	redirect: '/404'
		// },
		{
			// the 404 route, when none of the above matches
			path: "/404",
			name: "404",
			component: () => import("@/views/pages/error/Error-1.vue")
		}
	]
});
