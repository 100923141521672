<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		.card.card-custom.gutter-b
			.card-header
				.card-title
					.card-label Generate Clicks
				.card-toolbar
			.card-body
				form.form.form-inline.form-filters(@submit.prevent="loadData()")
					div(style="width: 100%; display: block")
						.row.filters-form-row
							.form-row-main.col-auto.mr-auto(style="flex:1;")
								select-offer(v-model="filters.offer_id")
								.form-group
									label OS
									select.form-control(v-model="filters.platform")
										option(value="") - All -
										option(value="ios") iOS
										option(value="android") Android
								.form-group
									label Countries
									input.form-control.input-search(type="text", placeholder="", v-model="filters.countries")
								.form-group
									label R.PubID
									input.form-control.input-search(type="text", placeholder="", v-model="filters.remote_publisher_id")
								.form-group
									label R.OfferID
									input.form-control.input-search(type="text", placeholder="", v-model="filters.remote_offer_id")
								.form-group
									label Status
									select.form-control(v-model="filters.status")
										option(value="") All
										option(value="active") All active
										option(value="draft") Draft
										option(value="pending") Pending
										option(value="process") Process
										option(value="paused") Paused
										option(value="done") Done
										option(value="error") Error
										option(value="canceled") Canceled
								.form-group.no-label
									button.btn.btn-primary(type="submit", @click="loadData()") Go
							.form-row-end.col-auto
								.form-group.no-label
									button.btn.btn-success(type="button", @click="addJob()")
										i.flaticon2-plus
										| Add

				table.table.table-bordered.table-compact
					thead
						tr
							th ID
							th &nbsp;
							th Status
							th Placement
							th Offer
							th Advertiser
							th OS
							th Geo
							th R.Pub
							th R.Off
							th Time Range
							th Limit
							th Count
							th Start On
							th Created At
							th Actions
					tbody
						tr(v-if="records.length===0")
							td(colspan="15") No matching records were found
						tr(v-for="r in records", :key="r.id", :class="'row-'+r.status")
							td {{ r.id }}
							td.actions
								button.btn.btn-action.btn-danger(type="button", v-if="r.status==='process'", @click="pauseRecord(r)")
									i.la.la-pause
								button.btn.btn-action.btn-danger(type="button", v-if="r.status==='paused'", @click="resumeRecord(r)")
									i.la.la-play
							td {{ r.status }}
							td
								entity(:id="r.placement.id", :name="r.placement.name", type="placement")
							td
								entity(:id="r.offer.id", :name="r.offer.name", type="offer")
							td
								entity(:id="r.advertiser.id", :name="r.advertiser.name", type="advertiser")
							td {{ r.platform }}
							td {{ r.country }}
								span(v-if="r.region") , {{ r.region.name }}
							td
								span(v-if="r.remote_publisher_id") [{{ r.remote_publisher_id }}]
								=' '
								span(v-if="r.remote_publisher") {{ r.remote_publisher.name }}
							td {{ r.remote_offer_id }}
							td {{ r.profile_time_from }} - {{ r.profile_time_to }}
							td {{ r.limit }}
							td.text-right.monospaced {{ r.current_count.toLocaleString() }}
							td {{ r.start_time }}
							td {{ r.created_at }}
							td.actions
								button.btn.btn-action.btn-secondary(type="button", @click="editJob(r)")
									i.la.la-pen
								button.btn.btn-action.btn-secondary(type="button", @click="copyJob(r)")
									i.la.la-copy
								.btn.btn-action.btn-outline-danger()
									i.la.la-trash

				paginate(:paginator="paginate", @update-page-size="loadData")

				b-modal#job-form-modal(title="Job Info", @ok="saveJob")
					loading(:active.sync="busySave", :is-full-page="false")
					form.form.form-horizontal(v-if="currentJob")
						select-placement(v-model="currentJob.placement", :multiple="false")
						.form-group
							label Start time
							input.form-control(type="text", v-model="currentJob.start_time", placeholder="2021-01-14 00:00:00")
						.row
							.col-sm-6
								.form-group
									label Date From
									input.form-control(type="text", v-model="currentJob.profile_time_from", placeholder="2021-01-14 00:00:00")
							.col-sm-6
								.form-group
									label Date To
									input.form-control(type="text", v-model="currentJob.profile_time_to", placeholder="2021-01-15 00:00:00")
						.row
							.col-sm-6
								.form-group
									label Country
									input.form-control(type="text", v-model="currentJob.country", placeholder="US")
							.col-sm-6
								.form-group
									label Platform
									select.form-control(v-model="currentJob.platform")
										option(value="ios") iOS
										option(value="android") Android
						.row
							.col-sm-6
								.form-group
									label RegionID
									input.form-control(type="text", v-model="currentJob.region_id", placeholder="1234")
							.col-sm-6
								.form-group
									label Exclude RegionIDs
									input.form-control(type="text", v-model="currentJob.exclude_regions", placeholder="123,456")
						.row
							.col-sm-6
								.form-group
									b-checkbox(v-model="currentJob.require_device_id") Require Device ID
							.col-sm-6
								.form-group
									b-checkbox(v-model="currentJob.send_impressions") Send Impressions
						.row
							.col-sm-4
								.form-group
									label Publisher ID
									input.form-control(type="text", v-model="currentJob.publisher_id")
							.col-sm-4
								.form-group
									label Remote Publisher ID
									input.form-control(type="text", v-model="currentJob.remote_publisher_id")
							.col-sm-4
								.form-group
									label Remote Offer ID
									input.form-control(type="text", v-model="currentJob.remote_offer_id")
						.row
							.col-sm-6
								.form-group
									label Limit
									input.form-control(type="text", v-model="currentJob.limit")
							.col-sm-6
								.form-group
									label Delivery Speed
									input.form-control(type="text", v-model="currentJob.delivery_speed")
							.col-sm-6
								.form-group
									label Status
									select.form-control(v-model="currentJob.status")
										option(value="draft") Draft
										option(value="pending") Pending
							.col-sm-6
								.form-group
									button.btn.btn-secondary(type="button", @click="countJobReach()") Calculate
										=' '
										span(v-if="currentJob.calculate_results") ({{currentJob.calculate_results}})


</template>
<style lang="scss">
.row-pending {
	background: #bbeaff;
}
.row-process {
	background: #ddffdd;
}
.row-paused {
	background: #ffedc9;
}
.row-done {
	background: #f6f6f6;
}
</style>
<script>
import Vue from 'vue';
import moment from 'moment';
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
	name: 'ClickImportJobList',
	async beforeRouteEnter(to, from, next){
		let publisherOptions = await Vue.$dwData.publisher.getOptions();
		next(vm => {
			vm.publisherOptions = publisherOptions;
			vm.loadData();
			return vm;
		});
	},
	data(){
		return {
			busy: false,
			busySave: false,
			paginate: {
				numPages: 0,
				total: 0,
				page: 1,
				limit: 50,
				onPageChange: () => {
					this.loadData();
				},
			},
			publisherOptions: [],
			records: [],
			currentJob: null,
			filters: {
				status: '',
				offer_id: [],
				countries: '',
				platform: '',
				remote_publisher_id: '',
				remote_offer_id: '',
			}
		};
	},
	mounted(){
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Media'},
			{title: 'Jobs'},
			{title: 'Generate Clicks'}
		]);
	},
	methods: {
		addJob(){
			this.currentJob = {
				id: 0,
				placement_id: null,
				placement: null,
				country: 'US',
				region_id: null,
				city_id: null,
				remote_publisher_id: null,
				remote_offer_id: null,
				send_impressions: false,
				require_device_id: false,
				platform: 'ios',
				status: 'draft',
				exclude_regions: '',
				profile_time_from: moment().format('YYYY-MM-DD')+' 00:00:00',
				profile_time_to: moment().add(1, 'day').format('YYYY-MM-DD')+' 00:00:00',
				start_time: moment().add(1, 'day').format('YYYY-MM-DD')+' 00:00:00',
				limit: 0,
				delivery_speed: 1,
			};
			this.$bvModal.show('job-form-modal');
		},
		editJob(job){
			this.currentJob = Vue.util.extend({}, job);
			this.currentJob.placement = {
				v: this.currentJob.placement_id,
				t: `[${this.currentJob.placement_id}] ${this.currentJob.offer.name}`,
			};
			this.$bvModal.show('job-form-modal');
		},
		copyJob(job){
			this.currentJob = Vue.util.extend({}, job);
			this.currentJob.placement = {
				v: this.currentJob.placement_id,
				t: `[${this.currentJob.placement_id}] ${this.currentJob.offer.name}`,
			};
			this.currentJob.id = 0;
			this.currentJob.status = 'draft';
			this.$bvModal.show('job-form-modal');
		},
		async countJobReach(){
			this.busySave = true;
			let data = Vue.util.extend({}, this.currentJob);
			data.placement_id = data.placement ? data.placement.v : 0;
			delete data.placement;
			try {
				let resp = await this.$api.post('jobs/countClickGeneratorJobReach', data);
				this.currentJob.calculate_results = resp.total;
				console.log(resp);
			} catch (e) {
				console.error(e);
			}
			this.busySave = false;
		},
		async pauseRecord(r){
			this.busySave = true;
			let data = {id: r.id};
			try {
				let resp = await this.$api.post('jobs/pauseClickGeneratorJob', data);
				this.busySave = false;
				this.$notify.success('Job has been paused');
			} catch (e) {
				console.error(e);
				this.busySave = false;
			}
		},
		async resumeRecord(r){
			this.busySave = true;
			let data = {id: r.id};
			try {
				let resp = await this.$api.post('jobs/resumeClickGeneratorJob', data);
				this.busySave = false;
				this.$notify.success('Job has been resumed');
			} catch (e) {
				console.error(e);
				this.busySave = false;
			}
		},
		async saveJob(bvModalEvt){
			bvModalEvt.preventDefault();
			if (this.busySave) {
				return;
			}
			this.busySave = true;
			let data = Vue.util.extend({}, this.currentJob);
			data.placement_id = data.placement.v;
			delete data.placement;
			try {
				let resp = await this.$api.post('jobs/saveClickGeneratorJob', data);
				this.busySave = false;
				this.$notify.success('Job has been saved');
				this.$bvModal.hide('job-form-modal');
				this.loadData();
			} catch (e) {
				console.error(e);
				this.busySave = false;
			}
		},
		async loadData(){
			this.busy = true;
			let params = {
				page: this.paginate.page,
				limit: this.paginate.limit,
				offer_id: this.filters.offer_id.map(r => r.v).join(','),
				status: this.filters.status,
				platform: this.filters.platform,
				countries: this.filters.countries,
				remote_publisher_id: this.filters.remote_publisher_id,
				remote_offer_id: this.filters.remote_offer_id,
				// publisher_id: this.filters.publisher_id.map(r => r.v).join(','),
			};
			try {
				let resp = await this.$api.get('jobs/getClickGeneratorJobs', {params});
				this.records = resp.records;
				this.paginate.total = resp.total;
				this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
			} catch (e) {
				console.error(e);
			}
			this.busy = false;
		}
	}
}

</script>
