<template lang="pug">
.fg-offer(:class="{'form-group form-group-sm': wrapped}")
	label(v-if="wrapped") Offers
	multiselect(:multiple="this.multiple", :options="offerOptions", :close-on-select="false",
		:searchable="true", @search-change="asyncFind",
		:loading="isLoading",  :max-height="300", :internal-search="false"
		:value="value", @input="onChange", track-by="id", label="name", deselect-label="", select-label="")
		template(#tag="{ option, remove }")
			span.multiselect__tag [{{ option.id }}] {{ option.name }}
				i.multiselect__tag-icon(@click="remove(option)")
		template(#option="props")
			span [{{ props.option.id }}] {{ props.option.name }}
		template(#singleLabel="{option}")
			span [{{option.id }}] {{ option.name }}
</template>
<script>
import Vue from 'vue';

export default {
	name: 'SelectOffer',
	props: {
		value: [Array, Object],
		multiple: {
			type: Boolean,
			default: true,
			required: false
		},
		wrapped: {
			type: Boolean,
			default: true,
			required: false
		},
		network_id: {
			type: Number,
			default: null,
			required: false
		}
	},
	data() {
		return {
			isLoading: false,
			offerOptions: [],
		};
	},
	methods: {
		async asyncFind(query) {
			this.isLoading = true;
			console.log(query);
			try {
				let params = {
					page: 1,
					limit: 20,
					keyword: query,
					network_id: 1,
				};
				let resp = await this.$api.get('/offers/getList', {params});
				this.offerOptions = resp.records;
			} catch (e) {
			}
			this.isLoading = false;
		},
		onChange(value) {
			this.$emit('input', value);
		}
	},
	async mounted() {
		// this.offerOptions = await Vue.$dwData.offer.getOptions(true);
	}
}
</script>
