 <template lang="pug">
	.card.card-custom.gutter-b
		.card-header
			.card-title
				.card-label Remote Publishers
			.card-toolbar
		.card-body
			form.form.form-inline.form-filters(@submit.prevent="loadData()", :class="{expanded:filtersExpanded}")
				a.btn.btn-primary.toggle-filters.d-md-none(v-b-toggle.form-filters)
					span Filters
					i.fa.fw.fa-chevron-up.transition-all(:class="{'rotate-180':filtersExpanded}")
				b-collapse#form-filters.dont-collapse-sm(style="width: 100%;", v-model="filtersExpanded")
					.row.filters-form-row
						.form-row-main.col-auto.mr-auto(style="flex:1;")
							.form-group
								label Search
								input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.keyword", v-debounce:300ms="loadData")
							.form-group.no-label
								button.btn.btn-primary(type="button", @click="loadData()") Go
						.form-row-end.col-auto
							.form-group.no-label
								button.btn.btn-success(type="button", @click="addRecord()")
									i.flaticon2-plus
									| Add

			p.clearfix &nbsp;

			table.table.table-bordered.table-compact
				thead
					tr
						th Publisher
						th Remote Publisher ID
						th Remote Publisher Name
						th Score
						th Actions
				tbody
					tr(v-if="records.length===0")
						td(colspan="4") No matching records were found
					tr(v-for="r in records", :key="r.id")
						td {{ r.publisher.name }}
						td {{ r.remote_publisher_id }}
						td {{ r.name }}
						td {{ r.score }}
						td.actions
							button.btn.btn-action.btn-secondary(type="button", @click="editRecord(r)")
								i.la.la-pen
							.btn.btn-action.btn-outline-danger()
								i.la.la-trash

			paginate(:paginator="paginate", @update-page-size="loadData")

			b-modal#record-form-modal(title="Remote Publisher", @ok="saveRecord")
				loading(:active.sync="busySave", :is-full-page="false")
				form.form.form-horizontal(v-if="currentRecord")
					.form-group
						label PublisherID
						input.form-control(type="text", v-model="currentRecord.publisher_id")
					.row
						.col-sm-6
							.form-group
								label Remote Publisher ID
								input.form-control(type="text", v-model="currentRecord.remote_publisher_id")
						.col-sm-6
							.form-group
								label Name
								input.form-control(type="text", v-model="currentRecord.name")
					.row
						.col-sm-6
							.form-group
								label Score
								input.form-control(type="text", v-model="currentRecord.score")


</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
import moment from "moment";
export default {
	name: 'RemotePublisherList',
	async beforeRouteEnter(to, from, next){
		let publisherOptions = await Vue.$dwData.publisher.getOptions();
		next(vm => {
			vm.publisherOptions = publisherOptions;
			vm.loadData();
			return vm;
		});
	},
	data(){
		return {
			busy: false,
			busySave: false,
			paginate: {
				numPages: 0,
				total: 0,
				page: 1,
				limit: 50,
				onPageChange: () => {
					this.loadData();
				}
			},
			publisherOptions: [],
			filters: {
				keyword: '',
				status: 1
			},
			records: [],
			currentRecord: null,
			filtersExpanded: false,
		};
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ title: 'Media' },
			{ title: 'Remote Publishers' }
		]);
	},
	methods: {

		addRecord(){
			this.currentRecord = {
				id: 0,
				publisher_id: 2,
				remote_publisher_id: null,
				name: '',
				score: 0,
			};
			this.$bvModal.show('record-form-modal')
		},
		editRecord(job){
			this.currentRecord = Vue.util.extend({}, job);
			this.$bvModal.show('record-form-modal');
		},
		async saveRecord(bvModalEvt){
			bvModalEvt.preventDefault();
			if (this.busySave) {
				return;
			}
			this.busySave = true;
			let data = Vue.util.extend({}, this.currentRecord);
			try {
				let resp = await this.$api.post('remote-entities/savePublisher', data);
				this.busySave = false;
				this.$notify.success('Record has been saved');
				this.$bvModal.hide('record-form-modal');
				this.loadData();
			} catch (e) {
				console.error(e);
				this.busySave = false;
			}
		},

		async loadData(){
			this.busy = true;
			let params = {
				page: this.paginate.page,
				limit: this.paginate.limit,
				keyword: this.filters.keyword,
			};
			try {
				let resp = await this.$api.get('remote-entities/getPublisherList', {params});
				this.records = resp.records;
				this.paginate.total = resp.total;
				this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
			} catch (e){
				console.error(e);
			}
			this.busy = false;
		}
	},
	created(){
		this.loadData();
	}

}
</script>
