<template lang="pug">
	div.quick-edit-select(:class="{'edit-inline': !wrapped, 'edit-mode':editModeActive}")
		span(v-if="!notext && !r['_edit_'+field]") {{ r[field] }}
		select.form-control.form-control-sm(type="text", v-model="r['_'+field]", v-if="r['_edit_' + field]")
			option(v-for="o in options", :value="o.v") {{ o.t }}
		span.quickedit-buttons
			a(href="javascript:void(0)", @click="editRecord(r, field)", v-if="!r['_edit_' + field]")
				i.la.la-pencil
			a(href="javascript:void(0)", @click="cancelEditRecord(r, field)", v-if="r['_edit_' + field]")
				i.la.la-times
			a(href="javascript:void(0)", @click="saveRecord(r, field)", v-if="r['_edit_' + field]")
				i.la.la-save
</template>
<script>
	import Vue from 'vue';

	export default {
		name: 'quick-edit-select',
		props: {
			notext: {
				type: Boolean,
				default(){
					return false;
				}
			},
			wrapped: Boolean,
			r: {
				type: Object
			},
			field: {
				type: String
			},
			options: {
				type: Array
			}
		},
		computed: {
			editModeActive: function (){
				return this.editMode;
			}
		},
		data(){
			return {
				editMode: false
			};
		},
		methods: {
			editRecord(r, field){
				this.editMode = true;
				Vue.set(r, '_' + field, r[field]);
				Vue.set(r, '_edit_' + field, true);
				r = r[field];
			},
			cancelEditRecord(r, field){
				this.editMode = false;
				Vue.set(r, '_edit_' + field, false);
			},
			async saveRecord(r, field){
				this.$emit('on-save', r, field);
			}
		}
	}
</script>
