<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		.card.card-custom.gutter-b
			.card-header
				.card-title
					.card-label User Device Profile
				.card-toolbar
			.card-body
				form.form.form-inline.form-filters(@submit.prevent="loadData()", :class="{expanded:filtersExpanded}")
					a.btn.btn-primary.toggle-filters.d-md-none(v-b-toggle.form-filters)
						span Filters
						i.fa.fw.fa-chevron-up.transition-all(:class="{'rotate-180':filtersExpanded}")
					b-collapse#form-filters.dont-collapse-sm(style="width: 100%;", v-model="filtersExpanded")
						.row.filters-form-row
							.form-row-main.col-auto.mr-auto(style="flex:1;")
								.form-group
									label Search
									input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.keyword", v-debounce:300ms="loadData")
								//.form-group
									label Status
									select.form-control(v-model="filters.status", @change="loadData()")
										option(v-for="o in statusOptions", :value="o.v") {{ o.t }}
								.form-group.no-label
									button.btn.btn-primary(type="button", @click="loadData()") Go

				p.clearfix &nbsp;

				table.table.table-bordered.table-compact
					thead
						tr
							//th ID
							th IP
							th OS
							th Country
							th DeviceID
							th Headers
							th Appname
							th Bundle
							th Last Seen
							th Actions
					tbody
						tr(v-if="records.length===0")
							td(colspan="9") No matching records were found
						tr(v-for="r in records", :key="r.id")
							//td {{ r.id }}
							td {{ r.ip_fmt }}
							td {{ r.platform }}
							td {{ r.country }}
							td {{ r.device_id }}
							td
								div(style="width: 300px;")
									small {{ r.headers['user-agent'] }}
									br
									small {{ r.headers['accept-language'] }}
							td
								div(style="max-width: 200px; overflow: hidden; text-overflow: ellipsis;") {{ r.params['app_name'] }}
							td {{ r.params['bundle_id'] }}
							td {{ r.last_seen }}
							td &nbsp;
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
	name: 'ProfileList',
	data(){
		return {
			busy: false,
			// PERMS: null,
			paginate: {
				numPages: 0,
				total: 0,
				page: 1,
				limit: 50,
				onPageChange: () => {
					this.loadData();
				}
			},
			filters: {
				keyword: '',
				status: 1
			},
			// statusOptions: [
			// 	{v: undefined, t: 'All'},
			// 	{v: 0, t: 'Inactive'},
			// 	{v: 1, t: 'Active Only'},
			// ],
			records: [],
			filtersExpanded: false,
		};
	},
	mounted(){
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Media'},
			{title: 'Profiles'}
		]);
	},
	methods: {
		async loadData(){
			this.busy = true;
			let params = {
				page: this.paginate.page,
				limit: this.paginate.limit,
				keyword: this.filters.keyword,
			};
			try {
				let resp = await this.$api.get('/profiles/getList', {params});
				this.records = resp.records;
				this.paginate.total = resp.total;
				this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
			} catch (e) {
				console.error(e);
			}
			this.busy = false;
		}
	},
	created(){
		this.loadData();
	}
}
</script>
