<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		form(@submit.prevent="submit", v-if="campaign")

			b-tabs(content-class="mt-3", pills, card)
				b-tab(title="Main", style="padding: 0")
					.row
						.col-md-6
							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Campaign Information <span v-if="campaign.id">({{ campaign.id }})</span>
									.card-toolbar
								.card-body
									.row
										.col-sm-6
											.form-group
												label Name
												input.form-control(type="text", v-model="campaign.name")
										.col-sm-6
											.form-group
												label Status
												select.form-control(v-model="campaign.status")
													option(v-for="o in statusOptions", :value="o.v") {{ o.t }}
									.row
										.col-sm-6
											.form-group
												label Tag
												select.form-control(v-model="campaign.tag_id")
													option(:value="null") - Select -
													option(v-for="p in tagOptions", :value="p.v") {{ p.t }}
									.row
										.col-sm-4
											.form-group
												label Bid
												input.form-control(type="text", v-model="campaign.bid")
										.col-sm-4
											.form-group
												label Impression Cap
												input.form-control(type="text", v-model="campaign.daily_impression_cap")
										.col-sm-4
											.form-group
												label Budget
												input.form-control(type="text", v-model="campaign.daily_budget")
						.col-md-6
							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Targeting
									.card-toolbar
								.card-body
									.row
										.col-sm-6
											.form-group
												label Countries
												input.form-control(type="text", v-model="campaign.countries")
										.col-sm-6
											.form-group
												label Platform
												select.form-control(v-model="campaign.platform")
													option(:value="1") iOS
													option(:value="2") Android
													option(:value="3") Web

</template>
<script>
import Vue from 'vue';
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';

async function loadRoute(vm, to, from, next){

	let campaign = {};
	if (to.name === 'campaign-edit') {
		let entityId = +to.params.id;
		try {
			campaign = await Vue.$dwData.campaign.get(entityId, true);
		} catch (e) {
			Vue.$notify.error('Failed to load tag');
			return next(false);
		}
	} else {
		let copyEntityId = +to.query.copy_id;
		if (copyEntityId){
			try {
				campaign = await Vue.$dwData.campaign.get(copyEntityId, true);
				tag.id = 0;
			} catch (e) {
				Vue.$notify.error('Failed to load campaign');
				return next(false);
			}
		} else {
			campaign = Vue.$dwData.campaign.newInstance();
		}
	}

	let tagOptions = await Vue.$dwData.tag.getOptions(true);

	return vm => {
		vm.campaign = campaign;
		vm.tagOptions = tagOptions;
		vm.busy = false;
		return vm;
	};
}


export default {
	name: 'CampaignForm',
	async beforeRouteEnter(to, from, next){
		let bind = await loadRoute({}, to, from, next);
		next(bind);
	},
	async beforeRouteUpdate(to, from, next){
		await loadRoute(this, to, from, next);
		next();
	},
	data(){
		return {
			busy: true,
			campaign: null,
			tagOptions: [],
			statusOptions: Vue.$dwData.general.campaignStatusOptions
		};
	},
	methods: {
		async submit(closeAfterSave = false){
			if (this.busy){
				return;
			}
			this.busy = true;
			let data = Vue.util.extend({}, this.campaign);
			try {
				let resp = await this.$api.post('campaigns/save', data);
				this.busy = false;
				this.$notify.success('Campaign has been saved');
				if (closeAfterSave) {
					this.$router.push({name: 'campaign-list'});
				} else {
					this.placement = resp.entity;
				}
			} catch (e) {
				console.error(e);
				this.busy = false;
			}
		}
	},
	mounted(){
		let vm = this;
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Media'},
			{title: 'Demand'},
			{title: 'Campaigns'},
			{title: this.campaign && this.campaign.id ? 'Edit Campaign' : 'Add Campaign'}
		]);
		this.$store.dispatch('setBackButton', {
			route: {name: 'campaign-list'}
		});
		this.$store.dispatch('setPageButtons', [
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save',
				action(){
					vm.submit();
				}
			},
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save & Close',
				action(){
					vm.submit(true);
				}
			}
		]);
	},
}
</script>
