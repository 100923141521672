<template lang="pug">
	div.entity(:class="'entity-'+type + ' mode-'+mode" :style="wrapperStyle")
		div.active-area(@click="entityClicked()")
			span.id(v-if="!(params && params.show_id === false)") {{ id }}
			span.name {{ name }}
		ul.quick-menu
			li(v-for="b in buttons")
				a(v-if="b.action", @click="b.action()", v-b-tooltip.hover.bottom, :title="b.label" )
					i.la(:class="'la-'+b.icon")
				router-link(v-if="b.link", :to="b.link", v-b-tooltip.hover.bottom, :title="b.label")
					i.la(:class="'la-'+b.icon")

</template>
<style lang="scss">
$color-subsource: rgb(128, 178, 195);
$color-asset: rgb(255, 110, 6);
$color-placement: rgba(6, 133, 255, 1);
$color-publisher: rgb(102, 88, 179);

$color-offer: rgba(44, 210, 82, 1);
$color-advertiser: rgba(75, 155, 140, 1);

.entity {
	display: inline-block;
	padding: 3px 5px;
	border-radius: 5px;
	position: relative;
	transition: all .3s ease-in-out;
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 12px;
	line-height: 14px;
	background: rgba(0, 0, 0, 0.1);

	&.no-bg {
		background: transparent !important;

		&:hover {
			background: rgba(0, 0, 0, 0.1) !important;
		}
	}

	&:hover {
		background: rgba(0, 0, 0, 0.2);
	}

	span.name {
		/*max-width: 120px;*/
		overflow: hidden;
		display: inline;
		text-overflow: ellipsis;
		word-break: break-all;
	}


	span.id {
		//font-size: 12px;
		//position: absolute;
		//left: 5px;
		//top: -6px;
		border-radius: 3px;
		padding: 2px 2px 1px 2px;
		margin-right: 2px;
		margin-left: -5px;

		vertical-align: top;
		background: rgba(0, 0, 0, 0.2);
		color: #fff;
	}

	&.mode-mini {

		width: 75px;

		.active-area {
			//display: flex;
			position: relative;

			span.id {

			}

			span.name {
				top: 0;
				left: 100%;
				margin-left: 5px;
				z-index: 20;
				background: #fff;
				padding: 2px;
				border-radius: 5px;
				//display: none;
				position: absolute;
				display: none;
				width: 150px;
				//flex: 1;
				overflow: hidden;
				//display: inline-block;
			}

			&:hover {
				span.name {
					display: block;
				}
			}
		}

	}


	&.entity-subsource {
		background: rgba($color-subsource, 0.2);

		&:hover {
			background: $color-subsource;
		}

		&.no-bg {
			&:hover {
				background: rgba($color-subsource, 0.1) !important;
			}
		}

		span.id {
			background: $color-subsource;
			color: #fff;
		}

		span.name {
			max-width: 100%;
			overflow: hidden;
			display: inline-block;
			text-overflow: ellipsis;
		}

		ul.quick-menu li a {
			background: $color-subsource;
		}
	}

	&.entity-offer {
		background: rgba($color-offer, 0.2);

		&:hover {
			background: $color-offer;
		}

		&.no-bg {
			&:hover {
				background: rgba($color-offer, 0.2) !important;
			}
		}

		//border: 1px solid #2cd252;
		//border-bottom: 2px solid #2cd252;

		span.id {
			background: $color-offer;
			color: #fff;
		}

		ul.quick-menu li a {
			background: $color-offer;
		}
	}

	&.entity-asset {

		background: rgba($color-asset, 0.2);

		&:hover {
			background: $color-asset;
		}

		&.no-bg {
			&:hover {
				background: rgba($color-asset, 0.2) !important;
			}
		}

		//color: #fff;

		span.id {
			background: $color-asset;
			color: #fff;
		}

		ul.quick-menu li a {
			background: $color-asset;
		}
	}


	&.entity-placement {
		//border: 1px solid #0685ff;
		//border-bottom: 2px solid #0685ff;

		background: rgba($color-placement, 0.2);

		&:hover {
			background: $color-placement;
		}

		&.no-bg {
			&:hover {
				background: rgba($color-placement, 0.2) !important;
			}
		}

		//color: #fff;

		span.id {
			background: $color-placement;
			color: #fff;
		}

		ul.quick-menu li a {
			background: $color-placement;
		}
	}

	&.entity-advertiser {

		background: rgba($color-advertiser, 0.2);

		&:hover {
			background: $color-advertiser;
		}

		&.no-bg {
			&:hover {
				background: rgba($color-advertiser, 0.2) !important;
			}
		}

		//border: 1px solid #4b9c8d;
		//border-bottom: 2px solid #4b9c8d;
		//color: #fff;

		span.id {
			background: $color-advertiser;
			color: #fff;
		}

		ul.quick-menu li a {
			background: $color-advertiser;
		}
	}

	&.entity-publisher {

		background: rgba($color-publisher, 0.2);

		&:hover {
			background: $color-publisher;
		}

		&.no-bg {
			&:hover {
				background: rgba($color-publisher, 0.2) !important;
			}
		}

		//border: 1px solid #96389c;
		//border-bottom: 2px solid #96389c;
		//color: #fff;

		span.id {
			background: $color-publisher;
			color: #fff;
		}

		ul.quick-menu li a {
			background: $color-publisher;
		}
	}

	ul.quick-menu {
		display: none;
		//box-shadow: 2px 2px 3px rgba(0, 0, 0, .5);
		list-style: none;
		position: absolute;
		padding: 0;
		margin: 0;
		border-radius: 0 0 5px 5px;
		overflow: hidden;
		right: 0;
		bottom: -24px;
		height: 24px;
		z-index: 10;

		li {
			list-style: none;
			display: inline-block;
			float: right;
			width: 24px;
			height: 24px;

			a {
				//opacity: .8;
				color: #fff;
				cursor: pointer;
				float: right;
				width: 24px;
				height: 24px;
				line-height: 24px;
				text-align: center;
				font-size: 12px;
				transition: all .3s ease-in-out;
				i {
					color: #fff;
				}
				&:hover {
					//opacity: 1;
				}
			}
		}
	}

	&:hover {
		border-radius: 5px 5px 0 5px;

		ul.quick-menu {
			display: block;
		}
	}
}
</style>
<script>
import Vue from 'vue';

import QuickEditPlacementModal from './QuickEditPlacementModal';
import QuickEditOfferModal from './QuickEditOfferModal';
import SubsourceRulesModal from './SubsourceRulesModal';

export default {
	name: 'Entity',
	props: {
		id: [String, Number],
		name: [String, Number],
		mode: String,
		type: String,
		maxWidth: Number,
		data: Object,
		params: Object
	},
	components: {
		QuickEditPlacementModal
	},
	data(){
		return {
			//buttons: [],
			ctimeout: null,
			clickCounter: 0
		};
	},
	methods: {
		entityClicked(){
			this.clickCounter++;
			if (this.clickCounter === 1) {
				this.ctimeout = setTimeout(() => {
					this.clickCounter = 0;
					this.$emit('click');
				}, 400);
			} else {
				clearTimeout(this.ctimeout);
				this.clickCounter = 0;
				this.$emit('dblclick');
			}
		}
	},
	computed: {
		wrapperStyle(){
			let o = {};
			if (this.maxWidth) {
				o['max-width'] = this.maxWidth + 'px';
			}
			return o;
		},
		buttons(){
			let entity = this;
			switch (this.type) {
				case 'group':
					return [];
				case 'subsource': {
					let actions = [
						{
							icon: 'edit',
							label: 'Quick Edit',
							action: () => {
								this.$modal.show(SubsourceRulesModal, {
									id: this.id
								}, {
									height: 'auto',
									scrollable: true
								}, {
									'before-close': (/*event*/) => {
										// let placement = await Vue.ovData.placement.get(this.id, true);
										// this.$emit('update:name', placement.name);
									}
								});
							}
						}
					];
					if (entity.data && entity.data.status) {
						if (entity.data.status === 'open') {
							actions.push({
								icon: 'pause',
								label: 'Pause',
								action: () => {
									console.log('Pause');

									let params = {
										subsources: [this.id],
										status: 'block'
									};
									try {
										this.$ovReq.post('subsource/updateSourcesStatus', params);
										entity.data.status = 'blocked';
									} catch (e) {
										console.error(e);
									}
								}
							});
						} else {
							actions.push({
								icon: 'play',
								label: 'Resume',
								action: () => {

									let params = {
										subsources: [this.id],
										status: 'open'
									};
									try {
										this.$ovReq.post('subsource/updateSourcesStatus', params);
										entity.data.status = 'open';
									} catch (e) {
										console.error(e);
									}
								}
							})
						}
					}
					return actions;
				}
				case 'placement': {
					return [
						{
							icon: 'search',
							label: 'Edit',
							link: {
								name: 'placement-edit',
								params: {id: this.id}
							}
						},
						// {
						// 	icon: 'area-chart',
						// 	label: 'Targeting',
						// 	link: {
						// 		name: 'placement-targeting',
						// 		params: {id: this.id}
						// 	}
						// },
						{
							icon: 'edit',
							label: 'Quick Edit',
							action: () => {
								this.$modal.show(QuickEditPlacementModal, {
									id: this.id
								}, {
									height: 'auto',
									scrollable: true
								}, {
									'before-close': async (/*event*/) => {
										let placement = await Vue.ovData.placement.get(this.id, true);
										this.$emit('update:name', placement.name);
									}
								});
							}
						},

					];
				}
				case 'offer': {
					return [
						{
							icon: 'search',
							label: 'Edit',
							link: {
								name: 'offer-edit',
								params: {id: this.id}
							}
						},
						// {
						// 	icon: 'area-chart',
						// 	label: 'Targeting',
						// 	link: {
						// 		name: 'offer-targeting',
						// 		params: {id: this.id}
						// 	}
						// },
						{
							icon: 'edit',
							label: 'Quick Edit',
							action: () => {
								this.$modal.show(QuickEditOfferModal, {
									id: this.id
								}, {
									height: 'auto',
									scrollable: true
								}, {
									'before-close': async (/*event*/) => {
										let offer = await Vue.ovData.offer.get(this.id, true);
										this.$emit('update:name', offer.name);
									}
								});
							}
						}
					];
				}
				case 'advertiser': {
					return [
						{
							icon: 'search',
							label: 'Edit',
							link: {
								name: 'advertiser-edit',
								params: {id: this.id}
							}
						}
					];
				}
				case 'publisher': {
					return [
						{
							icon: 'search',
							label: 'Edit',
							link: {
								name: 'publisher-edit',
								params: {id: this.id}
							}
						}
					];
				}
			}
			return [];
		}
	},
	created(){
		// switch (this.type) {
		// 	case 'group':
		// 		this.buttons = [];
		// 		break;
		// 	case 'subsource':
		// 		this.buttons = [
		// 			{
		// 				icon: 'edit',
		// 				label: 'Quick Edit',
		// 				action: () => {
		// 					this.$modal.show(SubsourceRulesModal, {
		// 						id: this.id
		// 					}, {
		// 						height: 'auto',
		// 						scrollable: true
		// 					}, {
		// 						'before-close': async (/*event*/) => {
		// 							// let placement = await Vue.ovData.placement.get(this.id, true);
		// 							// this.$emit('update:name', placement.name);
		// 						}
		// 					});
		// 				}
		// 			},
		// 			{
		// 				icon: 'pause',
		// 				label: 'Pause',
		// 				action: async() => {
		// 					console.log('Pause');
		//
		// 					let params = {
		// 						subsources: [this.id],
		// 						status: 'block'
		// 					};
		// 					try {
		// 						await this.$ovReq.post('subsource/updateSourcesStatus', params);
		// 					} catch (e) {
		// 						console.error(e);
		// 					}
		// 				}
		// 			}
		// 		];
		// 		break;
		// 	case 'placement':
		// 		this.buttons = [
		// 			{
		// 				icon: 'search',
		// 				label: 'Edit',
		// 				link: {
		// 					name: 'placement-edit',
		// 					params: {id: this.id}
		// 				}
		// 			},
		// 			{
		// 				icon: 'area-chart',
		// 				label: 'Targeting',
		// 				link: {
		// 					name: 'placement-targeting',
		// 					params: {id: this.id}
		// 				}
		// 			},
		// 			{
		// 				icon: 'edit',
		// 				label: 'Quick Edit',
		// 				action: () => {
		// 					this.$modal.show(QuickEditPlacementModal, {
		// 						id: this.id
		// 					}, {
		// 						height: 'auto',
		// 						scrollable: true
		// 					}, {
		// 						'before-close': async (/*event*/) => {
		// 							let placement = await Vue.ovData.placement.get(this.id, true);
		// 							this.$emit('update:name', placement.name);
		// 						}
		// 					});
		// 				}
		// 			},
		//
		// 		];
		// 		break;
		// 	case 'offer':
		// 		this.buttons = [
		// 			{
		// 				icon: 'search',
		// 				label: 'Edit',
		// 				link: {
		// 					name: 'offer-edit',
		// 					params: {id: this.id}
		// 				}
		// 			},
		// 			{
		// 				icon: 'area-chart',
		// 				label: 'Targeting',
		// 				link: {
		// 					name: 'offer-targeting',
		// 					params: {id: this.id}
		// 				}
		// 			},
		// 			{
		// 				icon: 'edit',
		// 				label: 'Quick Edit',
		// 				action: () => {
		// 					this.$modal.show(QuickEditOfferModal, {
		// 						id: this.id
		// 					}, {
		// 						height: 'auto',
		// 						scrollable: true
		// 					}, {
		// 						'before-close': async (/*event*/) => {
		// 							let offer = await Vue.ovData.offer.get(this.id, true);
		// 							this.$emit('update:name', offer.name);
		// 						}
		// 					});
		// 				}
		// 			}
		// 		];
		// 		break;
		// 	case 'advertiser':
		// 		this.buttons = [
		// 			{
		// 				icon: 'search',
		// 				label: 'Edit',
		// 				link: {
		// 					name: 'advertiser-edit',
		// 					params: {id: this.id}
		// 				}
		// 			}
		// 		];
		// 		break;
		// 	case 'publisher':
		// 		this.buttons = [
		// 			{
		// 				icon: 'search',
		// 				label: 'Edit',
		// 				link: {
		// 					name: 'publisher-edit',
		// 					params: {id: this.id}
		// 				}
		// 			}
		// 		];
		// 		break;
		// }
	}
}
</script>
