import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';


let api = {
	get(url, config){
		return new Promise((resolve, reject) => {
			Vue.axios.get(url, config)
				.then(
					resp => {
						if (resp.data.err === 0) {
							resolve(resp.data.data);
						} else {
							Vue.$notify.error(resp.data.errdesc);
							reject(resp.data);
						}
					},
					resp => {
						Vue.$notify.error();
						reject(resp);
					}
				);
		});

	},
	post(url, data, config){
		return new Promise((resolve, reject) => {
			Vue.axios.post(url, data, config)
				.then(
					resp => {
						if (resp.data.err === 0) {
							resolve(resp.data.data);
						} else {
							Vue.$notify.error(resp.data.errdesc);
							reject(resp.data);
						}
					},
					resp => {
						Vue.$notify.error();
						reject(resp);
					}
				);
		});

	}
};

const ApiService = {
	init(){
		Vue.use(VueAxios, axios);
		Vue.axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL + 'admin/';
		Vue.$api = api;
		Vue.prototype.$api = api;
	},
	setToken(token){
		Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	},
	...api
};

export default ApiService;
