<template lang="pug">
	div.page-reports.reports-widget
		loading(:active.sync="busy", :is-full-page="true")
		.card.card-custom.gutter-b()
			.card-header
				.card-title
					.card-label BuckSense Reports
				.card-toolbar
			.card-body
				form.form.dw-filters(@submit.prevent="loadData()")
					.form-blocks
						.block.block-dimensions
							.block-header
								h3 Dimensions
							.block-body
								.selection-list
									div(v-for="d in dimensions", :class="{selected:d._selected}")
										b-checkbox(v-model="d._selected") {{ d.label }}
								.selected-items
									div(v-for="d in selectedDimensions")
										a(href="javascript:void(0);", @click="removeDimension(d)")
											i.la.la-times
										span {{ d.label }}
						.block.block-metrics
							.block-header
								h3 Metrics
							.block-body
								.selection-list.selection-list-wide
									div.list-item(v-for="d in metrics", :class="{selected:d._selected}")
										a.toggle(v-if="d.children", href="javascript:void(0);", @click="d._expanded = !d._expanded")
											i.la(:class="{'la-caret-right': !d._expanded, 'la-caret-down': d._expanded}")
										a.toggle-all(v-if="d.children", href="javascript:void(0)", @click="toggleAllChildren(d)")
											i.la.la-check-square
										b-checkbox(v-model="d._selected", :class="{symbolic:d.symbolic}", :disabled="d.symbolic") {{ d.label }}
											=' '
											span(v-if="d.children") ({{ d.children | countSelected }})
										div(v-if="d.children", v-show="d._expanded")
											div.child(v-for="cd in d.children", :class="{selected:cd._selected}")
												b-checkbox(v-model="cd._selected") {{ cd.label }}
						.block.block-filters
							.block-header
								h3 Filters
								.block-actions
									a(href="javascript:void(0);", @click="clearFilters()") Clear
							.block-body.form-inline
								dw-date-time-picker(v-model="filters.datePicker")
								.form-group
									label Group Timeframe
									select.form-control(v-model="filters.group_timeframe", style="width: 120px;")
										option(value="none") None
										option(value="5min") 5 Minutes
										option(value="hour") Hour
										option(value="day") Day
								.form-group
									label Min Bids
									input.form-control(type="text", v-model="filters.min_bids")

					.row-buttons
						//.presets
							button.btn.rounded-pill(v-for="p in presets", type="button", @click="setPreset(p)", :class="'btn-'+p.type")
								i.la.la-plus(v-if="p.action === 'add'")
								span {{ p.name }}
						.submit-buttons
							b-button-group
								b-dropdown(split-button-type="submit", variant="primary", right, split, text="Go")
									b-dropdown-item(@click="loadData(1)")
										i.la.la-download
										| Export

		.card.card-custom
			.card-header.border-0.pt-5
				h3.card-title Results
			.card-body.pt-3.pb-0
				paginate(:paginator="paginate", @update-page-size="loadData")
				.stats-table-wrapper
					table.table.table-bordered.tbl-statistics(v-if="resultMeta")
						thead
							tr
								th(v-if="filters.group_timeframe === '5min'") Time
								th(v-if="filters.group_timeframe === 'day'") Day
								th(v-if="filters.group_timeframe === 'hour'") Hour
								th.col-dim(v-for="d in resultMeta.dimensions", :class="'col-'+d.name") {{ d.label }}
								th.col-metric C.Bid
								dw-th.col-metric(v-for="m in resultMeta.metrics", :key="m.name", :class="'col-'+m.name", @sort="doSort($event)", :sorter="sortBy", :field="m.name") {{ m.short_label || m.label }}

						tbody.summary
							tr(v-if="summary")
								td.c &nbsp;
								td(v-for="d in resultMeta.dimensions") &nbsp;
								td.c &nbsp;
								td.c(v-for="d in resultMeta.metrics", :class="'col-'+d.name")
									span {{ summary[d.name].toLocaleString() }}

						tbody
							tr(v-if="records.length===0")
								td(:colspan="numColumns") No matching records were found
							tr(v-for="r in records", v-bind:key="r.key")
								td.nowrap(v-if="filters.group_timeframe === '5min'") {{ r.group_time }}
								td.nowrap(v-if="filters.group_timeframe === 'day'") {{ r.group_time }}
								td.nowrap(v-if="filters.group_timeframe === 'hour'") {{ r.group_time }}
								template(v-for="d in resultMeta.dimensions")
									td.col-entity(v-bind:key="r.key+'_'+(d.name)")
										span {{ r[d.field] }}
								td
									div(v-if="r.dimset")
										.input-group.input-group-sm(style="width: 120px")
											input.form-control(v-model="r.dimset._payout")
											.input-group-append
												button.btn.btn-outline-secondary(type="button", @click="saveBid(r)", style="padding: 0; width: 30x; text-align: center;")
													i.la.la-save(style="font-size: 22px;", v-if="!r._busy")
													i.fa.fa-spinner.fa-spin(style="font-size: 22px;", v-if="r._busy")
											.input-group-append
												.input-group-text(style="padding: 9px 3px 0px 1px; text-align: center; width: 30px;")
													a(href="javascript:void(0);", @click="toggleLock(r)")
														i.la.la-fw(:class="{'la-check-square-o':r.dimset.lock,'la-square-o':!r.dimset.lock}", style="font-size: 22px;")

								td.c(v-for="d in resultMeta.metrics", :class="'col-'+d.name + ' flag-'+(r[d.name] ? r[d.name].flag : '')")
									span(v-if="r[d.name]", :class="'flag-'+r[d.name].flag") {{ r[d.name].toLocaleString() }}

						tfoot.summary
							tr(v-if="summary")
								th.c &nbsp;
								th(v-for="d in resultMeta.dimensions") &nbsp;
								td.c &nbsp;
								th.c(v-for="d in resultMeta.metrics", :class="'col-'+d.name")
									span {{ summary[d.name].toLocaleString() }}
</template>
<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import moment from "moment";
import Vue from "vue";

export default {
	name: 'BucksenseReports',
	data(){
		return {
			numColumns: 5,
			busy: false,
			filters: {
				group_timeframe: 'day',
				min_bids: 0,
				datePicker: {
					timezone: 0,
					dateRange: {
						startDate: null,
						endDate: null
					},
				},
			},
			paginate: {
				numPages: 0,
				total: 0,
				page: 1,
				limit: 50,
				onPageChange: () => {
					this.loadData();
				}
			},
			sortBy: {
				field: 'bids',
				direction: 'desc',
				sort: null,
			},
			dimensions: [
				{_selected: true, name: 'exchange_id', label: 'Exchange'},
				{_selected: true, name: 'campaign_id', label: 'Campaign'},
				{_selected: true, name: 'placement_id', label: 'PlacementID'},
				{_selected: true, name: 'placement_name', label: 'PlacementName'},
				{_selected: true, name: 'bundle', label: 'Bundle'},
			],
			metrics: [
				{_selected: true, name: 'bids', label: 'Bids'},
				{_selected: true, name: 'wins', label: 'Wins'},
				{_selected: true, name: 'win_rate', label: 'WinRate'},
				{_selected: true, name: 'cost', label: 'Cost'},
			],
			resultMeta: {
				dimensions:[],
				metrics:[]
			},
			records: [],
			summary: null,
		};
	},
	computed: {
		selectedDimensionHash(){
			let h = {};
			this.dimensions.forEach(d => {
				if (d._selected){
					h[d.name] = d;
				}
			});
			return h;
		},
		selectedDimensions(){
			return this.dimensions.filter(d => d._selected);
		},
		selectedMetrics(){
			return this.metrics.filter(d => d._selected || d.children && d.children.filter(c => c._selected).length);
		},
	},
	methods: {
		async saveBid(r){
			if (r._busy){
				return;
			}
			r._busy = true;
			try {
				let data = {
					id: r.dimset.id,
					payout: r.dimset._payout
				};
				let resp = await this.$api.post('mb/reports/setBid', data);
				r.dimset.payout = resp.payout;
				r.dimset._payout = resp.payout;
			} catch (e){
				console.error(e);
			}
			r._busy = false;
		},
		async toggleLock(r){
			if (r._busy){
				return;
			}
			r._busy = true;
			try {
				let resp = await this.$api.post('mb/reports/toggleLock', {id: r.dimset.id});
				r.dimset.lock = resp.lock;
			} catch (e){
				console.error(e);
			}
			r._busy = false;
		},
		doSort(data){
			this.sortBy = data
			this.loadData();
		},
		removeMetric: function (d){
			d._selected = false;
		},
		removeDimension: function (d){
			d._selected = false;
		},
		clearFilters(){

		},
		async loadData(){
			let metrics = [];
			this.metrics.forEach(m => {
				if (m._selected) {
					metrics.push(m.name);
				}
				if (m.children) {
					m.children.forEach(cm => {
						if (cm._selected) {
							metrics.push(cm.name);
						}
					});
				}
			});

			let dimensions = this.dimensions.filter(d => d._selected).map(d => d.name);

			let params = {
				extended_params: this.filters.extended_params ? 1 : 0,
				page: this.paginate.page,
				page_size: this.paginate.limit,
				group_timeframe: this.filters.group_timeframe,
				timezone: this.filters.datePicker.timezone,
				date_start: moment(this.filters.datePicker.dateRange.startDate).format('YYYY-MM-DD HH:mm'),
				date_end: moment(this.filters.datePicker.dateRange.endDate).format('YYYY-MM-DD HH:mm'),
				sort_col: this.sortBy.field,
				sort_dir: this.sortBy.direction,
				filters: {
					min_bids: this.filters.min_bids
				// 	subsource_data: this.filters.subsource_data ? 1 : 0,
				// 	has_conversions: this.filters.has_conversions ? 1 : 0,
				// 	advertiser_id: this.filters.advertiser_id.map(r => r.v),
				// 	publisher_id: this.filters.publisher_id.map(r => r.v),
				// 	offer_id: this.filters.offer_id.map(r => r.id),
				// 	asset_id: this.filters.asset_id.map(r => r.v),
				// 	placement_id: this.filters.placement_id.map(r => r.id),
				// 	os_id: this.filters.os_id.map(r => r.v),
				// 	device_id: this.filters.device_id.map(r => r.v),
				// 	browser_id: this.filters.browser_id.map(r => r.v),
				// 	country: this.filters.country,
				},
				metrics: metrics,
				dimensions: dimensions
			};
			if (this.busy) {
				return;
			}
			this.busy = true;
			console.log(params);
			try {
				let resp = await this.$api.post('mb/reports/query', params);
				this.records = resp.records.slice(0, this.paginate.limit);
				this.records.forEach(r => {
					console.log(r.dimset);
					if (r.dimset) {
						Vue.set(r, '_busy', false);
						Vue.set(r.dimset, '_payout', r.dimset.payout);
					}
				});
				this.summary = resp.summary;
				this.paginate.total = resp.total;
				this.resultMeta = {
					dimensions: resp.dimensions,
					metrics: resp.metrics
				};
				this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);

			} catch (e) {
				console.log(e);
			}
			this.busy = false;
		}
	},
	mounted(){
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Media'},
			{title: 'MB'},
			{title: 'Bucksense Reports'}
		]);
	},
}
</script>
