<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		form(@submit.prevent="submit", v-if="campaign")
			b-tabs(content-class="mt-3", pills, card)
				b-tab(title="Main", style="padding: 0")
					.row
						.col-md-6
							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label MB Campaign Information <span v-if="campaign.id">({{ campaign.id }})</span>
									.card-toolbar
								.card-body
									.form-group
										label Name
										input.form-control(type="text", v-model="campaign.name", readonly="readonly")
									pre {{ campaign.remote_data }}
							//.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Panel Information
									.card-toolbar
								.card-body
									.row
										.col-sm-12
											.form-group
												label Panel URL
												input.form-control(type="text", v-model="platform.panel_url")
									.row
										.col-sm-6
											.form-group
												label Username
												input.form-control(type="text", v-model="platform.panel_username")
										.col-sm-6
											.form-group
												label Password
												input.form-control(type="text", v-model="platform.panel_password")
						//.col-md-6

							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Misc
									.card-toolbar
								.card-body
									.form-group
										label Comments
										textarea.form-control(v-model="platform.comments", rows="5")
				b-tab(title="Optimizations", style="padding: 0", active)
					.card.card-custom.gutter-b
						.card-header
							.card-title
								.card-label Campaign Optimizations
							.card-toolbar
						.card-body
							form.form.dw-filters(@submit.prevent="loadData()")
								.form-inline
									dw-date-time-picker(v-model="filters.datePicker")
									.form-group
								button.btn.btn-primary(type="button", @click="loadData()") Go

</template>
<script>
import Vue from 'vue';
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import store from "@/core/services/store";
import {OVERRIDE_PAGE_LAYOUT_CONFIG, SET_LAYOUT_CONFIG} from "@/core/services/store/config.module";
import HtmlClass from "@/core/services/htmlclass.service";
import moment from "moment";

async function loadRoute(vm, to, from, next){

	let entity = {};
	if (to.name === 'mb-campaign-edit') {
		let entityId = +to.params.id;
		try {
			entity = await Vue.$dwData.mb.campaign.get(entityId, true);
		} catch (e) {
			Vue.$notify.error('Failed to load campaign');
			return next(false);
		}
	} else {
		entity = Vue.$dwData.mb.campaign.newInstance();
	}

	return vm => {
		vm.campaign = entity;
		vm.busy = false;
		return vm;
	};
}

export default {
	async beforeRouteEnter(to, from, next){
		let bind = await loadRoute({}, to, from, next);
		next(bind);
	},
	async beforeRouteUpdate(to, from, next){
		await loadRoute(this, to, from, next);
		next();
	},
	name: 'MBCampaignForm',
	data(){
		return {
			busy: true,
			campaign: null,
			filters: {
				datePicker: {
					timezone: 0,
					dateRange: {
						startDate: moment(moment().subtract(-(new Date().getTimezoneOffset() / 60), 'hours').format('DD/MM/YYYY') + ' 00:00', 'DD/MM/YYYY HH:mm'),
						endDate: moment(moment().subtract(-(new Date().getTimezoneOffset() / 60), 'hours').format('DD/MM/YYYY') + ' 23:59', 'DD/MM/YYYY HH:mm'),
					}
				},
			}
		}
	},
	methods: {
		async save(closeAfterSave = false){
			if (this.busy) {
				return;
			}
			this.busy = true;
			let data = Vue.util.extend({}, this.campaign);
			try {
				let resp = await this.$api.post('mb/campaigns/save', data);
				this.busy = false;
				this.$notify.success('MB Campaign has been saved');
				if (closeAfterSave) {
					this.$router.push({name: 'mb-campaign-list'});
				} else {
					this.platform = resp.entity;
				}
			} catch (e) {
				console.error(e);
				this.busy = false;
			}
		},

		async loadData(){
			if (this.busy) {
				return;
			}
			this.busy = true;
			let data = {
				campaign_id: this.campaign.id,
				date_start: moment(this.filters.datePicker.dateRange.startDate).format('YYYY-MM-DD HH:mm'),
				date_end: moment(this.filters.datePicker.dateRange.endDate).format('YYYY-MM-DD HH:mm'),
			};
			try {
				let resp = await this.$api.post('mb/campaigns/loadRemote/' + this.campaign.id, data);
				console.log(resp);
			} catch (e) {
				console.error(e);
			}
			this.busy = false;
		}
	},
	mounted(){
		let vm = this;
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Media'},
			{title: 'MB Campaigns', name: 'mb-campaign-list'},
			{title: this.campaign && this.campaign.id ? 'Edit Campaign' : 'Add Campaign'}
		]);
		this.$store.dispatch('setBackButton', {
			route: {name: 'mb-campaign-list'}
		});
		this.$store.dispatch('setPageButtons', [
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save',
				action(){
					vm.save();
				}
			},
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save & Close',
				action(){
					vm.save(true);
				}
			}
		]);
	},
}

</script>
