<template lang="pug">
	.form-group.form-group-sm.fg-publisher
		label Publishers ({{ visiblePublisherOptions.length }})
			b-checkbox(switch="", v-model="publisherOptionsArchive")
		multiselect(:multiple="this.multiple", :options="visiblePublisherOptions",
			:value="value", @input="onChange", track-by="v", label="t", deselect-label="", select-label="")
</template>
<script>
import Vue from 'vue';

export default {
	name: 'SelectPublisher',
	props: {
		value: [Array, Object],
		multiple: {
			type: Boolean,
			default: true,
			required: false
		},
		network_id: {
			type: Number,
			default: null,
			required: false
		}
	},
	computed: {
		visiblePublisherOptions(){
			return this.publisherOptions.filter(a => {
				return (!this.network_id || a.network_id === this.network_id) && (this.publisherOptionsArchive || a.active);
			});
		},
	},
	data(){
		return {
			publisherOptions: [],
			publisherOptionsArchive: false,
		};
	},
	methods: {
		onChange(value) {
			this.$emit('input', value);
		}
	},
	async mounted(){
		this.publisherOptions = await Vue.$dwData.publisher.getOptions(true);

	}
}
</script>
