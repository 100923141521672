<template lang="pug">
	.card.card-custom.gutter-b
		loading(:active.sync="busy", :is-full-page="false")
		.card-header
			.card-title
				.card-label Remote Publisher Groups
			.card-toolbar
		.card-body
			form.form.form-inline.form-filters(@submit.prevent="loadData()", :class="{expanded:filtersExpanded}")
				a.btn.btn-primary.toggle-filters.d-md-none(v-b-toggle.form-filters)
					span Filters
					i.fa.fw.fa-chevron-up.transition-all(:class="{'rotate-180':filtersExpanded}")
				b-collapse#form-filters.dont-collapse-sm(style="width: 100%;", v-model="filtersExpanded")
					.row.filters-form-row
						.form-row-main.col-auto.mr-auto(style="flex:1;")
							.form-group
								label Search
								input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.keyword")
							.form-group
								label Date
								input.form-control.input-search(type="text", placeholder="2021-04-15", v-model="filters.query_date")
							.form-group.no-label
								button.btn.btn-primary(type="submit") Go
							.form-group.no-label
								button.btn.btn-secondary(type="button", @click="calculateAll()") Calculate
						.form-row-end.col-auto
							.form-group.no-label
								button.btn.btn-success(type="button", @click="addRecord()")
									i.flaticon2-plus
									| Add

			p.clearfix &nbsp;

			table.table.table-bordered.table-compact
				thead
					tr
						th ID
						th Name
						th Score
						th OS
						th Geo
						th DeviceID
						th Publishers
						th(v-for="d in calc_dates") {{ d }}
						th Count
						th Actions
				tbody
					tr(v-if="records.length===0")
						td(colspan="4") No matching records were found
					tr(v-for="r in records", :key="r.id")
						td {{ r.id }}
						td {{ r.name }}
						td {{ r.score }}
						td {{ r.platform }}
						td {{ r.countries }}
							span(v-if="r.regions") , {{ r.regions }}
						td {{ r.require_device_id ? 'Yes' : 'No' }}
						td
							span(v-for="o in r.remote_publishers") &nbsp;
								span.badge.bg-secondary [{{ o.publisher_id}}:{{ o.remote_publisher_id }}] {{ o.name }}
						td(v-for="(d, j) in calc_dates") {{ r.calc_num_records[j].toLocaleString() }}
						td {{ r.num_records }}
						td.actions
							button.btn.btn-action.btn-secondary(type="button", @click="editRecord(r)")
								i.la.la-pen
							button.btn.btn-action.btn-primary(type="button", @click="calculate(r)")
								i.la.la-refresh
							.btn.btn-action.btn-outline-danger()
								i.la.la-trash

			paginate(:paginator="paginate", @update-page-size="loadData")

			b-modal#record-form-modal(title="Remote Publisher Group", @ok="saveRecord", size="lg")
				loading(:active.sync="busySave", :is-full-page="false")
				form.form.form-horizontal(v-if="currentRecord")
					.form-group
						label Name
						input.form-control(type="text", v-model="currentRecord.name")

					.row
						.col-sm-4
							.form-group
								label Country
								input.form-control(type="text", v-model="currentRecord.countries", placeholder="US")
						.col-sm-4
							.form-group
								label Regions
								input.form-control(type="text", v-model="currentRecord.regions", placeholder="342")
						.col-sm-4
							.form-group
								label Platform
								select.form-control(v-model="currentRecord.platform")
									option(value="") - All -
									option(value="ios") iOS
									option(value="android") Android
					.row
						.col-sm-6
							.form-group
								b-checkbox(v-model="currentRecord.require_device_id") Require Device ID
						.col-sm-6
							.form-group
								label Score
								input.form-control(type="text", v-model="currentRecord.score")
					.row
						.col-sm-4(v-for="o in remotePublisherOptions")
							b-checkbox(v-model="currentRecord.remote_publishers_hash[o.publisher_id + '_' + o.remote_publisher_id]") {{ o.t }}



</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
import moment from "moment";
export default {
	name: 'RemotePublisherGroupList',
	async beforeRouteEnter(to, from, next){
		let publisherOptions = await Vue.$dwData.publisher.getOptions();
		let remotePublisherOptions = await Vue.$dwData.remotePublisher.getOptions(true);
		next(vm => {
			vm.publisherOptions = publisherOptions;
			vm.remotePublisherOptions = remotePublisherOptions;
			vm.loadData();
			return vm;
		});
	},
	data(){
		return {
			busy: false,
			busySave: false,
			paginate: {
				numPages: 0,
				total: 0,
				page: 1,
				limit: 50,
				onPageChange: () => {
					this.loadData();
				}
			},
			remotePublisherOptions: [],
			publisherOptions: [],
			filters: {
				keyword: '',
				status: 1,
				query_date: moment().subtract(1, 'day').format('YYYY-MM-DD')
			},
			records: [],
			calc_dates: [],
			currentRecord: null,
			filtersExpanded: false,
		};
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ title: 'Media' },
			{ title: 'Remote Publisher Groups' }
		]);
	},
	methods: {

		addRecord(){
			this.currentRecord = {
				id: 0,
				name: '',
				score: 0,
				require_device_id: false,
				platform: '',
				countries: '',
				regions: '',
				publishers: [],
				remote_publishers_hash: {}
			};
			this.$bvModal.show('record-form-modal')
		},
		editRecord(job){
			this.currentRecord = Vue.util.extend({}, job);
			this.$bvModal.show('record-form-modal');
		},
		addRemotePublisherRow(){
			this.currentRecord.publishers.push({
				id: null
			});
		},
		async calculate(r){
			console.log(r);
			if (this.busy){
				return;
			}
			this.busy = true;
			let data = {
				id: r.id,
				query_date: this.filters.query_date
			};
			try {
				let resp = await this.$api.post('remote-entities/calculateGroup', data);
				r.num_records = resp.num_records;
				this.$notify.success('Calculation complete');
			} catch(e){
				console.error(e);
			}
			this.busy = false;
		},
		async saveRecord(bvModalEvt){
			bvModalEvt.preventDefault();
			if (this.busySave) {
				return;
			}
			this.busySave = true;
			let data = Vue.util.extend({}, this.currentRecord);
			data.remote_publishers = [];
			for (let k in data.remote_publishers_hash) {
				if (data.remote_publishers_hash[k]) {
					let parsed = k.split('_');
					data.remote_publishers.push({
						publisher_id: +parsed[0],
						remote_publisher_id: +parsed[1],
					});
				}
			}
			try {
				let resp = await this.$api.post('remote-entities/savePublisherGroup', data);
				this.busySave = false;
				this.$notify.success('Record has been saved');
				this.$bvModal.hide('record-form-modal');
				this.loadData();
			} catch (e) {
				console.error(e);
				this.busySave = false;
			}
		},

		async loadData(){
			this.busy = true;
			let params = {
				page: this.paginate.page,
				limit: this.paginate.limit,
				keyword: this.filters.keyword,
			};
			try {
				let resp = await this.$api.get('remote-entities/getPublisherGroupList', {params});
				this.records = resp.records;
				this.paginate.total = resp.total;
				this.calc_dates = resp.calc_dates;
				this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
			} catch (e){
				console.error(e);
			}
			this.busy = false;
		}
	},
	created(){
		this.loadData();
	}

}
</script>
