<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		.card.card-custom.gutter-b
			.card-header
				.card-title
					.card-label Automatic Campaigns
				.card-toolbar
			.card-body
				form.form.form-inline.form-filters(@submit.prevent="loadData()", :class="{expanded:filtersExpanded}")
					a.btn.btn-primary.toggle-filters.d-md-none(v-b-toggle.form-filters)
						span Filters
						i.fa.fw.fa-chevron-up.transition-all(:class="{'rotate-180':filtersExpanded}")
					b-collapse#form-filters.dont-collapse-sm(style="width: 100%;", v-model="filtersExpanded")
						.row.filters-form-row
							.form-row-main.col-auto.mr-auto(style="flex:1;")
								.form-group
									label Search
									input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.keyword")
								.form-group
									label OS
									select.form-control(v-model="filters.platform")
										option(value="") - All -
										option(value="ios") iOS
										option(value="android") Android
								.form-group
									label Countries
									input.form-control.input-search(type="text", placeholder="", v-model="filters.countries")
								.form-group
									label Status
									select.form-control(v-model="filters.status")
										option(value="") - All -
										option(v-for="o in statusOptions", :value="o.v") {{ o.t }}
								.form-group
									label Offer Status
									select.form-control(v-model="filters.offer_status")
										option(value="") - All -
										option(v-for="o in offerStatusOptions", :value="o.v") {{ o.t }}
								.form-group
									label Device Id
									select.form-control(v-model="filters.require_deviceid")
										option(value="") - All -
										option(value="required") Required
										option(value="optional") Not Required
								.form-group
									label All Supply
									select.form-control(v-model="filters.use_all_supply")
										option(value="") - All -
										option(value="all_supply") Use all supply
										option(value="custom") Custom
								.form-group
									label MMP
									select.form-control(v-model="filters.mmp")
										option(:value="null") - None -
										option(v-for="o in mmpOptions", :value="o.v") {{ o.t }}
								.form-group
									label Date
									input.form-control.input-search(type="text", placeholder="YYYY-MM-DD", v-model="filters.delivery_date")
								.form-group
									label Records Status
									select.form-control(v-model="filters.record_status")
										option(value="") - All -
										option(value="no_request") No Request
										option(value="insufficient") Insufficient
								// select-advertiser(v-model="filters.advertiser_id")
								.form-group.no-label
									button.btn.btn-primary(type="submit") Go
							.form-row-end.col-auto
								//select-advertiser(v-model="addAdvertiser", :multiple="false", @input="addOffer")
								.form-group.no-label
									router-link.btn.btn-success(:to="{name:'auto-campaign-add'}")
										i.flaticon2-plus
										| Add

				p.clearfix &nbsp;

		.card.card-custom.gutter-b
			.card-body
				.table-wrapper
					table.table.table-bordered
						thead
							tr
								th &nbsp;
								th ID
								th Status
								th.highlight-cell Status
								th.highlight-cell Daily
								th.highlight-cell Count
								th.highlight-cell Last Update
								th PlacementID
								th Offer
								th MMP
								th OS
								th Geo
								th Req.DeviceID
								th(style="padding-top: 0; padding-bottom: 0; vertical-align: middle; ") &nbsp;
									//b-dropdown(text="Bulk Actions", size="sm", variant="secondary")
										b-dropdown-item(href="#", @click="bulkConnect()") Connect
										b-dropdown-item(href="#", @click="bulkPause()") Pause
										b-dropdown-item(href="#", @click="bulkActivate()") Activate
										b-dropdown-item(href="#", @click="bulkExport()") Export
										b-dropdown-item(href="#", @click="bulkStatistics()") View statistics
										b-dropdown-item(href="#", @click="bulkModal('daily_click_cap', 'Set Click Cap')") Set Click Cap
						tbody
							tr(v-if="records.length === 0")
								td(colspan="18") No matching records were found
							tr(v-for="r in (selectedTab==='bucket' ? bucket : records)", v-bind:key="r.id", :class="{'row-selected': bucketHash[r.id]}")
								td.col-select(:class="{'bucket-row':bucketHash[r.id]}")
									a(href="javascript:void(0)", @click="toggleBucket(r)")
										i.la(:class="{'la-check-square':bucketHash[r.id], 'la-stop':!bucketHash[r.id]}")
								td {{ r.id }}
								td {{ r.status }}
								td.highlight-cell
									span {{ r.delivery_record_status }}
								td.highlight-cell
									span(v-if="r.delivery_record_total_records !== null") {{ r.delivery_record_total_records.toLocaleString() }}
									span(v-if="r.delivery_record_requested_records !== null") / {{ r.delivery_record_requested_records.toLocaleString() }}
									button.btn.btn-mini.btn-secondary(type="button", @click="clearDeliveryData(r)")
										i.la.la-times
								td.highlight-cell
									span(v-if="r.delivery_record_processed_records !== null") {{ r.delivery_record_processed_records ? r.delivery_record_processed_records.toLocaleString() : 0 }}
								td.highlight-cell
									span {{ r.delivery_record_updated_at }}
								td
									entity(:id="r.placement_id", :name="r.placement_name", type="placement")
								td
									entity(:id="r.offer_id", :name="r.offer_name", type="offer")
								td {{ r.offer_mmp }}
								td {{ r.platform }}
								td {{ r.countries }}
								td
									b-form-checkbox(v-model="r.require_device_id", switch, @change="updateRecordRequireDeviceId(r)")
								td.actions
									router-link.btn.btn-action.btn-secondary(:to="{name:'auto-campaign-edit', params: {id:r.id}}", v-b-tooltip.hover.bottom, title="Edit")
										i.la.la-pencil
									router-link.btn.btn-action.btn-info(:to="{name:'auto-campaign-add', query:{copy_id:r.id}}", v-b-tooltip.hover.bottom, title="Duplicate")
										i.la.la-copy
									button.btn.btn-action.btn-info(type="button", @click="prepareRecord(r)", v-b-tooltip.hover.bottom, title="Prepare Delivery")
										i.la.la-refresh
									button.btn.btn-action.btn-danger(type="button", @click="deleteRecord(r)")
										i.la.la-trash

					paginate(:paginator="paginate", @update-page-size="loadData")

</template>
<style lang="scss">
.mmp {
	text-transform: uppercase;
	font-weight: bold;
}

.category {
	position: absolute;
	bottom: 2px;
	left: 2px;
	font-size: 10px;
	color: #aaa;
}

.created_at {
	position: absolute;
	bottom: 2px;
	right: 2px;
	font-size: 10px;
	color: #aaa;
}
.highlight-cell {
	background: #f6f6f6;
}
</style>
<script>
import Vue from 'vue';
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import QuickConnectOffersModal from "@/views/components/QuickConnectOffersModal";
import moment from 'moment';

export default {
	name: 'AutoCampaignList',
	async beforeRouteEnter(to, from, next){
		let advertiserOptions = await Vue.$dwData.advertiser.getOptions();
		next(vm => {
			vm.advertiserOptions = advertiserOptions;
			return vm;
		});
	},
	data(){
		return {
			busy: false,
			selectedTab: 'search',
			paginate: {
				numPages: 0,
				total: 0,
				page: 1,
				limit: 50,
				onPageChange: () => {
					this.loadData();
				}
			},
			mmpOptions: Vue.$dwData.general.mmpOptions,
			addAdvertiser: null,
			filters: {
				mmp: null,
				keyword: '',
				record_status: '',
				offer_status: '',
				delivery_date: moment().format('YYYY-MM-DD'),
				status: '',
				platform: '',
				countries: '',
				require_deviceid: '',
				use_all_supply: '',
				advertiser_id: []
			},
			bucket: [],
			bucketHash: {},
			records: [],
			filtersExpanded: false,
			statusOptions: [
				{v: 'all', t: 'All'},
				{v: 'draft', t: 'Draft'},
				{v: 'pending', t: 'Pending'},
				{v: 'live', t: 'Live'},
				{v: 'paused', t: 'Paused'},
				{v: 'stopped', t: 'Stopped'},
				{v: 'error', t: 'Error'},
				{v: 'complete', t: 'Complete'},
			],
			offerTypeOptions: Vue.$dwData.general.offerTypeOptions,
			offerStatusOptions: [{
				v: null,
				t: '- All -',
			}, ...Vue.$dwData.general.offerStatusOptions.filter(o => o.v !== 'draft')],
			payoutModelOptions: Vue.$dwData.general.payoutModelOptions,
		};
	},
	mounted(){
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Media'},
			{title: 'Automation'},
			{title: 'Auto-Campaigns'}
		]);
	},
	methods: {

		async updateRecordRequireDeviceId(r){
			try {
				let data = {
					id: r.id,
					field: 'require_device_id',
					val: r.require_device_id
				};
				let resp = await this.$api.post('/auto-campaigns/updateField', data);
				this.$notify.success('AutoCampaign Job has been updated');
			} catch (e){
				console.error(e);
			}
		},

		async prepareRecord(r){
			console.log('prepareRecord', r.remote_publisher_group_id);
			try {
				let data = {
					id: r.id,
					date: this.filters.delivery_date,
					remote_publisher_group_id: r.remote_publisher_group_id
				};
				let resp = await this.$api.post('/auto-campaigns/prepare', data);
				this.$notify.success('AutoCampaign Job has been prepared');
				this.loadData();
			} catch (e){
				console.error(e);
			}
		},

		async deleteRecord(job){
			if (!confirm('Are you sure?')){
				return;
			}
			try {
				let data = {
					id: job.id
				};
				await this.$api.post('auto-campaigns/delete', data);
				this.busySave = false;
				this.$notify.success('Job has been removed');
				this.loadData();
			} catch (e) {
				console.error(e);
				this.busySave = false;
			}
		},

		async clearDeliveryData(job){
			if (!confirm('Are you sure?')){
				return;
			}
			try {
				let data = {
					id: job.id,
					delivery_date: this.filters.delivery_date
				};
				await this.$api.post('auto-campaigns/clearDeliveryData', data);
				this.busySave = false;
				this.$notify.success('Data has been cleared');
				this.loadData();
			} catch (e) {
				console.error(e);
				this.busySave = false;
			}
		},

		async updateGroup(r){
			console.log(r.remote_publisher_group_id);
			try {
				let data = {
					id: r.id,
					remote_publisher_group_id: r.remote_publisher_group_id
				};
				let resp = await this.$api.post('/auto-campaigns/updateGroup', data);
				this.$notify.success('AutoCampaign Job has been saved');
			} catch (e){
				console.error(e);
			}
		},

		async loadData(){
			this.busy = true;
			let params = {
				page: this.paginate.page,
				limit: this.paginate.limit,
				keyword: this.filters.keyword,
				status: this.filters.status,
				offer_status: this.filters.offer_status,
				platform: this.filters.platform,
				countries: this.filters.countries,
				delivery_date: this.filters.delivery_date,
				mmp: this.filters.mmp,
				require_deviceid: this.filters.require_deviceid,
				use_all_supply: this.filters.use_all_supply,
				record_status: this.filters.record_status
			};
			try {
				let resp = await this.$api.get('/auto-campaigns/getList', {params});
				this.records = resp.records;
				this.paginate.total = resp.total;
				this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
			} catch (e) {
				console.error(e);
			}
			this.busy = false;
		}
	}
}
</script>
