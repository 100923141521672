<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		.card.card-custom.gutter-b
			.card-header
				.card-title
					.card-label MMP Accounts
				.card-toolbar
			.card-body
				form.form.form-inline.form-filters(@submit.prevent="loadData()", :class="{expanded:filtersExpanded}")
					a.btn.btn-primary.toggle-filters.d-md-none(v-b-toggle.form-filters)
						span Filters
						i.fa.fw.fa-chevron-up.transition-all(:class="{'rotate-180':filtersExpanded}")
					b-collapse#form-filters.dont-collapse-sm(style="width: 100%;", v-model="filtersExpanded")
						.row.filters-form-row
							.form-row-main.col-auto.mr-auto(style="flex:1;")
								.form-group
									label Search
									input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.keyword")
								.form-group
									label Network
									select.form-control(v-model="filters.network_id", style="width: 120px;")
										option(:value="null") - All -
										option(v-for="n in networkOptions", :value="n.v") {{ n.t }}
								//.form-group.fg-offer
									label Offer(s)
									multiselect(:multiple="true", :options="offerOptions", v-model="filters.offer_id",
										track-by="id", label="name", deselect-label="", select-label="",
										//:internal-search="true")
								.form-group.no-label
									button.btn.btn-primary(type="submit") Go
							.form-row-end.col-auto
								.form-group.no-label
									router-link.btn.btn-success(:to="{name:'mmp-account-add'}")
										i.flaticon2-plus
										| Add

				p.clearfix &nbsp;

				table.table.table-bordered.table-compact
					thead
						tr
							th ID
							th MMP
							th Type
							th Identifier
							th Actions
					tbody
						tr(v-if="records.length===0")
							td(colspan="10") No matching records were found
						tr(v-for="r in records", :key="r.id")
							td {{ r.id }}
							td {{ r.mmp }}
							td {{ r.type }}
							td {{ r.identifier }}
							td.actions
								router-link.btn.btn-action.btn-secondary(:to="{name:'mmp-account-edit', params: {id:r.id}}")
									i.la.la-pen
								button.btn.btn-action.btn-danger(type="button", @click="deleteRecord(r)")
									i.la.la-trash

				paginate(:paginator="paginate", @update-page-size="loadData")

</template>
<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import Vue from 'vue';

export default {
	name: 'MMPAccountList',
	async beforeRouteEnter(to, from, next){
		let networkOptions = await Vue.$dwData.network.getOptions(true);

		next(vm => {
			vm.networkOptions = networkOptions;
			vm.loadData();
			return vm;
		});
	},
	data(){
		return {
			busy: false,
			// PERMS: null,
			paginate: {
				numPages: 0,
				total: 0,
				page: 1,
				limit: 50,
				onPageChange: () => {
					this.loadData();
				}
			},
			networkOptions: [],
			filters: {
				keyword: '',
				status: null,
				network_id: 1
			},
			records: [],
			filtersExpanded: false,
		};
	},
	mounted(){
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Demand'},
			{title: 'MMP Accounts'}
		]);
	},
	methods: {

		async deleteRecord(job){
			if (!confirm('Are you sure?')){
				return;
			}
			try {
				let data = {
					id: job.id
				};
				await this.$api.post('mmp-accounts/delete', data);
				this.busySave = false;
				this.$notify.success('MMP Account has been removed');
				this.loadData();
			} catch (e) {
				console.error(e);
				this.busySave = false;
			}
		},

		async loadData(){
			if (this.busy){
				return;
			}
			this.busy = true;
			let params = {
				page: this.paginate.page,
				limit: this.paginate.limit,
				keyword: this.filters.keyword,
				status: this.filters.status,
				network_id: this.filters.network_id || 0
			};
			try {
				let resp = await this.$api.get('/mmp-accounts/getList', {params});
				this.records = resp.records;
				this.paginate.total = resp.total;
				this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
			} catch (e){
				console.error(e);
			}
			this.busy = false;
		}
	}

}
</script>
